import React, { useContext, useEffect, useState } from 'react';

import {
  Upload,
  Button, 
  Table,
  message,
  Avatar,
  Row,
  Col,
  Space,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Modal,
  Form,
  InputNumber,
  Popconfirm
} from 'antd';

import {
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  Divider,
  SelectSubTitle
} from '../index.styles';

import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import { Context as DataContext } from "../../../../../../api/dataProvider";
import { STATIC_IMG_URL } from '../../../../../../Constants';

import moment from 'moment';

const { Option } = Select
const { RangePicker } = DatePicker;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EventModal = ({ visible, hideModal, editBool, societiesList, productsList, eventData }) => {

  const [thumbnailImage, setThumbnailImage] = useState();
  const [title, setTitle] = useState();
  const [society, setSociety] = useState();
  const [displayDate, setDisplayDate] = useState();
  const [permanent, setPermanent] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [inventoryList, setInventoryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [eventVisible, setVisible] = useState(true);

  const [addInventoryModal, setAddInventoryModal] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

  const [productData, setProductData] = useState();
  const [productID, setProductID] = useState();
	const [quantity, setQuantity] = useState();
  const [position, setPosition] = useState();
  const [customizationList, setCustomizationList] = useState([]);

  const [editProduct, setEditProduct] = useState(false);
  const [editIndex, setEditIndex] = useState();

  const [eventID, setEventID] = useState();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const {
    addEvent,
    editEvent,
    downloadSaleReport,
    downloadEventInventory
  } = useContext(DataContext);

  const isEditing = (record) => record.size === editingKey;

	const [loading, setLoading] = useState(false);
  const [saleReportLoading, setSaleReportLoading] = useState(false);
  const [inventoryDownloadLoading, setInventoryDownloadLoading] = useState(false);

  useEffect(() => {
    if (productData && productData["customizations"] != null) {
      let arr = [];
      for (let customization of productData["customizations"]) {
        arr.push({
          "size": customization["size"],
          "color": customization["color"],
          "compositeProduct": customization["compositeProduct"]["_id"],
          "quantity": 0,
          "price": customization["compositeProduct"]["discountPrice"],
          "_id": customization["_id"]
        })
      }
      setCustomizationList(arr);
    }
  }, [productData])

  const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB');
		}
		return isJpgOrPng && isLt2M;
	}

	const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
			setLoading(false);
    }
	}

  const handleImageListPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

  const downloadInventory = async () => {
    try {
      setInventoryDownloadLoading(true);
      let resp = await downloadEventInventory({ eventID });
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'EventInventory.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      setInventoryDownloadLoading(false)
    } catch (e) {
      setInventoryDownloadLoading(false)
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const downloadSalesReport = async () => {
    try {
      setSaleReportLoading(true);
      let resp = await downloadSaleReport({ eventID });
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'SalesReport.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSaleReportLoading(false)
    } catch (e) {
      setSaleReportLoading(false)
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const columns = [
    {
			title: '',
			dataIndex: 'index',
			key: 'image',
			render: index => <img src = {productsList[index] == null ? '' : `https://buyzone.s3.ap-south-1.amazonaws.com/images/${productsList[index]["thumbnailImage"]}`} width = {45} height = {45} style = {{ borderRadius: 25 }} />,
			width: 60
    },
    {
			title: 'Name',
			dataIndex: 'index',
			key: 'name',
			render: index => <Name>{productsList[index] == null ? 'Unknown' : productsList[index]["title"]}</Name>,
    },
    {
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			render: quantity => <Other>{quantity}</Other>,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      'key': 'position',
      render: position => <Other>{position}</Other>,
      sorter: (a, b) => a.position - b.position,
      sortOrder: 'ascend',
      ellipsis: true
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record, index) => <span>
        <a
          href="javascript:;"
          onClick={() => initiateEditInventoryItem(record, index)}
          style={{
            marginRight: 8,
          }}
        >
          Edit
        </a>
        <Popconfirm title="Sure to delete?" onConfirm={() => {let newArr = [...inventoryList]; console.log(newArr); newArr.splice(index, 1); console.log(newArr); setInventoryList(newArr)}}>
          <a>Delete</a>
        </Popconfirm>
      </span>
    }
  ];

  const mainColumns = [
    {
      title: 'Title',
      dataIndex: 'size',
      width: '30%',
      render: (size, _record) => {
        let data = productData["sizes"].find((val) => val["_id"].toString() == size.toString());
        if (data != null)
          return (
            <Name>{data["value"]}</Name>
          )
        else
            return (
              <Name>No Size</Name>
            )
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      editable: true
    },
    {
      title: 'Price',
      dataIndex: 'price',
      editable: true
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.size)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        );
      },
    }
  ];

  useEffect(() => {
    if (eventData != null) {
      setEventID(eventData["_id"]);
      setTitle(eventData["title"]);
      setSociety(eventData["society"]["_id"]);
      setPermanent(eventData["permanent"])
      setPickup(eventData["pickup"]);
      setDisplayDate(eventData["displayDate"]);
      setStartDate(eventData["startDate"]);
      setEndDate(eventData["endDate"]);
      setVisible(eventData["visible"]);
      let inventoryList = [];
      for (let inventoryItem of eventData["inventory"]) {
        inventoryItem["index"] = productsList.findIndex((val) => val["_id"] == inventoryItem["product"])
        inventoryList.push(inventoryItem)
      }
      setInventoryList(inventoryList);
    }
  }, []);

  const initiateEditInventoryItem = (item, index) => {
    console.log(item);
    setProductID(item["product"]);
    if (!(item["customizations"] == null || item["customizations"].length == 0)) {
      setCustomizationList(item["customizations"]);
    }
    setPosition(item["position"]);
    setQuantity(item["quantity"]);
    setEditProduct(true);
    setEditIndex(index);
    setAddInventoryModal(true);
  }

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.size);
  };

  const save = async (key) => {
    try {
      console.log(key);
      const row = await form.validateFields();
      const newData = [...customizationList];
      const index = newData.findIndex((item) => key === item.size);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        // setCustomItemsList(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        // setCustomItemsList(newData);
        setEditingKey('');
      }
      setCustomizationList(newData);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }

  const finalColumns = mainColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const valiate = () => {
    if (!title) {
      message.error('Please add title')
      return false;
    }
    if (!society) {
      message.error('Please select society');
      return false;
    }
    if (!permanent && !startDate) {
      message.error('Please select start date');
      return false;
    }
    if (!permanent && !endDate) {
      message.error('Please select end date');
      return false;
    }
    if (inventoryList.length == 0) {
      message.error('Please select at least one item for inventory');
      return false;
    }
    return true;
  }

  const addEventToServer = async () => {
    if (!valiate())
      return;
    const finalData = {
      title,
      society,
      startDate,
      endDate,
      displayDate,
      inventory: inventoryList,
      permanent,
      pickup,
      visible: eventVisible
    };
    setLoader(true);
    try {
      let resp = await addEvent(finalData);
      message.success(resp.message);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      let { response } = e;
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        message.error("Some error occurred");
        console.error(e);
      }
    }
  }

  const editEventToServer = async () => {
    if (!valiate())
      return;
    const finalData = {
      _id: eventID,
      title,
      society,
      startDate,
      endDate,
      displayDate,
      inventory: inventoryList,
      permanent,
      pickup,
      visible: eventVisible
    };
    setLoader(true);
    try {
      let resp = await editEvent(finalData);
      message.success(resp.message);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      let { response } = e;
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        message.error("Some error occurred");
        console.error(e);
      }
    }
  }

  return(
    <CustomModalStyle
      centered
      visible={visible}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      afterClose = {() => {
        setTitle();
        
      }}
      width = {700}
    >
      <ModalTitle>{!editBool ? 'Add' : 'Edit'} Event</ModalTitle>
      <div style = {{ height: 20 }} />
      <Divider />
      <div style = {{ height: 20 }} />
      {/* <Upload
        listType="picture-card"
        showUploadList={false}
        accept="image/*" 
        onPreview={(file) => handleImageListPreview(file)}
        customRequest={async ({file, onSuccess, onError}) => {
          try {
            console.log(file)
            const key = await handleProductThumbnailImageUpload(file);
            setThumbnailImage(key);
            onSuccess('Ok');
          } catch (e) {
            onError('Error')
          }
        }}
        beforeUpload={beforeUpload}
        onChange={(e) => handleChange(e)}
      >
        {thumbnailImage ? <img src={'https://buyzone.s3.ap-south-1.amazonaws.com/images/' + thumbnailImage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload> */}
      <div style = {{ height: 10 }} />
      <Space>
        <Checkbox checked = {pickup} onChange={(e) => setPickup(e.target.checked)}>Pickup Allowed</Checkbox>
        <Checkbox checked = {permanent} onChange={(e) => setPermanent(e.target.checked)}>Permanent Event</Checkbox>
        <Checkbox checked = {eventVisible} onChange={(e) => setVisible(e.target.checked)}>Visible</Checkbox>
      </Space>
      <div style = {{ height: 10 }} />
      <Space>
        <Input style = {{width: 272}} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <Select
          showSearch
          style = {{
            width: 272
          }}
          placeholder="Select Society"
          optionFilterProp="children"
          onChange={(val) => setSociety(val)}
          value = {society}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            societiesList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["name"]}</Option>)
          }
        </Select>
      </Space>
      <div style = {{ height: 10 }} />
      <DatePicker onChange={(val) => setDisplayDate(val.toDate())} placeholder="Select Display Date" value={displayDate == null ? null : moment(displayDate)} format="DD MMM YYYY hh:mm A" />
      <div style = {{ height: 10 }} />
      {
        !permanent &&
        <RangePicker
          style= {{ width: '100%' }}
          showTime={{ format: 'hh:mm A' }}
          format="DD MMM YYYY hh:mm A"
          value={startDate == null ? [] : [moment(startDate), moment(endDate)]}
          onChange={(val) => console.log(val)}
          onOk={(dates) => {
            if (dates[0] != null)
              setStartDate(dates[0].toDate());
            if (dates[1] != null)
              setEndDate(dates[1].toDate())
          }}
        />
      }
      <div style = {{ height: 10 }} />
      <Button type='primary' onClick={() => setAddInventoryModal(true)}>Add Items</Button>
			<div style = {{ height: 15 }} />
			<Table dataSource={inventoryList} columns={columns} scroll={{ y: '150px' }} />
			<div style = {{ height: 20 }} />
			<Divider />
			<div style = {{ height: 20 }} />
      <Space>
        <Button type = "primary" onClick = {() => {eventID == null ? addEventToServer() : editEventToServer()}} loading = {loader}>{eventID == null ? 'Add' : 'Edit'}</Button>
        {
          eventID != null &&
          <Button type="secondary" onClick = {() => downloadSalesReport()} loading={saleReportLoading}>Download Sale Report</Button>
        }
        {
          eventID != null &&
          <Button type="secondary" onClick = {() => downloadInventory()} loading={inventoryDownloadLoading}>Download Inventory</Button>
        }
      </Space>
      <Modal
				visible={addInventoryModal}
				onOk={() => {
					if (productID == null) {
						message.error('Please select a product first.')
						return
					}
					if (customizationList.length == 0 && quantity == null) {
						message.error('Please add a quantity first.')
						return
					}
          if (position == null) {
						message.error('Please add a position first.')
						return
					}
          // if (quantity > (productData["stockOnHand"] - productData["stockCommitted"])) {
          //   message.error('Available Quantity for selected product: ' + (productData["stockOnHand"] - productData["stockCommitted"]));
					// 	return
          // }
					const newProducts = [...inventoryList];
          let index = productsList.findIndex((val) => val["_id"] == productID)
          console.log("Edit Product: ", editProduct);
          if (editProduct)
            newProducts[editIndex] = {
              "product": productID,
              "quantity": quantity,
              "position": position,
              "customizations": customizationList,
              index
            }
          else
            newProducts.push({
              "product": productID,
              "quantity": quantity,
              "position": position,
              "customizations": customizationList,
              index
            })
					setInventoryList(newProducts);
          message.success(`Product ${editProduct ? 'edited' : 'added' } successfully`);
					setAddInventoryModal(false);
				}}
				onCancel={() => setAddInventoryModal(false)}
        afterClose={() => {
					setProductID();
					setQuantity();
          setPosition();
          setEditIndex();
          setCustomizationList([]);
          setEditProduct(false);
        }}
				okText = "Add"
				width = {950}
			>
				<div>
					<ModalTitle>Select Product</ModalTitle>
				</div>
				<div style = {{ height: 20 }} />
				<Divider />
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={7}>
						<Select
							showSearch
							style = {{
								width: '100%'
							}}
              disabled = {editProduct}
							placeholder="Select Product"
							optionFilterProp="children"
							value={productID}
							onChange={(val) => {setProductID(val); setProductData(productsList.find((v) => v["_id"] == val))}}
							// filterOption={(input, option) =>
							// 	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							// }
						>
							{
								productsList.map((v) => <Option value={v["_id"]} key={v["_id"]} 
                  disabled = {(v["stockOnHand"] - v["stockCommitted"]) <= 0}
                >
                  {v["title"]}
                  <SelectSubTitle>
                    Available Stock: {v["stockOnHand"] - v["stockCommitted"]}
                  </SelectSubTitle>
                </Option>)
							}
						</Select>
					</Col>
          {
            customizationList.length == 0 &&
            <Col span={7}>
              <Input placeholder="Quantity" type="number" value = {quantity} max = {10} onChange={(e) => setQuantity(e.target.value)} />
            </Col>
          }
          <Col span={7}>
						<Input placeholder="Position" type="number" value = {position} onChange={(e) => setPosition(e.target.value)} />
					</Col>
				</Row>
        <div style = {{ height: 15 }} />
        {
          customizationList.length > 0 &&
          <Form form={form} component={false}>
            <Table
              rowClassName="editable-row"
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns = {finalColumns}
              dataSource = {customizationList}
              bordered
            />
          </Form>
        }
			</Modal>
    </CustomModalStyle>
  );
}