exports.errorCodes = {
	"ADMIN_VOUCHERS": {
		"DENOMINATIONS_NOT_AVAILABLE": 1000,
		"WRONG_OTP": 1001,
		"OTP_EXPIRED": 1002,
		"PINELABS_ORDER_FAILED": 1003,
		"PINELABS_UNKNOWN_STATUS": 1004,
	}
}

exports.buyzoneVoucherOrderStatusCodes = {
	4100: "SUCCESSFUL",
	4101: "CANCELLED",
}

exports.adminVoucherOrderStatusCodes = {
	400: "CREATED",
	401: "APPROVED",
	402: "PROCESSING",
	403: "COMPLETED",
	404: "FAILED"
}

exports.adminProductOrderStatusCodes = {
	500: "CREATED",
	501: "APPROVED",
	502: "PROCESSING",
	503: "COMPLETED",
	504: "FAILED"
}

exports.saleStatusCode = {
	600: "SCHEDULED",
	601: "LIVE",
	602: "COMPLETED",
	603: "FAILED",
	604: "CANCELLED",
}

exports.orderStatusCodes = {
	100: "SUCCESSFUL",
	101: "PROCESSED",
	102: "UNPAID",
	103: "WAITING",
	104: "CANCELLED",
	105: "PAYMENT CANCELLED"
}

exports.productStatusCodes = {
	200: "SUCCESSFUL",
	201: "PROCESSED",
	202: "UNPAID",
	203: "WAITING",
	204: "CANCELLED",
	205: "PACKED",
	206: "SHIPPED",
	207: "DELIVERED",
	208: "PREBOOKED",
	209: "PAYMENT CANCELLED"
}

exports.digitalStoreCodes = {
	200: "SUCCESSFUL",
	201: "PROCESSED",
	202: "UNPAID",
	203: "WAITING",
	204: "CANCELLED",
	205: "REFUNDED",
	206: "DELIVERED",
	207: "PAYMENT CANCELLED"
}