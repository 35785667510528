import React from "react";
import Img from "../../assets/svgs/404.svg";
import { Container, H1, H2, Btn } from "./index.styles";
import { useHistory } from "react-router-dom";

const Error = () => {

  let history = useHistory();

  return (
    <Container>
    <div style = {{ height: 100 }} />
      <img
        style={{
          objectFit: "contain",
          position: "relative",
          width: 250
        }}
        src={Img}
        alt="error"
      />
      <div style = {{ height: 30 }} />
      <H1>Page not found</H1>
      <H2 style={{ marginTop: 15 }}>
        Sorry we couldn't find the page you were looking for!
      </H2>
      <Btn style={{ marginTop: 40 }} onClick = {() => history.push('/')}>TAKE ME HOME</Btn>
    </Container>
  );
};

export default Error;
