import React, { useState } from 'react';

import {
  Name
} from './index.styles'

import {
  Row,
  Col,
  Input,
  Select,
  Space,
  Button
} from 'antd'
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { useEffect } from 'react';

const { Option } = Select

export const OtherDetailsTab = forwardRef(({ details, setDetails, customItemsList }, ref) => {

  const [hsnCode, setHsnCode] = useState();
  const [gstPercent, setGSTPercent] = useState();
  const [sku, setSKU] = useState();
  const [shipmentType, setShipmentType] = useState();
  const [limitingQuantity, setLimitingQuantity] = useState();
  const [cartQuantity, setCartQuantity] = useState();
  const [length, setLength] = useState();
  const [breadth, setBreadth] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();

  const [warranty, setWarranty] = useState();
  const [exchange, setExchange] = useState();
  const [guarantee, setGuarantee] = useState();

  const [cod, setCOD] = useState();
  const [cancellation, setCancellation] = useState();
  const [shipment, setShipment] = useState();

  const [codCharges, setCODCharges] = useState();
  const [cancellationCharges, setCancellationCharges] = useState();
  const [shipmentCharges, setShipmentCharges] = useState();
  
  useEffect(() => {
    if (details["_id"] != null) {
      const { dimensions, weight, charges, otherDetails } = details;

      setHsnCode(details["hsnCode"]);
      setGSTPercent(details["gstPercent"]);
      setSKU(details["sku"]);
      setShipmentType(details["shipmentType"]);
      setLimitingQuantity(details["limitingQuantity"]);
      setCartQuantity(details["cartQuantity"]);

      if (dimensions != null) {
        setLength(dimensions["length"]);
        setBreadth(dimensions["breadth"]);
        setHeight(dimensions["height"]);
      }

      setWarranty(otherDetails["warranty"]);
      setExchange(otherDetails["exchange"]);
      setGuarantee(otherDetails["guarantee"]);
      setCOD(otherDetails["cod"]);
      setCancellation(otherDetails["cancellation"]);
      setShipment(otherDetails["shipment"]);

      setCODCharges(charges["cod"]);
      setCancellationCharges(charges["cancellation"]);
      setShipmentCharges(charges["shipment"]);

      setWeight(weight["value"])

    }
  }, [])

  useImperativeHandle(ref, () => ({
		handleOtherDetails() {
			return new Promise ((resolve, reject) => {
				const otherDetails = {
          warranty,
          exchange,
          guarantee,
          cod,
          cancellation,
          shipment
        }
        const charges = {
          "cod": codCharges,
          "cancellation": cancellationCharges,
          "shipment": shipmentCharges,
        }
        let dimensions;
        if (length != null || breadth != null || height != null)
          dimensions = {
            length,
            breadth,
            height
          }
        const newWeight = {
          value: weight,
          unit: 'Kg'
        }
        const finalData = {
          sku,
          hsnCode,
          gstPercent,
          limitingQuantity,
          cartQuantity,
          otherDetails,
          charges,
          dimensions,
          shipmentType,
          weight: newWeight
        }
        const newDetails = JSON.parse(JSON.stringify(details));
        Object.assign(newDetails, finalData)
				resolve(finalData)
			})
		}
	}))

  return (
    <>
      <Name>Shipment Details</Name>
			<div style = {{ height: 15 }} />
      <Space size='middle'>
        <Input placeholder="HSN Code" value = {hsnCode} onChange={(e) => setHsnCode(e.target.value)} allowClear />
        <Input addonAfter='%' placeholder="GST Percent" type='number' value = {gstPercent} onChange={(e) => setGSTPercent(e.target.value)} allowClear />
        {
          customItemsList.length == 0 &&
          <Input placeholder="SKU" value = {sku} onChange={(e) => setSKU(e.target.value)} allowClear />
        }
        <Select
          value={shipmentType}
          style={{ width: 200 }}
          placeholder="Shipment Type"
          onSelect={(val) => setShipmentType(val)}
        >
          <Option value="Normal">Normal</Option>
          <Option value="Isolated">Isolated</Option>
          <Option value="Isolated">Isolated</Option>
          <Option value="Liq. Eatables">Liq. Eatables</Option>
          <Option value="Liquids">Liquids</Option>
        </Select>
			</Space>
			<div style = {{ height: 15 }} />
      <Space size='middle'>
        <Input addonAfter="m" placeholder="Length" type='number' value = {length} onChange={(e) => setLength(e.target.value)} allowClear />
        <Input addonAfter="m" placeholder="Breadth" type='number' value = {breadth} onChange={(e) => setBreadth(e.target.value)} allowClear />
        <Input addonAfter="m" placeholder="Height" type='number' value = {height} onChange={(e) => setHeight(e.target.value)} allowClear />
      </Space>
      <div style = {{ height: 15 }} />
      <Space size='middle'>
        <Input addonAfter="kg" placeholder="Weight" type='number' value = {weight} onChange={(e) => setWeight(e.target.value)} allowClear />
        <Input placeholder="Limiting Quantity" type="number" value = {limitingQuantity} onChange={(e) => setLimitingQuantity(e.target.value)} allowClear />
        <Input placeholder="Cart Quantity" type='number' value = {cartQuantity} onChange={(e) => setCartQuantity(e.target.value)} allowClear />
			</Space>
			<div style = {{ height: 15 }} />
      <Name>User Perks</Name>
			<div style = {{ height: 15 }} />
      <Space>
        <Select
          value={warranty}
          style={{ width: 200 }}
          placeholder="Warranty"
          onSelect={(val) => setWarranty(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        <Select
          value={exchange}
          style={{ width: 200 }}
          placeholder="Exchange"
          onSelect={(val) => setExchange(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        <Select
          value={guarantee}
          style={{ width: 200 }}
          placeholder="Guarantee"
          onSelect={(val) => setGuarantee(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Space>
      <div style = {{ height: 15 }} />
      <Name>Extra Charges</Name>
			<div style = {{ height: 15 }} />
      <Space>
        <Select
          value={cod}
          style={{ width: 200 }}
          placeholder="COD"
          onSelect={(val) => setCOD(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        {
          cod &&
          <Input addonBefore='₹' placeholder="COD Charges" type='number' value = {codCharges} onChange={(e) => setCODCharges(e.target.value)} allowClear />
        }
      </Space>
      <div style = {{ height: 15 }} />
      <Space>
        <Select
          value={cancellation}
          style={{ width: 200 }}
          placeholder="Cancellation"
          onSelect={(val) => setCancellation(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        {
          cancellation &&
          <Input addonBefore='₹' placeholder="Cancellation Charges" type='number' value = {cancellationCharges} onChange={(e) => setCancellationCharges(e.target.value)} allowClear />
        }
      </Space>
      <div style = {{ height: 15 }} />
      <Space>
        <Select
          value={shipment}
          style={{ width: 200 }}
          placeholder="Shipment"
          onSelect={(val) => setShipment(val)}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        {
          shipment &&
          <Input addonBefore='₹' placeholder="Shipment Charges" type='number' value = {shipmentCharges} onChange={(e) => setShipmentCharges(e.target.value)} allowClear />
        }
      </Space>
    </>
  )
})