import React from 'react';

import {
  StatTitle,
  StatText,
} from './index.styles';

import { 
  Row,
  Col
} from 'antd';

import moment from 'moment'

function DetailsTab({ details }) {
  return (
    <>
      <Row>
        <Col 
          span={11}
          style = {{
              height: 110,
              borderRadius: 10,
              backgroundColor: '#ecf9ea',
              padding: 20
          }}
        >
            <StatTitle>
              {
                details["packed"]["status"] ?
                `Packed by ${details.packed["admin"]["firstName"]} at ${moment(details.packed["timestamp"]).format("DD MMM, YYYY hh:mm A")}`:
                'None'
              }
            </StatTitle>
            <StatText color="#559f49">
              Packed Status
            </StatText>
        </Col>
        <div style = {{ width: 20 }} />
        <Col
          span={11}
          style = {{
              height: 110,
              borderRadius: 10,
              backgroundColor: '#f9f5ea',
              padding: 20
          }}
        >
          <StatTitle>
          {
            details["shipped"]["status"] ?
            `Shipped by ${details.shipped["admin"]["firstName"]} at ${moment(details.shipped["timestamp"]).format("DD MMM, YYYY hh:mm A")}`:
            'None'
          }
          </StatTitle>
          <StatText color = "#ac9554">
            Shipped Status
          </StatText>
        </Col>
        <div style = {{ width: 20 }} />
        {/* <Col
          span={7}
          style = {{
              height: 110,
              borderRadius: 10,
              backgroundColor: '#eaf2f8',
              padding: 20
          }}
        >
          <StatTitle>Total Packages Shipped</StatTitle>
        </Col> */}
      </Row>
      <div style = {{ height: 20 }} />
      <Row>
        <Col
          span={11}
          style = {{
              height: 110,
              borderRadius: 10,
              backgroundColor: '#eaf2f8',
              padding: 20
          }}
        >
          <StatTitle>Total Amount</StatTitle>
          <StatText color = "#4983ae">₹{details.paymentDetails.totalAmount}</StatText>
        </Col>
        <div style = {{ width: 20 }} />
        <Col
          span={11}
          style = {{
              height: 110,
              borderRadius: 10,
              backgroundColor: '#f9f5ea',
              padding: 20
          }}
        >
          <StatTitle>Order Type</StatTitle>
          <StatText color = "#ac9554">{details.paymentDetails.cod ? "COD" : "Paid"}</StatText>
        </Col>
      </Row>
    </>
  )
}

export default DetailsTab;