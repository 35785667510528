import React from 'react';
import {
  Table
} from 'antd'
import {
  Name,
} from './index.styles';

import moment from 'moment';

export const InventoryAdjustmentsTab = ({
  inventoryAdjustments,
  batches
}) => {

  const productsColumns = [
    {
      'title': 'Type',
      dataIndex: 'adjustmentType',
      key: 'adjustmentType',
      render: i => <Name>{i == 0 ? "Addition" : "Subtraction"}</Name>
    },
    {
      'title': 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (i, record) => <Name>{i == null ? `Added Batch: ${batches.find((val) => val["_id"].toString() == record["batchID"].toString())["title"]}` : i}</Name>,
    },
    {
      'title': 'Date Added',
      dataIndex: 'date',
      key: 'date',
      render: i => <Name>{moment(i).format('DD MMM YYYY')}</Name>,
    },
    {
      'title': 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: i => <Name>{i}</Name>,
    },
  ];

  return (
    <>
      <Table 
        dataSource={inventoryAdjustments}
        columns={productsColumns}
        scroll={{ y: '30vh' }}
      />
    </>
  );
}