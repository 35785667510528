import styled, { css } from "styled-components";

const row = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100vw;
  flex-direction: column;
  ${row}
`;

export const H1 = styled.div`
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #333743;
`;

export const H2 = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #333e49;
`;

export const Btn = styled.div`
  width: 196px;
  height: 60px;
  border-radius: 17px;
  border: solid 2px #8171f6;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #8171f6;
  ${row}
  cursor: pointer;
`;
