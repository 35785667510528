import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext } from 'react';
import {
  SelectSubTitle
} from './index.styles';
import {
	Input,
	Select,
  Space,
  DatePicker,
  message,
  Table
} from 'antd';
import moment from 'moment';
import { Context as DataContext } from '../../../../../../api/dataProvider';
import { Name, Other, Title, ModalTitle, ModalTitleDesc } from './index.styles';


const { Option } = Select;

export const VoucherDetailsTab = forwardRef(({ data }, ref) => {

  const [totalAmount, setTotalAmount] = useState();
  const [expiry, setExpiry] = useState();
  const [userID, setUserID] = useState();
  const [totalCount, setTotalCount] = useState(1);
  const [allUsers, setAllUsers] = useState([]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: type => <Title>{type}</Title>,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: reason => <Other>{reason}</Other>,
    },
		{
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => <Other>{amount}</Other>,
    }
  ];

  const {
		searchUserByPhoneNumber
	} = useContext(DataContext);

  useEffect(() => {
    if (data != null) {
      setTotalAmount(data["totalAmount"]);
      setExpiry(data["expiry"]);
      if (data["user"])
        setUserID(data["user"]["_id"])
    }
  }, [])

  useImperativeHandle(ref, () => ({
		handleVoucherDetails() {
			return new Promise ((resolve, reject) => {
				const finalData = {
          totalAmount,
          expiry,
          user: userID,
          totalCount
				}
				resolve(finalData)
			})
		}
	}))

  const handleUserSearch = async (phoneNumber) => {
    try {
      let resp = await searchUserByPhoneNumber({ phoneNumber })
      if (resp != null)
        setAllUsers([resp])
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Space>
        <Input 
          placeholder={'Total Amount'} 
          value = {totalAmount} 
          onChange={(e) => setTotalAmount(e.target.value)} 
          maxLength = {50} 
          allowClear />
        <Select
          disabled = {data != null}
          showSearch
          style = {{
            width: 350
          }}
          placeholder="Select User (By Phone)"
          showArrow={false}
          filterOption={false}
          value={userID}
          onSearch={(val) => handleUserSearch(val)}
          onChange={(val) => {setUserID(val)}}
          notFoundContent={null}
          defaultActiveFirstOption={false}
        >
          {
            allUsers.map((v) => <Option value={v["_id"]} key={v["phone"]}>
              {v["firstName"]} {v["lastName"]}
              <SelectSubTitle>
                Phone: {v["phone"]}
              </SelectSubTitle>
            </Option>)
          }
        </Select>
        <DatePicker placeholder="Expiry" format="DD MMM YYYY, hh:mm a" showTime onChange={(val) => {console.log("Date Changed")}} value={expiry == null ? expiry : moment(expiry)} onOk={(val) => setExpiry(val.toDate())} />
      </Space>
      <div style={{ height: 15 }} />
      <Input 
        placeholder={'Total Count'}
        value = {totalCount}
        type = "number"
        onChange={(e) => setTotalCount(e.target.value)}
        maxLength = {50}
        allowClear />
      {
        data != null &&
        <Table style={{ marginTop: 30 }} dataSource={data["transactions"]} columns={columns} scroll={{ y: '70vh' }} />
      }
    </>
  );
})