import React, { forwardRef, useImperativeHandle, useState, useEffect, useContext } from 'react';
import {
  Name,
  SelectSubTitle
} from './index.styles';
import {
	Input,
	Select,
  Space,
  Button,
  Table,
  Avatar,
  Modal,
  message
} from 'antd';
import moment from 'moment';

import { Context as DataContext } from '../../../../../../api/dataProvider';

const { Option } = Select;

export const UsersTab = forwardRef(({ data }, ref) => {

  let timeout;
  let currentValue;
  const [users, setUsers] = useState([]);
  const [userModal, setUserModal] = useState(false);
  const [userID, setUserID] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [searchType, setSearchType] = useState('phoneNumber');

  const {
		searchUser
	} = useContext(DataContext);

  const userColumns = [
    {
      title: '',
      dataIndex: 'profileImage',
      key: 'image',
      render: profileImage => <Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${profileImage}`} size="large" style = {{ cursor: 'pointer' }} />,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: firstName => <Name>{firstName} {firstName}</Name>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: phone => <Name>{phone}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeUsers(index)}>
            Remove
          </a>
        );
      },
    }
  ];

  const removeUsers = (index) => {
		let newArr = [...users];
		newArr.splice(index, 1);
		setUsers(newArr);
	}

  useImperativeHandle(ref, () => ({
		handleUserDetails() {
			return new Promise ((resolve, reject) => {
				resolve(users.map(v => v["_id"]))
			})
		}
	}))

  useEffect(() => {
    if (data != null) {
      setUsers(data["applyConstraints"]["users"])
    }
  }, [])

  const handleUserSearch = async (phoneNumber) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = phoneNumber;
    async function getData() {
      try {
        let resp = await searchUser({ data: phoneNumber, searchType })
        if (resp != null)
          setAllUsers(resp)
      } catch (e) {
        console.log(e);
      }
    }
    timeout = setTimeout(getData, 500);
  }

  const setSearchSelect = async (value) => {
    setSearchType(value)
    setAllUsers([])
  }

  return (
    <>
      <Button type='primary' onClick={() => setUserModal(true)}>Add Specific Users</Button>
      <div style = {{ height: 20 }} />
      <Table dataSource={users} columns={userColumns} scroll={{ y: '350px' }} />
      <Modal
        visible={userModal}
        onOk={() => {
          if (userID == null) {
            message.error('Please select a user first.')
            return;
          }
          const newUsers = [...users];
          const data = allUsers.find(val => val["_id"].toString() == userID.toString())
          newUsers.push(data);
          setUsers(newUsers);
          setUserModal(false);
          setUserID();
          message.success('User added successfully!');
        }}
        onCancel={() => setUserModal(false)}
        okText = "Add"
        width = {650}
        title = "Add Brand"
      >
        <div style = {{ height: 20 }} />
        <Input.Group compact>
          <Select defaultValue="phoneNumber" value={searchType} onChange={setSearchSelect} style={{ minWidth: 150 }}>
            <Option value="phoneNumber">Phone Number</Option>
            <Option value="email">Email</Option>
            <Option value="name">Name</Option>
          </Select>
          <Select
            showSearch
            style = {{
              width: 350
            }}
            placeholder="Select User"
            showArrow={false}
            filterOption={false}
            value={userID}
            onSearch={(val) => handleUserSearch(val)}
            onChange={(val) => {setUserID(val)}}
            notFoundContent={null}
            defaultActiveFirstOption={false}
          >
            {
              allUsers.map((v) => <Option value={v["_id"]} key={v["phone"]}>
                {v["firstName"]} {v["lastName"]}
                <SelectSubTitle>
                  Phone: {v["phone"]}
                </SelectSubTitle>
              </Option>)
            }
          </Select>
        </Input.Group>
      </Modal>
    </>
  )
});