import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import {
  Checkbox,
  Space,
  Input,
  Select,
  DatePicker
} from 'antd';
import {
	Name
} from './index.styles';
import { Editor } from '@tinymce/tinymce-react';

import moment from 'moment';
import { useEffect } from 'react';

const { Option } = Select
const { RangePicker } = DatePicker;

export const PrebookingTab = forwardRef(({
  details
}, ref) => {

  const [enabled, setEnabled] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [shipmentCharge, setShipmentCharge] = useState();
  const [maxStock, setMaxStock] = useState();
  const [tnc, setTnc] = useState("<ol><li></li></ol>");

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef != null && editorRef.current != null)
      return new Promise (async (resolve, reject) => {
        resolve(editorRef.current.getContent());
      })
  };

  useEffect(() => {
    if (details["_id"] != null) {
			let {
        prebooking
      } = details;
      if (prebooking != null && prebooking["enabled"] != null && prebooking["enabled"]) {
        let {
          enabled,
          startDate,
          endDate,
          shipmentCharge,
          maxStock,
          tnc
        } = prebooking;
        setEnabled(enabled);
        setStartDate(startDate);
        setEndDate(endDate);
        setShipmentCharge(shipmentCharge);
        setMaxStock(maxStock);
        setTnc(tnc);
      }
		}
  }, [])

  useImperativeHandle(ref, () => ({
		handlePrebookingDetails() {
			return new Promise (async (resolve, reject) => {
        let tnc = await log();
				let finalData;
        if (enabled) {
          finalData = {
            enabled,
            startDate,
            endDate,
            tnc,
            shipmentCharge,
            maxStock
          }
        } else {
          finalData  = {
            enabled
          }
        }
				resolve({ "prebooking": finalData })
			})
		}
	}))

  return (
    <>
      <Checkbox
        value = {enabled}
        onChange = {(e) => setEnabled(e.target.checked)}
      >
        Enabled
      </Checkbox>
      <div style = {{ height: 15 }} />
      {
        enabled &&
        <>
          <div>
            <Name>Start & End Date</Name>
            <div style = {{ height: 8 }} />
            <RangePicker
              style= {{ width: '100%' }}
              showTime={{ format: 'hh:mm A' }}
              format="DD MMM YYYY hh:mm A"
              value={startDate == null ? [] : [moment(startDate), moment(endDate)]}
              onChange={(val) => console.log(val)}
              onOk={(dates) => {
                if (dates[0] != null)
                  setStartDate(dates[0].toDate());
                if (dates[1] != null)
                  setEndDate(dates[1].toDate())
              }}
            />
          </div>
          <div style = {{ height: 10 }} />
          <Space>
            <div>
              <Name>Shipment Charge</Name>
              <div style = {{ height: 8 }} />
              <Input 
                value = {shipmentCharge}
                type = "number"
                onChange = {(e) => setShipmentCharge(e.target.value)}
                placeholder = "Optional"
              />
            </div>
            <div>
              <Name>Max Stock</Name>
              <div style = {{ height: 8 }} />
              <Input 
                style = {{ width: 250 }}
                value = {maxStock}
                type = "number"
                onChange = {(e) => setMaxStock(e.target.value)}
                placeholder = "Set Max Available Stock"
              />
            </div>
          </Space>
          <div style = {{ height: 10 }} />
          <Name>Terms & Conditions</Name>
          <div style = {{ height: 8 }} />
          <Editor
            apiKey = "xmvxnian23wvi7o7b6mqiwysfyr2ic9uya6is6cko48sezt4"
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={tnc}
            init={{
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
              ],
              toolbar: 'bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist'
              ,
              content_style: 'body { font-family: Montserrat,Arial,sans-serif; font-size:14px }'
            }}
          />
        </>
      }
    </>
  )
});