import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Table,
	Modal,
	Row,
	Input,
	Checkbox
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import {
  CustomModalStyle,
  Name,
  Other
} from './index.styles'
import { Context as DataContext } from "../../../../../api/dataProvider";


export const BuyzoneVoucherTypes = () => {
  const [createVoucherModal, setCreateVoucherModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vouchers, setVouchers] = useState([]);
	const [voucherIndex, setVoucherIndex] = useState();

	const [showPrice, setShowPrice] = useState();
	const [actualPrice, setActualPrice] = useState();
	const [visible, setVisible] = useState();

	const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Original Price',
        dataIndex: 'showPrice',
        key: 'showPrice',
        render: text => <Other>{text}</Other>,
    },
    {
			title: 'Discount Price',
			dataIndex: 'actualPrice',
			key: 'actualPrice',
			render: text => <Other>{text}</Other>,
		},
		{
			title: 'Visible',
			dataIndex: 'visible',
			key: 'visible',
			render: text => <Other>{text ? "Yes" : "No"}</Other>,
		}
  ];

	const {
    getBuyzoneVoucherTypes,
		editBuyzoneVoucherType
  } = useContext(DataContext);

	useEffect(() => {
    getInitialData();
  }, []);

	const getInitialData = async () => {
    try {
			setLoading(true);
			let voucherTypesResp = await getBuyzoneVoucherTypes();
      setVouchers(voucherTypesResp);
			setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

	const handleRowClick = async (record, index) => {
    setVoucherIndex(index);
		setShowPrice(vouchers[index]["showPrice"])
		setActualPrice(vouchers[index]["actualPrice"])
		setVisible(vouchers[index]["visible"])
    setCreateVoucherModal(true);
  }

	const handleSave = async () => {
		await editBuyzoneVoucherType(vouchers[voucherIndex]["_id"], {
			showPrice,
			actualPrice,
			visible
		})
		setCreateVoucherModal(false)
		getInitialData();
	}

	return (
		<div>
			<Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={vouchers}
      />
			{
				voucherIndex != null &&
				<Modal
					centered
					visible={createVoucherModal}
					title={vouchers[voucherIndex]["title"] + " - " + vouchers[voucherIndex]["actualPrice"]}
					onOk={() => handleSave()}
					onCancel={() => setCreateVoucherModal(false)}
					okText="Save"
					// afterClose = {() => setVoucherData()}
					width = {1100}
					style = {{
						height: 700
					}}
				>
					<div style={{ display: 'flex', gap: 15 }}>
						<div>
							Actual Price
							<Input placeholder="Actual Price" value = {showPrice} onChange={(e) => setShowPrice(e.target.value)} maxLength = {50} allowClear />
						</div>
						<div>
						Discount Price
						<Input placeholder="Discount Price" value = {actualPrice} onChange={(e) => setActualPrice(e.target.value)} maxLength = {50} allowClear />
						</div>
						<div style={{ width: 300 }}><Checkbox onChange={(e) => setVisible(e.target.checked)} checked = {visible}>Visible</Checkbox></div>
					</div>
				</Modal>
			}
		</div>
	)
}