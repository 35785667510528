import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import {
	Row,
	Col,
	Tag, 
	Input, 
	Tooltip,
	Select,
	Modal,
	Upload,
	message,
	Button,
	List,
	Typography,
	Space
} from 'antd';

import {
	Name
} from './index.styles';

import { PlusOutlined } from '@ant-design/icons';

import { Context as DataContext } from '../../../../../../api/dataProvider';
import { ColorsListArr } from '../../../../../../Constants';
import { useImperativeHandle } from 'react';

const { Option } = Select;

const unitsArr = [
	"box",
	"cm",
	"dz",
	"ft",
	"g",
	"in",
	"kg",
	"km",
	"lb",
	"mg",
	"m",
	"pcs",
	""
];;

export const CustomizationsTab = forwardRef(({ details, setDetails, customItemsList, setCustomItemsList }, ref) => {

	const [sizes, setSizes] = useState([])
	const [colors, setColors] = useState([])
	const [displayTag, setDisplayTag] = useState();
	const [displayTagColor, setDisplayTagColor] = useState('#000000');

	const [productDetails, setProductDetails] = useState([]);
	const [tempDetail, setTempDetail] = useState();
	const [tempIndex, setTempIndex] = useState();

	const [sizeModal, setSizeModal]	 = useState(false);
	const [colorModal, setColorModal]	 = useState(false);

	const [sizeValue, setSizeValue] = useState();
	const [sizeUnit, setSizeUnit] = useState();
	const [originalPrice, setOriginalPrice] = useState();
	const [discountPrice, setDiscountPrice] = useState();

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

	const [colorImages, setColorImages] = useState([]);
	const [colorPicker, setColorPicker] = useState();

	const {
		handleProductThumbnailImageUpload
	} = useContext(DataContext);

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	useEffect(() => {
		if (details["_id"] != null) {
			setColors(details["colors"]);
			setSizes(details["sizes"]);
			setProductDetails(details["details"]);
			setDisplayTag(details["overlay"]["tag"]);
			setDisplayTagColor("#" + details["overlay"]["color"])
		}
	}, [])

	useEffect(() => {
		setTempDetail();
		setTempIndex();
	}, [productDetails])

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleImageListPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	useEffect(() => {
		const itemArr = [];
		for (var color of colors) {
			if (sizes.length > 0)
				for (let size of sizes) {
					itemArr.push({
						'title': '' + ' - ' + color["name"] + " / " + size["value"],
						'color': color["value"],
						'size': size["value"],
						'originalPrice': '',
						'discountPrice': '',
						'sku': '',
					})
				}
			else
				itemArr.push({
					'title': '' + ' - ' + color["name"],
					'color': color["value"],
					'size': null,
					'originalPrice': '',
					'discountPrice': '',
					'sku': '',
					'hsnCode': '',
					'stock': ''
				})
		}
		setCustomItemsList(itemArr);
	}, [colors])

	useEffect(() => {
		const itemArr = [];
		for (var size of sizes) {
			if (colors.length > 0)
				for (let color of colors) {
					itemArr.push({
						'title': '' + ' - ' + color["name"] + " / " + size["value"],
						'color': color["value"],
						'size': size["value"],
						'originalPrice': '',
						'discountPrice': '',
						'sku': '',
						'hsnCode': '',
						'stock': ''
					})
				}
			else
				itemArr.push({
					'title': '' + ' - ' + size["value"],
					'color': null,
					'size': size["value"],
					'originalPrice': '',
					'discountPrice': '',
					'sku': '',
					'hsnCode': '',
					'stock': ''
				})
		}
		setCustomItemsList(itemArr);
	}, [sizes])

	const handleCustomization = () => {
		const finalData = {
			colors,
			sizes,
			overlay: {
				"tag": displayTag,
        "color": displayTagColor.split('#')[1]
			}
		};
		const newDetails = JSON.parse(JSON.stringify(details));
		Object.assign(newDetails, finalData);
		setDetails(newDetails)
	}

	useImperativeHandle(ref, () => ({
		handleCustomizationDetails() {
			return new Promise ((resolve, reject) => {
				const finalData = {
					colors,
					sizes,
					details: productDetails,
					overlay: {
						"tag": displayTag,
						"color": displayTagColor.split('#')[1]
					}
				}
				resolve(finalData)
			})
		}
	}))

	return (
		<>
			<Row>
				<Col span = {12}>
					<Name>Sizes</Name>
					<div style = {{ height: 15 }} />
					{
						sizes.map((v, i) => (
							<Tag
								className="edit-tag"
								key={i}
								closable
								onClose={() => {const newSizes = [...sizes]; newSizes.splice(i, 1); setSizes(newSizes)}}
							>
								<span
								>
									{v["value"]}
								</span>
							</Tag>
						))
					}
					<Tag className="site-tag-plus" onClick={() => setSizeModal(true)}>
						<PlusOutlined /> New Size
					</Tag>
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {11}>
					<Name>Colors</Name>
					<div style = {{ height: 15 }} />
					{
						colors.map((v, i) => (
							<Tag
								className="edit-tag"
								key={i}
								closable
								color={"#"+v["value"]}
								onClick={() => {setColorImages(v["images"]); setColorPicker("#" + v["value"]); setColorModal(true)}}
								onClose={() => {const newColors = [...colors]; newColors.splice(i, 1); setColors(newColors)}}
							>
								{v["name"]}
							</Tag>
						))
					}
					<Tag className="site-tag-plus" onClick={() => setColorModal(true)}>
						<PlusOutlined /> New Color
					</Tag>
				</Col>
			</Row>
			<div style = {{ height: 25 }} />
			<Row>
				<Col span = {14}>
					<Input placeholder="Display Tag" value = {displayTag} onChange={(e) => setDisplayTag(e.target.value)} allowClear />
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {6}>
					{/* <CompactPicker 
						color = {displayTagColor}
						onChangeComplete = {(color)  => {
							setDisplayTagColor(color)
						}}
					/> */}
					<Select
						style={{ width: '100%' }}
						placeholder="Select Color"
						optionFilterProp="children"
						onChange={(e) => setDisplayTagColor(ColorsListArr[e]["value"])}
						value={displayTagColor}
					>
						{
							ColorsListArr.map((v, i) => <Option key={i} value={i} label={v["name"]}>
								<div style = {{
									display: 'flex',
									alignItems: 'center'
								}}>
									<div
										style = {{ 
											width: 20,
											height: 20,
											borderRadius: 15,
											border: 'solid 1px #707070',
											background: '#' + v["value"],
											display: 'inline',
											marginRight: 10
										}}
									/>
									{v["name"]}
								</div>
							</Option>)
						}
					</Select>
				</Col>
			</Row>
			<div style = {{ height: 15 }} />
			<Space>
				<Input.TextArea style = {{ width: 600 }} placeholder="Add/Edit Details" value={tempDetail} onChange={e => setTempDetail(e.target.value)} />
				<Button type="primary" disabled = {tempDetail == null || tempDetail == ""} onClick = {() => {if (tempIndex == null) { setProductDetails([...productDetails, tempDetail]) } else { let newArr = [...productDetails]; newArr[tempIndex] = tempDetail; setProductDetails(newArr) } }}>{tempIndex == null ? 'Add' : 'Save'}</Button>
			</Space>
			<div style = {{ height: 15 }} />
			<List
				header={<div>Details</div>}
				bordered
				dataSource={productDetails}
				renderItem={(item, index) => (
					<List.Item actions={
						[
							<a key="list-loadmore-edit" onClick={() => {setTempDetail(item); setTempIndex(index)}}>Edit</a>, 
							<a key="list-loadmore-more" onClick={() => {let newArr = [...productDetails]; newArr.splice(index, 1); setProductDetails(newArr)}}>Delete</a>
						]
					}>
						<Typography.Text>• {item}</Typography.Text>
					</List.Item>
				)}
			/>
			<div style = {{ height: 15 }} />
			<Modal
				visible={sizeModal}
				title="Add Size"
				afterClose = {() => {
					setSizeValue();
					setSizeUnit();
					setOriginalPrice();
					setDiscountPrice();
				}}
				onCancel={() => setSizeModal(false)}
				onOk={() => {
					const newSizes = [...sizes];
					newSizes.push({
						"value": sizeValue + ' ' + sizeUnit,
					})
					setSizes(newSizes)
					setSizeModal(false)
				}}
			>
				<Row>
					<Col span = {11}>
						<Input placeholder="Size Value" value = {sizeValue} onChange={(e) => setSizeValue(e.target.value)} maxLength = {50} allowClear />
					</Col>
					<div style = {{ width: 15 }} />
					<Col span = {11}>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder="Unit"
							optionFilterProp="children"
							onChange={(e) => setSizeUnit(e)}
							value={sizeUnit}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								unitsArr.map((v, i) => <Option key={i} value={v}>{v}</Option>)
							}
						</Select>
					</Col>
				</Row>
				{/* <div style = {{ height: 15 }} />
				<Row>
					<Col span = {11}>
						<Input addonBefore="₹" placeholder="Price" value = {originalPrice} onChange={(e) => setOriginalPrice(e.target.value)} allowClear />
					</Col>
					<div style = {{ width: 15 }} />
					<Col span = {11}>
						<Input addonBefore="₹" placeholder="Offer Price" value = {discountPrice} onChange={(e) => setDiscountPrice(e.target.value)} allowClear />
					</Col>
				</Row> */}
			</Modal>
			<Modal
				visible={colorModal}
				title="Add/Edit Color"
				afterClose = {() => {
					setColorPicker()
					setColorImages([])
				}}
				onCancel={() => setColorModal(false)}
				onOk={() => {
					if (colorPicker == null) {
						message.error('Please select a color')
						return;
					}
					var colorsArr = [...colors];
					colorsArr.push({
						"value": ColorsListArr[colorPicker]["value"],
						"name": ColorsListArr[colorPicker]["name"],
						"images": colorImages
					})
					setColors(colorsArr)
					setColorModal(false);
				}}
			>
				<Name>Product Image(s)</Name>
				<div style = {{ height: 15 }} />
				<Upload
					listType="picture-card"
					fileList={colorImages}
					customRequest={async (input) => {
						console.log(input);
						const {file, onSuccess, onError} = input;
						try {
							const key = await handleProductThumbnailImageUpload(file);
							const newImages = [...colorImages];
							newImages.push({
								uid: key,
								name: 'image.png',
								url: 'https://buyzone.s3.ap-south-1.amazonaws.com/images/' + key,
								status: 'done',
							});
							setColorImages(newImages)
							onSuccess('Ok');
						} catch (e) {
							onError('Error')
						}
					}}
					onPreview={(file) => handleImageListPreview(file)}
					// onChange={(e) => handleImageListChange(e)}
				>
					{colorImages.length >= 4 ? null : uploadButton}
				</Upload>
				<div style = {{ height: 15 }} />
				<Select
					style={{ width: '100%' }}
					placeholder="Select Color"
					optionFilterProp="children"
					onChange={(e) => setColorPicker(e)}
					value={sizeUnit}
				>
					{
						ColorsListArr.map((v, i) => <Option key={i} value={i} label={v["name"]}>
							<div style = {{
								display: 'flex',
								alignItems: 'center'
							}}>
								<div
									style = {{ 
										width: 20,
										height: 20,
										borderRadius: 15,
										border: 'solid 1px #707070',
										background: '#' + v["value"],
										display: 'inline',
										marginRight: 10
									}}
								/>
								{v["name"]}
							</div>
						</Option>)
					}
				</Select>
			</Modal>
			<Button hidden type="primary" id = "customizationsButton" onClick = {() => handleCustomization()}>
				Submit
			</Button>
		</>
	)
})