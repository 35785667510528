import React, { useContext, useEffect, useState } from 'react';

import {
  Button, 
  Table,
  message,
  Avatar,
  Space,
  Input,
  Row,
  Col
} from 'antd';

import {
  Name,
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  MenuButton
} from './index.styles';

import { PlusOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { BatchInventoryModal } from './components/batchInventoryModal';

import { Context as DataContext } from "../../../../../api/dataProvider";
import { STATIC_IMG_URL } from '../../../../../Constants';

import moment from 'moment';
import { BatchesTab } from './components/batches';
import { InventoryAdjustmentsTab } from './components/inventoryAdjustments';
import { AdjustmentModal } from './components/adjustmentModal';

const { Search } = Input

export const Inventory = () => {

  const [loader, setLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [productList, setProductsList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [batchModal, setBatchModal] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);
  const [inventoryData, setInventoryData] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [adjustmentModal, setAdjustmentModal] = useState(false);

  const {
    getInventoryProducts,
    downloadInventoryExcel
  } = useContext(DataContext);

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = async () => {
    try {
      setLoader(true);
      let productList = await getInventoryProducts();
      setProductsList(productList);
      setFilteredList(productList);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      let { response } = e;
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        message.error("Some error occurred");
        console.error(e);
      }
    }
  }

  const downloadExcel = async () => {
    try {
      setDownloadLoader(true);
      let resp = await downloadInventoryExcel();
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Inventory.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadLoader(false);
    } catch (e) {
      setDownloadLoader(false);
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const productsColumns = [
    {
      'title': '',
      dataIndex: 'thumbnailImage',
      key: 'img',
      width: 80,
      render: i => <Avatar src={STATIC_IMG_URL + i} />
    },
    {
      'title': 'Product Name',
      dataIndex: 'title',
      key: 'title',
      render: i => <Name>{i}</Name>
    },
    {
      'title': 'Stock On Hand',
      dataIndex: 'stockOnHand',
      key: 'stockOnHand',
      width: 200,
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Stock Committed',
      dataIndex: 'stockCommitted',
      key: 'stockCommitted',
      width: 200,
      render: i => <Name>{i}</Name>,
    },
  ]

  const hideModal = async () => {
    setInventoryModal(false)
    setInventoryData();
  }

  const handleRowClick = (record, index) => {
    console.log(record);
    setInventoryData(record);
    setInventoryModal(true);
  }

  return (
    <>
      <Space>
        <Search placeholder="Search..." allowClear onSearch={(v) => {
          if (v == "")
            setFilteredList(productList)
          else
            setFilteredList(productList.filter((val) => val["title"].toString().toLowerCase().includes(v.toLowerCase())));
        }} style={{ width: 300 }} enterButton />
        <Button type="primary" icon={<PlusOutlined />} loading = {loader} onClick = {() => setBatchModal(true)}>
          Add Batch Inventory
        </Button>
        <Button type="secondary" icon={<DownloadOutlined />} loading = {downloadLoader} onClick = {() => downloadExcel()}>
          Download Inventory
        </Button>
        <Button type="secondary" icon={<EditOutlined />} onClick = {() => setAdjustmentModal(true)}>
          Adjust Inventory
        </Button>
      </Space>
      <div style = {{ height: 20 }} />
      <Table 
        loading = {loader} 
        dataSource={filteredList} 
        columns={productsColumns} 
        scroll={{ y: '70vh' }} 
        onRow={(record, index) => ({
          onClick: () => {handleRowClick(record, index)}
        })}
      />
      {
        adjustmentModal &&
        <AdjustmentModal visible = {adjustmentModal} hideModal = {() => setAdjustmentModal(false)} inventoryProductsList = {productList} />
      }
      {
        batchModal &&
        <BatchInventoryModal visisble = {batchModal} hideModal = {() => setBatchModal(false)} inventoryProductsList = {productList} />
      }
      {
        inventoryData != null &&
        <CustomModalStyle
          centered
          visible={inventoryModal}
          onOk={() => hideModal()}
          onCancel={() => hideModal()}
          afterClose = {() => {
            
          }}
          width = {1350}
        >
          <Row align="middle" justify="space-between">
            <div style = {{ marginLeft: 25 }}>
              <ModalTitle>{inventoryData.title}</ModalTitle>
            </div>
            <Row>
              <Col>
                <ModalTitle>Total Batches</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{inventoryData.batches.length}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Stock On Hand</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{inventoryData.stockOnHand}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Stock Committed</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{inventoryData.stockCommitted}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
            </Row>
          </Row>
          <div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 45 }} />
          <Row justify="space-between" style = {{
            minHeight: 400
          }}>
            <Col span = {5} style = {{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
              <MenuButton selected={selectedTab === 0} onClick = {() => setSelectedTab(0)}>Batches</MenuButton>
              <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 1} onClick = {() => setSelectedTab(1)}>Inventory Adjustments</MenuButton>
            </Col>
            <Col span = {18}>
              {
                selectedTab == 0 &&
                <BatchesTab batches = {inventoryData["batches"]} />
              }
              {
                selectedTab == 1  &&
                <InventoryAdjustmentsTab batches = {inventoryData["batches"]} inventoryAdjustments = {inventoryData["inventoryAdjustments"]} />
              }
            </Col>
          </Row>
        </CustomModalStyle>
      }
    </>
  );
};