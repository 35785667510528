import React, { useContext, useEffect, useState } from 'react';

import {
  Button, 
  Table,
  Select,
  Input,
  Modal,
  Row,
  Col,
  Space,
  message,
  DatePicker
} from 'antd';

import {
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  Divider
} from './index.styles';

import { Context as DataContext } from "../../../../../../api/dataProvider";

import moment from 'moment';

const { Option } = Select;

export const BatchInventoryModal = ({ visisble, hideModal, inventoryProductsList }) => {

  const {
    getInventoryProducts,
    addBatchInventory
  } = useContext(DataContext);
  const [loader, setLoader] = useState(false);
  const [sku, setSKU] = useState()
  const [title, setTitle] = useState();
  const [quantity, setQuantity] = useState();
  const [expiry, setExpiry] = useState();
  const [vendor, setVendor] = useState();
  const [purchaseDate, setPurchaseDate] = useState();
  const [customizationID, setCustomizationID] = useState();
  const [addBatchLoader, setAddBatchLoader] = useState(false);

  const [productData, setProductData] = useState();

  const getSKUProduct = async () => {
		const product = inventoryProductsList.find((val) => val["sku"] == sku);
    if (product == null) {
      setSKU();
      message.error("Invalid SKU");
      return;
    }
    setProductData(product);
	}

  const validate = () => {
    // if (!sku) {
    //   message.error("Please enter SKU.")
    //   return false;
    // }
    if (!expiry) {
      message.error("Please enter expiry.")
      return false;
    }
    if (!quantity) {
      message.error("Please enter quantity.")
      return false;
    }
    if (productData.customizations.length > 0 && !customizationID) {
      message.error("Please select customization.")
      return false;
    }
    return true;
  }

  const addInventory = async () => {
    if (!validate())
      return
    setAddBatchLoader(true);
    try {
      let resp = await addBatchInventory({
        "productID": productData["_id"],
        sku,
        expiry,
        title,
        quantity,
        vendor,
        purchaseDate,
        customizationID
      });
      setAddBatchLoader(false);
      message.success(resp.message);
    } catch (e) {
      let { response } = e;
      setAddBatchLoader(false);
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        console.error(e);
        message.error("Some error occurred");
      }
    }
  }

  return (
    <CustomModalStyle
      centered
      visible={visisble}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      afterClose = {() => {
        
      }}
      width = {650}
    >
      <ModalTitle>Add Inventory</ModalTitle>
      <div style = {{ height: 20 }} />
      <Divider />
      <div style = {{ height: 20 }} />
      <Select
        showSearch
        style = {{
          width: '100%'
        }}
        placeholder="Select Product"
        optionFilterProp="children"
        onChange={(val) => {
          let product = inventoryProductsList.find((v) => v["_id"] == val);
          console.log(product)
          setProductData(product);
          setSKU(product["sku"]);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          inventoryProductsList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
        }
      </Select>
      {/* <Row justify="space-between">
        <Col span={20}>
          <Input disabled = {(productData != null && Object.keys(productData).length > 0)} placeholder="SKU" type="number" value = {sku} onChange={(e) => setSKU(e.target.value)} />
        </Col>
        <Col span={3}>
          <Button type='primary' onClick={() => !(productData != null && Object.keys(productData).length > 0) ? getSKUProduct() : setProductData()}>{!(productData != null && Object.keys(productData).length > 0) ? "Search" : "Change"}</Button>
        </Col>
      </Row> */}
      <div style = {{ height: 20 }} />
      {
        productData != null && Object.keys(productData).length > 0 &&
        <>
          <Row justify="space-between">
            <Col span={7}>
              <DatePicker style = {{ width: '100%' }} placeholder = "Expiry Date" format = "DD MMM YYYY" onChange={(val) => setExpiry(val)} value = {expiry} />
            </Col>
            <Col span={7}>
              <Input placeholder="Quantity" type="number" value = {quantity} onChange={(e) => setQuantity(e.target.value)} />
            </Col>
            <Col span={7}>
              <Input placeholder="Title" value = {title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
          </Row>
          <div style = {{ height: 10 }} />
          <Row justify="space-between">
            <Col span={11}>
              <Input placeholder="Vendor" value = {vendor} onChange={(e) => setVendor(e.target.value)} />
            </Col>
            <Col span={11}>
              <DatePicker style = {{ width: '100%' }} placeholder = "Purchase Date" format = "DD MMM YYYY" onChange={(val) => setPurchaseDate(val)} value = {purchaseDate} />
            </Col>
          </Row>
          <div style = {{ height: 20 }} />
          {
            productData.customizations.length > 0 &&
            <Select
              showSearch
              style = {{
                width: '100%'
              }}
              placeholder="Select Customization"
              optionFilterProp="children"
              onChange={(val) => setCustomizationID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                productData.customizations.map((v) => <Option value={v["compositeProduct"]["_id"]} key={v["compositeProduct"]["_id"]}>{
                  (productData.sizes.length > 0 ? (productData.sizes.find((val) => {console.log(val, v); return val["_id"].toString() == v["size"].toString();})["value"] + ' ') : '') + 
                  (productData.colors.length > 0 ? productData.colors.find((val) => val["_id"] == v["color"])["name"] : '')
                }</Option>)
              }
            </Select>
          }
        </>
      }
      <div style = {{ height: 20 }} />
      <Divider />
      <div style = {{ height: 20 }} />
      <Button loading = {addBatchLoader} onClick = {() => addInventory()}>Add</Button>
    </CustomModalStyle>
  )
}