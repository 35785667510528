import styled from 'styled-components';

export const ModalTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const ModalTitleDesc = styled.div`
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const Divider = styled.div`
    height: 1px;
    background: #e1e1e1;
`;

export const Name = styled.div`
    opacity: 0.8;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const Title = styled.div`
    opacity: 0.8;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const MenuButton = styled.div`
    height: 39px;
    border-radius: 8px;
    background: ${(props) => (props.selected ? "#ebe8fa" : "#fff")};
    color: ${(props) => (props.selected ? "#5643f1" : "#333e49")};
    display: flex;
    align-items: center;
    padding-left: 23px;
    padding-right: 23px;
    cursor: pointer;
`;

export const FooterButton = styled.div`
    width: 133px;
    height: 40px;
    opacity: 0.8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: solid 1px ${(props) => (props.type == 'select' ? "#10eb00" : props.type == 'waitlist' ? "#5689fb" : '#ff0000')};
    background-color: #ffffff;
    color: ${(props) => (props.type == 'select' ? "#3fee32" : props.type == 'waitlist' ? "#5689fb" : '#ff0000')};
`;

export const SelectSubTitle = styled.div`
    opacity: 0.8;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;