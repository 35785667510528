import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body{
      font-family: Montserrat, sans-serif !important;
      overflow-x:hidden !important;
  }
  ${'' /* &:: -webkit-scrollbar {
    display: none;
  } */}
`;
