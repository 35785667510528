import React from 'react';
import {
  Table
} from 'antd'
import {
  Name
} from './index.styles';

import moment from 'moment';

export const BatchesTab = ({
  batches
}) => {

  const productsColumns = [
    {
      'title': 'Title',
      dataIndex: 'title',
      key: 'title',
      render: i => <Name>{i}</Name>
    },
    {
      'title': 'Original Item Count',
      dataIndex: 'totalItems',
      key: 'totalItems',
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Stock Committed',
      dataIndex: 'committedItems',
      key: 'committedItems',
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Used Items',
      dataIndex: 'usedItems',
      key: 'usedItems',
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Purchase Date',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      render: i => <Name>{i == null ? "" : moment(i).format('DD MMM YYYY')}</Name>,
    },
    {
      'title': 'Date Added',
      dataIndex: 'date',
      key: 'date',
      render: i => <Name>{moment(i).format('DD MMM YYYY')}</Name>,
    },
    {
      'title': 'Expiry',
      dataIndex: 'expiry',
      key: 'expiry',
      render: i => <Name>{moment(i).format('DD MMM YYYY')}</Name>,
    },
  ];

  return (
    <>
      <Table 
        dataSource={batches}
        columns={productsColumns}
        scroll={{ y: '30vh' }}
      />
    </>
  );
}