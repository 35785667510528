import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Table
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import {
  CustomModalStyle,
  Name,
  Other
} from './index.styles'
import { CreateVoucherModal } from './components/createVoucher';
import { Context as DataContext } from "../../../../../api/dataProvider";

export const Vouchers = () => {

  const [createVoucherModal, setCreateVoucherModal] = useState(false);
  const [loading, setLoading] = useState();
  const [vouchers, setVouchers] = useState([]);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);

  const [voucherData, setVoucherData] = useState();


  const {
    getProducts,
    getCompanies,
    getCategories,
    getBrands,
    getVouchers
  } = useContext(DataContext);

  useEffect(() => {
    getInitialData();
  }, []);

  const numberToProduct = {
    0: 'All',
    1: 'E-Vouchers',
    2: 'Products',
    3: 'Digital Stores'
  }

  const benefitType = {
    0: 'Percentage',
    1: 'Flat Amount'
  }

  const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Type',
        dataIndex: 'applyConstraints',
        key: 'voucherType',
        render: text => <Other>{numberToProduct[text.voucherType]}</Other>,
    },
    {
      title: 'Benefit Type',
      dataIndex: 'benefits',
      key: 'benefitType',
      render: text => <Other>{benefitType[text.benefitType]}</Other>,
    },
    {
      title: 'Amount',
      dataIndex: 'benefits',
      key: 'benefitAmount',
      render: text => <Other>{text.amount}</Other>,
    },
  ];

  const getInitialData = async () => {
    try {
      let companiesList = await getCompanies();
      let categoriesList = await getCategories();
      let productsList = await getProducts();
      let brandsList = await getBrands();
      let vouchersList = await getVouchers();
      setCompanies(companiesList);
      setCategories(categoriesList);
      setProducts(productsList);
      setBrands(brandsList["brands"]);
      setVouchers(vouchersList);
    } catch (e) {
      console.log(e);
    }
  }

  const handleRowClick = async (record, index) => {
    setVoucherData(record);
    setCreateVoucherModal(true);
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} loading = {loading} onClick = {() => setCreateVoucherModal(true)}>
        Add Voucher
      </Button>
      <div style = {{ height: 15 }} />
      <Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={vouchers}
      />
      <CustomModalStyle
        centered
        visible={createVoucherModal}
        onOk={() => setCreateVoucherModal(false)}
        onCancel={() => setCreateVoucherModal(false)}
        afterClose = {() => setVoucherData()}
        width = {1100}
        style = {{
          height: 700
        }}
      >
        <CreateVoucherModal data = {voucherData} brands = {brands} products = {products} categories = {categories} companies = {companies}/>
      </CustomModalStyle>
    </>
  )
}