import React, { useEffect, useContext, useState } from 'react';
import { Context as DataContext } from "../../../../../api/dataProvider";
import Papaparse from 'papaparse';

import ReactFileReader from 'react-file-reader';

import { PlusOutlined, DownloadOutlined } from '@ant-design/icons'
import { 
  message,
  Button,
  Select,
  Table,
  Row,
  Popconfirm,
  Modal,
  Input,
  Space,
  Checkbox
} from 'antd';

import { 
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  FooterButton
} from './index.styles';
import { errorCodes, adminVoucherOrderStatusCodes } from '../../../../../statusCodes';
import { AdminVoucherOrderModal } from './components/orderModal';

import moment from 'moment';

var fileDownload = require('js-file-download');

const { Option } = Select;
const { Search } = Input;

export const AdminVoucherOrders = () => {

  const [adminOrdersList, setAdminOrdersList] = useState([]);
  const [adminOrdersLoading, setAdminOrdersLoading] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [addOrderLoading, setAddOrderLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [finalOrdersArr, setFinalOrdersArr] = useState([]);

  const [orderID, setOrderID] = useState();
  const [addOTPModal, setAddOTPModal] = useState(false)
  const [otp, setOTP] = useState();

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showOrderData, setShowOrderData] = useState();

  const [adminClientsList, setAdminClients] = useState([])

  const [selectedClient, setSelectedClient] = useState();
  const [createInvoiceBool, setCreateInvoiceBool] = useState(false);

  const [clientName, setClientName] = useState();
  const [gst, setGST] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [receiverName, setReceiverName] = useState();

  const [description, setDescription] = useState();
  const [clientEmail, setClientEmail] = useState();

  const [billingAddress, setBillingAddress] = useState();
  const [billingPin, setBillingPin] = useState();
  const [billingState, setBillingState] = useState();
  const [billingCity, setBillingCity] = useState();

  const [sameAsBillingAddressBool, setSameAsBillingAddressBool] = useState(false);
  const [shippingAddress, setShippingAddress] = useState();
  const [shippingPin, setShippingPin] = useState();
  const [shippingState, setShippingState] = useState();
  const [shippingCity, setShippingCity] = useState();

  var [htmlInvoice, setHTMLInvoice] = useState("");
  const [invoice, setInvoiceOpen] = useState(false);

  const addModalTableColumns = [
    {
      title: 'Price (₹)',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
    }
  ];

  const adminVoucherOrderColumns = [
    {
      title: 'ID',
      dataIndex: 'orderID',
      key: 'orderID',
    },
    {
      title: 'Total Amount (₹)',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: brand => (
        <>
          {brand["name"]}
        </>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: brand => (
        <>
          {moment(brand).format("DD MMM YYYY")}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <>
          {adminVoucherOrderStatusCodes[status]}
        </>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: '_id',
      width: 200,
      render: (id) => (
        <>
          <Button type="link" onClick={() => handleRowAction(adminOrdersList.find((val) => val["_id"] == id))}>{adminOrdersList.find((val) => val["_id"] == id)["status"] == 403 ? 'Download' : 'Check Status'}</Button>
        </>
      ),
    },
  ];

  const {
    getBrands,
    getAllAdminVoucherOrders,
    createAdminVoucherOrder,
    approveAdminVoucherOrder,
    downloadAdminVoucherOrder,
    checkStatusAdminVoucherOrder,
    downloadAllAdminVoucherOrder,
    verifyAddressPin
  } = useContext(DataContext);

  useEffect(() => {
    getAllBrands();
    getAllOrders();
  }, []);

  const handleRowAction = async (data) => {
    if (data["status"] == 403)
      downloadExcel(data)
    else
      checkStatus(data);
  }

  const downloadExcel = async (data) => {
    try {
      let resp = await downloadAdminVoucherOrder(data);
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', data["orderID"] + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const checkStatus = async (data) => {
    try {
      let resp = await checkStatusAdminVoucherOrder(data);
      if (resp.status == 200) {
        getAllOrders();
      }
      message.success(resp.data.message);
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code, message: respMessage } = response.data;
        if (respMessage == null)
          message.error('Some error occurred');
        else
          message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const getAllBrands = async () => {
    try {
      const brandList = await getBrands();
      setBrandsList(brandList["brands"]);
    } catch (e) {
      console.error(e);
      message.error("Some error occurred while fetching all brands.")
    }
  }

  const getAllOrders = async () => {
    try {
      let { orders, adminClients } = await getAllAdminVoucherOrders();
      setAdminOrdersList(orders);
      setAdminClients(adminClients)
    } catch (e) {
      console.error(e);
      message.error('Some error occurred while fetching Admin Orders');
    }
  }

  const handleFiles = async (files) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const parseResult = Papaparse.parse(reader.result);
      if (parseResult.errors.length != 0) {
        console.error(parseResult.errors);
        message.error("There was some error while parsing CSV File.")
        return;
      }
      let parsedData = parseResult.data;
      if (!(parsedData[0][0] == "price" && parsedData[0][1] == "qty")) {
        message.error("Invalid CSV Format. Please ensure that the first row has only \"price\" & \"qty\"");
        return;
      }
      parsedData.splice(0, 1);
      console.log(parsedData)
      let finalOrderArr = [];
      let i = 0;
      for (var data of parsedData) {
        if ((data[0] == "" || data[1] == "") && i != (parsedData.length - 1)) {
          message.error("Invalid data at Row " + (i + 1));
          return;
        }
        if ((data[0] == "" || data[1] == "") && i == (parsedData.length - 1)) {
          i++;
          break;
        }
        finalOrderArr.push({
          "price": Number(data[0]),
          "qty": Number(data[1])
        })
        i++;
      }
      setFinalOrdersArr(finalOrderArr);
      message.success("CSV Parsed Successfully");
    }
    reader.readAsText(files[0]);
  }

  const checkInput = () => {
    if (selectedBrand == null) {
      message.error('Please select a brand')
      return false;
    }
    if (finalOrdersArr.length == 0) {
      message.error('No CSV Selected')
      return false;
    }
    if (createInvoiceBool && selectedClient == null) {
      message.error('Select a client first');
      return false;
    }
    if (createInvoiceBool && selectedClient == '') {
      if (clientName == null || clientName == '') {
        message.error('Please add a valid client name');
        return false;
      }
      if (gst == null || gst == '' || gst.length != 15) {
        message.error('Please add a valid GST');
        return false;
      }
      if (billingAddress == null || billingAddress == '') {
        message.error('Please enter Address Line for Billing Address');
        return false;
      }
      if (billingPin == null || billingPin == '') {
        message.error('Please enter valid billing pin');
        return false;
      }
      if (billingState == null || billingState == '') {
        message.error('Please verify the pin code for Billing Address');
        return false;
      }
      if (!sameAsBillingAddressBool) {
        if (shippingAddress == null || shippingAddress == '') {
          message.error('Please enter Address Line for Shipping Address');
          return false;
        }
        if (shippingPin == null || shippingPin == '') {
          message.error('Please enter valid pin for Shipping Address');
          return false;
        }
        if (shippingState == null || shippingState == '') {
          message.error('Please verify the pin code for Shipping Address');
          return false;
        }
      }
    }
    return true;
  }

  const handleCreate = async () => {
    if (!checkInput())
      return;
    setAddOrderLoading(true);
    try {
      let uploadData = {
        "sku": brandsList.find((val) => val["_id"] == selectedBrand)["skuArr"][0],
        "orderArr": finalOrdersArr,
        createInvoiceBool,
        description,
        clientEmail
      }
      if (createInvoiceBool) {
        let finalBillingAddress = {
          addressLine: billingAddress,
          pinCode: billingPin,
          state: billingState,
          city: billingCity
        }
        let finalShippingAddress = sameAsBillingAddressBool ? finalBillingAddress : {
          addressLine: shippingAddress,
          pinCode: shippingPin,
          state: shippingState,
          city: shippingCity
        }
        uploadData = {
          "sku": brandsList.find((val) => val["_id"] == selectedBrand)["skuArr"][0],
          "orderArr": finalOrdersArr,
          clientEmail,
          selectedClientID: selectedClient,
          billingAddress: finalBillingAddress,
          shippingAddress: finalShippingAddress,
          clientName,
          gst,
          createInvoiceBool,
          description
        }
      }

      let resp = await createAdminVoucherOrder(uploadData);
      setAddOrderLoading(false);
      if (resp.status == 200) {
        setOrderID(resp.data["orderID"])
        message.success(resp.data["message"]);
        setAddOTPModal(true);
      }
      else
        console.log(resp.data);
    } catch (e) {
      setAddOrderLoading(false);
      console.log(e.response);
      message.error(e.response.data.message)
    }
  }

  const handleOTP = async () => {
    if (!otp) {
      message.error('Please enter OTP before proceeding');
      return;
    }
    setAddOTPModal(false);
    setAddOrderLoading(true);
    try {
      let resp = await approveAdminVoucherOrder({
        orderID,
        otp
      });
      setAddOrderLoading(false);
      if (resp.status == 200) {
        message.success(resp.data["message"]);
      }
      else
        console.log(resp.data);
    } catch (e) {
      let { response}  = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
        if (code == errorCodes.ADMIN_VOUCHERS.OTP_EXPIRED || code == errorCodes.ADMIN_VOUCHERS.WRONG_OTP) {
          setAddOTPModal(true);
        }
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  const downloadAllOrders = async () => {
    try {
      let resp = await downloadAllAdminVoucherOrder();
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "Admin-Voucher-Orders-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const handleRowClickAction = (record, index) => {
    setShowOrderData(record);
    setShowOrderModal(true);
  }

  const verifyPin = async (type) => {
    const pin = type == 'Billing' ? billingPin : shippingPin;
    if (pin == null || pin.length == 0)
      message.error('Please enter some Pin Code first');
    try {
      let data = await verifyAddressPin(pin);
      if (type == "Billing") {
        setBillingCity(data["city"]);
        setBillingState(data["state"])
      } else {
        setShippingCity(data["city"]);
        setShippingState(data["state"])
      }
    } catch (e) {
      let { response }  = e;
      if (response != null) {
        if (response.status == 404)
          message.error("Invalid Pin Code. Please recheck and enter")
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  return (
    <>
      <Space>
        <Button type="primary" icon={<PlusOutlined />} onClick = {() => setAddOrderModal(true)} loading = {addOrderLoading} >
          Add Order
        </Button>
        <Button type="secondary" icon={<DownloadOutlined />} onClick = {() => downloadAllOrders()} >
          Download Orders
        </Button>
      </Space>
      <div style = {{ height: 15 }} />
      <Table 
        dataSource={adminOrdersList}
        columns={adminVoucherOrderColumns}
        onRow={(record, index) => ({
          onClick: () => {handleRowClickAction(record, index)}
        })}
      />
      {
        showOrderData != null &&
        <CustomModalStyle
          centered
          visible={showOrderModal}
          onOk={() => setShowOrderModal(false)}
          onCancel={() => setShowOrderModal(false)}
          width = {850}
        >
          <AdminVoucherOrderModal data = {showOrderData} />
        </CustomModalStyle>
      }
      <CustomModalStyle
        centered
        visible={addOrderModal}
        onOk={() => setAddOrderModal(false)}
        onCancel={() => setAddOrderModal(false)}
        width = {selectedClient == '' ? 1300 : 450}
      >
        <ModalTitle>Add Admin Voucher Order</ModalTitle>
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        {
          createInvoiceBool && (selectedClient == null || selectedClient != '') &&
          <Select
            style = {{ width: '100%' }}
            placeholder="Select Client"
            onSelect={(val) => setSelectedClient(val)}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <a
                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                    onClick={() => setSelectedClient('')}
                  >
                    <PlusOutlined /> Add New Client
                  </a>
                </div>
              </div>
            )}
          >
            {adminClientsList.map(item => (
              <Option key={item["_id"]} value={item["_id"]}>{item["name"]}</Option>
            ))}
          </Select>
        }
        {
          createInvoiceBool && selectedClient == '' &&
        <Row
            align="start"
            justify="space-between"
          >
            <div>
              <ModalTitle>Client Details</ModalTitle>
              <div style = {{ height: 15 }} />
              <Input
                onChange={(e) => setClientName(e.target.value)}
                value={clientName}
                placeholder="Client Name"
                width={250}
              />
              <div style = {{ height: 5 }} />
              <Input
                onChange={(e) => setGST(e.target.value)}
                value={gst}
                placeholder="GST Number"
              />
              {
                paymentMethod == "Cash" &&
                <>
                  <div style = {{ height: 5 }} />
                  <Input
                    onChange={(e) => setReceiverName(e.target.value)}
                    value={receiverName}
                    placeholder="Receiver Name"
                  />
                </>
              }
            </div>
            <div>
              <ModalTitle>Billing Address</ModalTitle>
              <div style = {{ height: 15 }} />
              <Input
                onChange={(e) => setBillingAddress(e.target.value)}
                value={billingAddress}
                placeholder="Billing Address"
                style = {{
                  width: 350
                }}
              />
              <div style = {{ height: 5 }} />
              <Search
                onChange={(e) => setBillingPin(e.target.value)}
                value={billingPin}
                placeholder="Pin Code"
                style = {{
                  width: 350
                }}
                type="number"
                enterButton="Verify"
                onSearch={() => verifyPin('Billing')}
              />
              <div style = {{ height: 5 }} />
              <Space>
                <Input
                  onChange={(e) => setBillingState(e.target.value)}
                  value={billingState}
                  placeholder="State"
                  disabled
                />
                <Input
                  onChange={(e) => setBillingCity(e.target.value)}
                  value={billingCity}
                  placeholder="City"
                  disabled
                />
              </Space>
            </div>
            <div>
              <Space>
                <ModalTitle>Shipping Address</ModalTitle>
                <Checkbox onChange={(e) => setSameAsBillingAddressBool(e.target.checked)}>Same as billing address</Checkbox>
              </Space>
              <div style = {{ height: 10 }} />
              <Input
                onChange={(e) => setShippingAddress(e.target.value)}
                value={sameAsBillingAddressBool ? billingAddress : shippingAddress}
                disabled={sameAsBillingAddressBool}
                placeholder="Shipping Address"
                style = {{
                  width: 350
                }}
              />
              <div style = {{ height: 5 }} />
              <Search
                onChange={(e) => setShippingPin(e.target.value)}
                value={sameAsBillingAddressBool ? billingPin : shippingPin}
                disabled={sameAsBillingAddressBool}
                placeholder="Pin Code"
                style = {{
                  width: 350
                }}
                type="number"
                enterButton="Verify"
                onSearch={() => verifyPin('Shipping')}
              />
              <div style = {{ height: 5 }} />
              <Space>
                <Input
                  onChange={(e) => setShippingState(e.target.value)}
                  value={sameAsBillingAddressBool ? billingState : shippingState}
                  placeholder="State"
                  disabled
                />
                <Input
                  onChange={(e) => setShippingCity(e.target.value)}
                  value={sameAsBillingAddressBool ? billingCity : shippingCity}
                  placeholder="City"
                  disabled
                />
              </Space>
            </div>
          </Row>
        }
        <div style = {{ height: 25 }} />
        <Input
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          placeholder="Description"
        />
        <div style = {{ height: 25 }} />
        <Input
          onChange={(e) => setClientEmail(e.target.value)}
          value={clientEmail}
          placeholder="Client Email"
        />
        <div style = {{ height: 25 }} />
        <Select
          style = {{ width: '100%' }}
          placeholder="Select Brand"
          value={selectedBrand}
          onSelect={(val, index) => {
            setSelectedBrand(val);
          }}
        >
          {
            brandsList.map((v, i) => <Option key={i} value={v["_id"]}>{v["name"]}</Option>)
          }
        </Select>
        <div style = {{ height: 25 }} />
        <ReactFileReader handleFiles={(files) => handleFiles(files)} fileTypes={'.csv'}>
          <Button style = {{ width: '100%' }} type="secondary" icon={<PlusOutlined />} onClick = {() => setAddOrderModal(true)} >
            Upload CSV
          </Button>
        </ReactFileReader>
        <div style = {{ height: 25 }} />
        {
          finalOrdersArr.length > 0 &&
          <Table scroll={{ y: '30vh' }} pagination = {false} dataSource = {finalOrdersArr} columns = {addModalTableColumns} />
        }
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        <Row justify="end">
          <Checkbox onChange={(e) => setCreateInvoiceBool(e.target.checked)}>Create Invoice</Checkbox>
          <Popconfirm
            title="Are you sure to create this order?"
            onConfirm={() => handleCreate()}
            okText="Create"
            placement="topRight"
            okButtonProps={{ loading: addOrderLoading }}
          >
            <Button color="primary" loading = {addOrderLoading}>Create</Button>
          </Popconfirm>
        </Row>
      </CustomModalStyle>
      <Modal
        centered
        visible={addOTPModal}
        onOk={() => handleOTP()}
        onCancel={() => setAddOTPModal(false)}
        title="Verification"
      >
        <p>Please enter the OTP to approve the order and process it.</p>
        <Input placeholder="OTP" value = {otp} onChange={(e) => setOTP(e.target.value)} allowClear />
      </Modal>
    </>
  )
}