import React, { useContext, useEffect } from 'react';
import { 
  Menu,
  Row,
  Col
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUsers,
  faShoppingCart,
  faBell,
  faCog,
  faHome,
  faStar,
  faTicketAlt
} from '@fortawesome/free-solid-svg-icons'
import { withRouter, Switch, Route, useHistory } from "react-router-dom";
import { Context as DataContext } from "../../api/dataProvider";

import { 
  Navbar,
  H1,
  NavLink,
  LinksContainer
} from './index.styles';
import { getAllowedRoutes } from "../../config";
import PrivateRoutesConfig from "../../config/PrivateRoutesConfig";
import AuthContext from '../../contexts/authContext';
import { useState } from 'react';

const { SubMenu } = Menu;

const links = [
  {
    name: "Users",
    path: "/users",
    icon: faUsers,
  },
  {
    name: "Reviews",
    path: "/reviews",
    icon: faStar,
  },
  {
    name: "Tickets",
    icon: faTicketAlt,
    menus: [
      {
        name: "Events",
        path: '/events'
      },
      {
        name: "Clubs",
        path: '/clubs'
      }
    ]
  },
  // {
  //   name: "Banned Users",
  //   path: "/banned-users",
  //   icon: faUsers,
  // },
  {
    name: "Orders",
    icon: faShoppingCart,
    menus: [
      {
        "name": "Vouchers",
        path: "/voucherorders"
      },
      {
        "name": "Buyzone Vouchers",
        path: "/buyzonevoucherorders"
      },
      {
        "name": "Products",
        path: "/productorders"
      },
      {
        "name": "Digital Store",
        path: "/digitalstoreorders"
      },
      {
        "name": "Admin Vouchers",
        path: "/adminvoucherorders"
      },
      {
        "name": "Admin Products",
        path: "/adminproductorders"
      }
    ]
  },
  {
    name: "Home",
    icon: faHome,
    menus: [
      {
        "name": "Banners",
        path: "/banners"
      },
      {
        "name": "Products",
        path: "/products"
      },
      {
        "name": "Digital Stores",
        path: "/digitalstores"
      },
      {
        "name": "Inventory",
        path: "/inventory"
      },
      {
        "name": "Sections",
        path: "/sections"
      },
      {
        "name": "Sale",
        path: "/sale"
      },
      {
        "name": "Vouchers",
        path: "/vouchers"
      },
      {
        "name": "Buyzone Vouchers",
        path: "/buyzone-vouchers"
      },
      {
        "name": "Buyzone Vouchers Types",
        path: "/buyzone-vouchers-types"
      },
      {
        "name": "Secret Codes",
        path: "/secret-codes"
      },
    ]
  }
];

function DashboardScreen() {

  const [allowedRoutes, setAllowedRoutes] = useState([]);
  let history = useHistory();
  const {
    login
  } = useContext(DataContext);
  const [menuLinks, setMenuLinks] = useState(links);

  const { role } = useContext(AuthContext);

  useEffect(() => {
    let routes = getAllowedRoutes(PrivateRoutesConfig, role);
    setAllowedRoutes(routes);
    let newLinks = [];
    for (let menuItem of links) {
      if (menuItem["menus"] == null) {
        let route = routes.find((val) => val["path"] == `/dashboard${menuItem["path"]}`);
        if (route != null)
          newLinks.push(menuItem);
      } else {
        let finalSubMenus = [];
        for (let subMenuItem of menuItem["menus"]) {
          let route = routes.find(val => val["path"] == `/dashboard${subMenuItem["path"]}`)
          if (route != null)
            finalSubMenus.push(subMenuItem);
        }
        menuItem["menus"] = finalSubMenus;
        if (finalSubMenus.length > 0)
          newLinks.push(menuItem);
      }
    }
    setMenuLinks(newLinks);
  }, [])

  return (
    <>
      <Navbar>
        <H1>BUYZONE</H1>
        <LinksContainer>
          <NavLink>
            <FontAwesomeIcon icon={faBell} style = {{ marginRight: 10 }} />
          </NavLink>
          <NavLink>
            <FontAwesomeIcon icon={faCog} style = {{ marginRight: 10 }} />
          </NavLink>
          <NavLink>
            Log out
          </NavLink>
        </LinksContainer>
      </Navbar>
      <Row>
        <Col span = {4}>
          <Menu
            style={{ height: '92vh', background: "#fafafa", fontSize: 12 }}
            // defaultSelectedKeys={['Users']}
            // defaultOpenKeys={['sub1']}
            mode="inline"
          >
            {
              menuLinks.map((item) => item["menus"] == null ? 
                <Menu.Item key={item["path"]} onClick = {() => history.push('/dashboard' + item["path"])}>
                  <span>
                    <FontAwesomeIcon icon={item["icon"]} style = {{ marginRight: 10 }} />
                    <span>{item["name"]}</span>
                  </span>
                </Menu.Item>
                :
                <SubMenu
                  key={item["path"]}
                  style = {{ fontSize: 12 }}
                  title={
                      <span>
                        <FontAwesomeIcon icon={item["icon"]} style = {{ marginRight: 10 }} />
                        <span>{item["name"]}</span>
                      </span>
                  }
                >
                  {
                    item["menus"].map((subItem) => <Menu.Item style = {{ fontSize: 12 }} key={subItem["path"]} onClick = {() => history.push('/dashboard' + subItem["path"])}>{subItem["name"]}</Menu.Item>)
                  }
                </SubMenu>
              )
            }
          </Menu>
        </Col>
        <Col style = {{ padding: '0.5rem 1rem' }} span = {20}>
          <Switch>
          {allowedRoutes.map((route) => {
            const { 
              path, 
              component,
            } = route;
            return (
            <Route
              key={path}
              path={path}
              component={component}
            >
            </Route>
            )
          })}
            {/* <Route path="/dashboard/users" component={Users} />{" "}
            <Route path="/dashboard/voucherorders" component={VoucherOrders} />{" "}
            <Route path="/dashboard/productorders" component={ProductsOrder} />{" "}
            <Route path="/dashboard/digitalstoreorders" component={DigitalStoreOrders} />{" "}
            <Route path="/dashboard/adminvoucherorders" component={AdminVoucherOrders} />{" "}
            <Route path="/dashboard/banners" component={Banners} />{" "}
            <Route path="/dashboard/products" component={Products} />{" "}
            <Route path="/dashboard/sections" component={Sections} />{" "}
            <Route path="/dashboard/inventory" component={Inventory} />{" "}
            <Route path="/dashboard/digitalstores" component={DigitalStores} />{" "} */}
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default withRouter(DashboardScreen);