import React from 'react';
import {
  ModalTitle,
  Divider,
  Name,
  ModalTitleDesc,
  CustomModalStyle
} from '../index.styles'
import {
  Table,
  Button,
  Row,
  Col,
  Space,
  Popconfirm,
  message,
  Modal,
  Input
} from 'antd';
import moment from 'moment';
import { Context as DataContext } from "../../../../../../api/dataProvider";
import { useContext } from 'react';
import { useState } from 'react';
import { errorCodes } from '../../../../../../statusCodes';
import renderHTML from 'react-render-html';

export const AdminVoucherOrderModal = ({
  data
}) => {

  const [addOTPModal, setAddOTPModal] = useState(false)
  const [otp, setOTP] = useState();

  var [htmlInvoice, setHTMLInvoice] = useState("");
  const [invoice, setInvoiceOpen] = useState(false);

  const {
    approveAdminVoucherOrder,
    downloadAdminVoucherOrder,
    cancelAdminVoucherOrder,
    checkStatusAdminVoucherOrder,
    getInvoice
  } = useContext(DataContext);

  const addModalTableColumns = [
    {
      title: 'Price (₹)',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
    }
  ];

  const cancelOrder = () => {

  }

  const approveOrder = async () => {
    if (!otp) {
      message.error('Please enter OTP before proceeding');
      return;
    }
    setAddOTPModal(false);
    try {
      let resp = await approveAdminVoucherOrder({
        "orderID": data["orderID"],
        otp
      });
      // setAddOrderLoading(false);
      if (resp.status == 200) {
        message.success(resp.data["message"]);
      }
      else
        console.log(resp.data);
    } catch (e) {
      let { response}  = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
        if (code == errorCodes.ADMIN_VOUCHERS.OTP_EXPIRED || code == errorCodes.ADMIN_VOUCHERS.WRONG_OTP) {
          setAddOTPModal(true);
        }
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  const downloadOrder = async () => {
    try {
      let resp = await downloadAdminVoucherOrder(data);
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', data["orderID"] + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const viewInvoice = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getInvoice(data["paymentDetails"]["invoiceID"]);
      hide();
      setHTMLInvoice(resp);
      setInvoiceOpen(true);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  return (
    <>
      <Row align="middle" justify="space-between">
        <ModalTitle>{data["orderID"]}</ModalTitle>
        <Row>
          <Col>
            <ModalTitle>Admin</ModalTitle>
            <div style = {{ height: 5 }} />
            <ModalTitleDesc>{data.admin.firstName} {data.admin.lastName}</ModalTitleDesc>
          </Col>
          <div style = {{ width: 50 }} />
          <Col>
            <ModalTitle>Brand</ModalTitle>
            <div style = {{ height: 5 }} />
            <ModalTitleDesc>{data.brand.name}</ModalTitleDesc>
          </Col>
          <div style = {{ width: 50 }} />
          <Col>
            <ModalTitle>Total Amount</ModalTitle>
            <div style = {{ height: 5 }} />
            <ModalTitleDesc>{data.totalAmount}</ModalTitleDesc>
          </Col>
          <div style = {{ width: 50 }} />
          <Col>
            <ModalTitle>Ordered On</ModalTitle>
            <div style = {{ height: 5 }} />
            <ModalTitleDesc>{moment(data["createdAt"]).format('DD MMM YYYY, mm:hh A')}</ModalTitleDesc>
          </Col>
          <div style = {{ width: 50 }} />
        </Row>
      </Row>
      <div style = {{ height: 25 }} />
      <Divider />
      {
        data.client != null &&
        <>
          <div style = {{ height: 25 }} />
          <Space
            align = "start"
            size = "large"
          >
            <div>
              <ModalTitle>Client Details</ModalTitle>
              <div style = {{ height: 5 }} />
              <ModalTitleDesc>{data.client.name}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.gst}</ModalTitleDesc>
            </div>
            <div>
              <ModalTitle>Billing Address</ModalTitle>
              <div style = {{ height: 5 }} />
              <ModalTitleDesc>{data.client.billingAddress.addressLine}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.billingAddress.city}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.billingAddress.state}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.billingAddress.pinCode}</ModalTitleDesc>
            </div>
            <div>
              <ModalTitle>Shipping Address</ModalTitle>
              <div style = {{ height: 5 }} />
              <ModalTitleDesc>{data.client.shippingAddress.addressLine}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.shippingAddress.city}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.shippingAddress.state}</ModalTitleDesc>
              <ModalTitleDesc>{data.client.shippingAddress.pinCode}</ModalTitleDesc>
            </div>
            {
              data.paymentDetails.shipmentCharges != null &&
              <div>
                <ModalTitle>Shipment Charges</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>₹{data.paymentDetails.shipmentCharges}</ModalTitleDesc>
              </div>
            }
          </Space>
        </>
      }
      {
        data.description.length != 0 &&
        <div style = {{ marginTop: 15 }}>
          <ModalTitle>Description</ModalTitle>
          <div style = {{ height: 5 }} />
          <ModalTitleDesc>{data.description}</ModalTitleDesc>
        </div>
      }
      <div
        style = {{
          minHeight: 250
        }}
      >
        <div style = {{ height: 25 }} />
        <Table scroll={{ y: '30vh' }} pagination = {false} dataSource = {data["pineLabsOrder"]["products"]} columns = {addModalTableColumns} />
      </div>
      <div style = {{ height: 25 }} />
      <Divider />
      <div style = {{ height: 25 }} />
      <Row justify="end">
        <Space>
          {
            (data["paymentDetails"]["zohoInvoiceID"] != "" && data["paymentDetails"]["zohoInvoiceID"] != "C") &&
            <Button type="secondary" onClick={() => viewInvoice()}>View Invoice</Button>
          }
          {
            data["status"] == 403 &&
            <Button type="primary" onClick={() => downloadOrder()}>Download Order</Button>
          }
          {
            data["status"] == 400 &&
            <Button type="primary" onClick={() => setAddOTPModal(true)}>Approve Order</Button>
          }
          {
            data["status"] == 400 &&
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              onConfirm = {() => cancelOrder()}
            >
              <Button type="danger">Cancel Order</Button>
            </Popconfirm>
          }
        </Space>
      </Row>
      <Modal
        centered
        visible={addOTPModal}
        onOk={() => approveOrder()}
        onCancel={() => setAddOTPModal(false)}
        title="Verification"
      >
        <p>Please enter the OTP to approve the order and process it.</p>
        <Input placeholder="OTP" value = {otp} onChange={(e) => setOTP(e.target.value)} allowClear />
      </Modal>
      <CustomModalStyle
				centered
				visible={invoice}
				onOk={() => setInvoiceOpen(false)}
				onCancel={() => setInvoiceOpen(false)}
				width = {650}
			>
				{
					renderHTML(htmlInvoice)
				}
			</CustomModalStyle>
    </>
  )
}