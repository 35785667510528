import React, { useEffect, useState } from 'react';
import {
	ModalTitle,
	Divider,
	Name,
	Other,
	Title
} from '../index.styles'
import { 
	Col,
	Row,
	Select,
	Input,
	Modal,
	Button,
	message,
	Table,
	Space,
	Tag,
	Popover,
	Avatar
} from 'antd';

const { Option } = Select;

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export function CreateOrder({
	events,
	productsList,
	createOrder,
	loader
}) {

	const [eventID, setEventID] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [products, setProducts] = useState([]);

	const [addProduct, setAddProduct] = useState(false);

	const [productID, setProductID] = useState();
	const [customizationID, setCustomizationID] = useState();
	const [customizationIndex, setCustomizationIndex] = useState();
	const [productData, setProductData] = useState();
	const [quantity, setQuantity] = useState();
	const [price, setPrice] = useState();
	const [sku, setSKU] = useState();

	const [totalAmount, setTotalAmount] = useState(0);

	const getSKUProduct = async () => {
		console.log("Clicked");
		console.log(sku);
		console.log(productsList.map((v) => v["sku"]));
		const product = productsList.find((val) => val["sku"] == Number(sku));
		if (product != null) {
			setProductID(product["_id"])
			setProductData(product)
		}
		else {
			message.info('No matching SKU found')
			setProductID();
		}
	}

	useEffect(() => {
		getTotalAmount();
	}, [products])

	const getTotalAmount = async () => {
		if (products.length > 0) {
			var totalAmount = 0;
			for (let product of products) {
				totalAmount += (product["price"] * product["quantity"]);
				setTotalAmount(totalAmount);
			}
		} else {
			// console.log("No products");
			setTotalAmount(0);
		}
	}

	const removeItemFromBill = (index) => {
		let newArr = [...products];
		newArr.splice(index, 1);
		setProducts(newArr);
	}

	const columns = [
    {
			title: '',
			dataIndex: 'index',
			key: 'image',
			render: index => <Popover content={PopoverProductDetails(productsList[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${productsList[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
			width: 60
    },
    {
			title: 'Name',
			dataIndex: 'index',
			key: 'name',
			render: index => <Name>{productsList[index]["title"]}</Name>,
    },
    {
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
      title: '',
      dataIndex: 'operation',
			width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            Remove
          </a>
        );
      },
    }
  ];

	return (
		<>
			<div>
				<ModalTitle>Create Order</ModalTitle>
			</div>
			<div style = {{ height: 25 }} />
			<Divider />
			<div style = {{ height: 25 }} />
			<Select
				loading = {productsList.length == 0}
				showSearch
				style = {{
					width: '100%'
				}}
				placeholder="Select Event"
				optionFilterProp="children"
				onChange={(val) => setEventID(val)}
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
			>
				{
					events.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
				}
			</Select>
			<div style = {{ height: 20 }} />
			<Row justify="space-between">
				<Col span={7}>
					<Input placeholder="First Name" value = {firstName} onChange={(e) => setFirstName(e.target.value)} />
				</Col>
				<Col span={7}>
					<Input placeholder="Last Name" value = {lastName} onChange={(e) => setLastName(e.target.value)} />
				</Col>
				<Col span={7}>
					<Input placeholder="Phone Number" type="number" value = {phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
				</Col>
			</Row>
			<div style = {{ height: 20 }} />
			<Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
			<div style = {{ height: 20 }} />
			<Table dataSource={products} columns={columns} scroll={{ y: '150px' }} />
			<div style = {{ height: 20 }} />
			<Divider />
			<div style = {{ height: 20 }} />
			<div
				style = {{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Name>
					Total: ₹{totalAmount}
				</Name>
				<Button loading = {loader} type='primary' onClick={() => {
					createOrder({
						eventID,
						firstName,
						lastName,
						phoneNumber,
						products,
						totalAmount
					});
					// setEventID()
					// setFirstName()
					// setLastName()
					// setPhoneNumber()
					// setProducts([]);
					// setTotalAmount(0);
				}}>Create</Button>
			</div>
			<Modal
				visible={addProduct}
				onOk={() => {
					if (productID == null) {
						message.error('Please select a product first.')
						return
					}
					if (quantity == null) {
						message.error('Please add a quantity first.')
						return
					}
					if ((price != null && price != '') && price <= 0) {
						message.error('Please add a valid price.')
						return
					}
					if (productData["customizations"].length != 0 && customizationID == null) {
						message.error('Please select a customization.')
						return
					}
					const newProducts = [...products];
					const index = productsList.findIndex((val) => val["_id"] == productID);
					console.log(productData);
					console.log(customizationID);
					let customizationObject = productData["customizations"].find((val) => val["size"].toString() == customizationID.toString());
					if (customizationObject != null)
						customizationObject = customizationObject["compositeProduct"];
					newProducts.push({
						"details": productID,
						"quantity": quantity,
						"price": price == null ? customizationObject != null ? (customizationObject["discountPrice"] || productsList[index]["originalPrice"]) : (productsList[index]["discountPrice"] == null ? productsList[index]["originalPrice"] : productsList[index]["discountPrice"]) : price,
						"index": index,
						"customization": customizationID == null ? null : {
							"size": customizationID
						}
					})
					setProducts(newProducts);
					setAddProduct(false);
					setProductID();
					setQuantity();
					setPrice();
					message.success('Product added successfully');
				}}
				onCancel={() => setAddProduct(false)}
				okText = "Add"
				width = {950}
			>
				<div>
					<ModalTitle>Select Product</ModalTitle>
				</div>
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={20}>
						<Input placeholder="SKU" type="number" value = {sku} onChange={(e) => setSKU(e.target.value)} />
					</Col>
					<Col span={3}>
						<Button type='primary' onClick={() => getSKUProduct()}>Search</Button>
					</Col>
				</Row>
				<div style = {{ height: 20 }} />
				<Divider />
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={7}>
						<Select
							showSearch
							style = {{
								width: '100%'
							}}
							placeholder="Select Product"
							optionFilterProp="children"
							value={productID}
							onChange={(val) => {setProductID(val); setProductData(productsList.find((v) => v["_id"] == val))}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								productsList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
							}
						</Select>
					</Col>
					<Col span={7}>
						<Input placeholder="Quantity" type="number" value = {quantity} onChange={(e) => setQuantity(e.target.value)} />
					</Col>
					<Col span={7}>
						<Input placeholder="Price (Optional)" type="number" value = {price} onChange={(e) => setPrice(e.target.value)} />
					</Col>
					{
						productData && productData["customizations"].length > 0 &&
						<Select
              showSearch
              style = {{
                width: '100%',
								marginTop: 15
              }}
              placeholder="Select Customization"
              optionFilterProp="children"
              onChange={(val) => setCustomizationID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                productData.customizations.map((v) => <Option value={v["size"]} key={v["size"]}>{
                  (productData.sizes.length > 0 ? (productData.sizes.find((val) => val["_id"] == v["size"])["value"] + ' ') : '') + 
                  (productData.colors.length > 0 ? productData.colors.find((val) => val["_id"] == v["color"])["name"] : '')
                }</Option>)
              }
            </Select>
					}
				</Row>
			</Modal>
		</>
	)
}