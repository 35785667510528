import React, { useContext, useEffect, useRef, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
	Modal,
	Input,
	DatePicker,
	Select,
	Space,
	message,
	Table,
	Upload,
	Avatar,
	Tag,
	Checkbox
} from 'antd';
import {Context as DataContext} from '../../../../../api/dataProvider';
import { Name, Other, Title, ModalTitle, ModalTitleDesc } from './index.styles';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

const { Option } = Select;

export function EventsScreen() {

	const [eventModal, setEventModal] = useState(false);
	const [screenLoading, setScreenLoading] = useState(false);
	const [events, setEvents] = useState([]);
	const [clubs, setClubs] = useState([]);

	const [title, setTitle] = useState();
	const [description, setdescription] = useState();
	const [tickets, setTickets] = useState([]);
	const [ticketModal, setTicketModal] = useState(false)
	const [imageUrl, setimageUrl] = useState();
	const [originalPrice, setoriginalPrice] = useState();
	const [discountPrice, setdiscountPrice] = useState();
	const [addressLine, setaddressLine] = useState();
	const [eventDate, seteventDate] = useState();
	const [club, setclub] = useState()
	const [latitude, setlatitude] = useState()
	const [longitude, setlongitude] = useState()

	const [ticketType, setTicketType] = useState()
	const [ticketOriginalPrice, setTicketOriginalPrice] = useState()
	const [ticketDiscountPrice, setTicketDiscountPrice] = useState()
	const [totalCount, setTotalCount] = useState()
	const [coverCharges, setCoverCharges] = useState(false)

	const [addEventLoading, setAddEventLoading] = useState(false)

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

	const [uploadLoading, setUploadLoading] = useState(false);

	const ticketTypeToText = {
		3101: "Couple",
		3100: "Stag"
	}

	const ticketColumns = [
		{
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: data => <Name>{ticketTypeToText[data]}</Name>,
    },
		{
      title: 'Original Price',
      dataIndex: 'originalPrice',
      key: 'originalPrice',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'Discount Price',
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'Total Count',
      dataIndex: 'totalTickets',
      key: 'totalTickets',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'Cover Charges',
      dataIndex: 'coverCharges',
      key: 'coverCharges',
      render: data => <Name>{data ? 'Yes' : 'No'}</Name>,
    },
	];

	const columns = [
    {
      title: '',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: imageUrl => <Avatar src = {imageUrl} size="large" style = {{ cursor: 'pointer' }} />,
      width: 45
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: title => <Title>{title}</Title>,
    },
    {
      title: 'Total Tickets',
      dataIndex: 'totalTickets',
      key: 'totalTickets',
      render: quantity => <Other>{quantity}</Other>,
    },
		{
      title: 'Club',
      dataIndex: 'club',
      key: 'club',
      render: club => <Other>{club["clubName"]}</Other>,
    }
  ];

	const {
		handleEventImageUpload,
		createTicketEvent,
		getAllClubs,
		getAllEvents
	} = useContext(DataContext);

	const editorRef = useRef(null);
	const log = () => {
		if (editorRef != null && editorRef.current != null)
			return new Promise (async (resolve, reject) => {
				resolve(editorRef.current.getContent());
			})
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleImageListPreview = async (file) => {
    if (!file.url && !file.preview) {
			file.preview = getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	const uploadButton = (
		<div>
			{uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB');
		}
		return isJpgOrPng && isLt2M;
	}

	const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
      return;
    }
    if (info.file.status === 'done') {
			setUploadLoading(false);
    }
	}

	useEffect(() => {
		fetchInitialData();
	}, []);

	const fetchInitialData = async () => {
		setScreenLoading(true);
		let clubs = await getAllClubs()
		let events = await getAllEvents()
		setClubs(clubs);
		setEvents(events)
		setScreenLoading(false);
	}

	const validation = () => {
		let data = {
			title: title,
			description: description,
			imageUrl: imageUrl,
			originalPrice: originalPrice,
			discountPrice: discountPrice,
			addressLine: addressLine,
			eventDate: eventDate,
			club: club,
			latitude: latitude,
			longitude: longitude
		}
		for (let key in data) {
			if (data[key] == null) {
				if (key === 'imageUrl') {
					message.error(`Please upload an image`)
					return false;
				}
				message.error(`Please fill ${key}`)
				return false;
			}
		}
		return true;
	}

	const ticketValidation = () => {
		let data = {
			ticketType,
			ticketOriginalPrice,
			// ticketDiscountPrice,
			totalCount,
			coverCharges
		}
		for (let key in data) {
			if (data[key] == null) {
				message.error(`Please fill ${key}`)
				return false;
			}
		}
		return true;
	}

	const handleCreateNewEvent = async () => {
		if (!validation()) return;
		setAddEventLoading(true);
		try {
			const resp = await createTicketEvent({
				title,
				description,
				imageUrl,
				originalPrice,
				discountPrice,
				addressLine,
				eventDate,
				tickets,
				club,
				latitude,
				longitude,
				termsConditions: await log()
			})
			console.log(resp.data);
			message.success('Event added successfully!');
			setAddEventLoading(false);
			window.location.reload()
		} catch (e) {
			setAddEventLoading(false);
			message.error(e.response.data.message ?? "Some error occurred");
			console.error(e)
		}
	}

	return <>
		<Button type="primary"
			onClick = {() => setEventModal(true)}
			loading = {screenLoading}
		>
			Create New Event
		</Button>
		<div style = {{ height: 20 }} />
		<Table loading = {screenLoading} dataSource={events} columns={columns} scroll={{ y: '70vh' }} />
		<Modal
			visible={eventModal}
			onOk={handleCreateNewEvent}
			onCancel={() => setEventModal(false)}
			afterClose={() => {
				setTitle()
				setdescription()
				setimageUrl()
				setoriginalPrice()
				setdiscountPrice()
				setaddressLine()
				seteventDate()
				setclub()
				setlatitude()
				setlongitude()
			}}
			okText = "Add"
			width = {850}
			title = "Add Event"
			okButtonProps={{ loading: addEventLoading }}
		>
			<Upload
				listType="picture-card"
				showUploadList={false}
				accept="image/*" 
				onPreview={(file) => handleImageListPreview(file)}
				customRequest={async ({file, onSuccess, onError}) => {
					try {
						console.log(file)
						const key = await handleEventImageUpload(file);
						setimageUrl("https://buyzone.s3.ap-south-1.amazonaws.com/images/" + key);
						onSuccess('Ok');
					} catch (e) {
						onError('Error')
					}
				}}
				beforeUpload={beforeUpload}
				onChange={(e) => handleChange(e)}
			>
				{imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
			</Upload>
			<div style = {{ height: 20 }} />
			<div style={{ display: 'flex', gap: '20px' }}>
				<Input placeholder={'Title'} onChange={(e) => setTitle(e.target.value)} />
				<Input placeholder={'Original Ticket Price'} onChange={(e) => setoriginalPrice(e.target.value)} />
				<Input placeholder={'Discount Ticket Price'} onChange={(e) => setdiscountPrice(e.target.value)} />
			</div>
			<div style = {{ height: 20 }} />
			<div style={{ display: 'flex', gap: '20px' }}>
				<Input placeholder={'Latitude'} onChange={(e) => setlatitude(e.target.value)} />
				<Input placeholder={'Longitude'} onChange={(e) => setlongitude(e.target.value)} />
			</div>
			<div style = {{ height: 20 }} />
			<Input.TextArea rows={4} placeholder={'Description'} onChange={(e) => setdescription(e.target.value)} />
			<div style = {{ height: 20 }} />
			<Button type='primary' disabled = {ticketModal} onClick={() => setTicketModal(true)}>Add Tickets</Button>
			<div style = {{ height: 10 }} />
			<Table dataSource={tickets} columns={ticketColumns} scroll={{ y: '150px' }} />
			<div style = {{ height: 20 }} />
			<div style={{ display: 'flex', gap: '20px' }}>
				<Input placeholder={'Address Line'} onChange={(e) => setaddressLine(e.target.value)} />
				<DatePicker style={{ width: 450 }} placeholder="Event Date" format="DD MMM YYYY, hh:mm a" showTime onChange={(val) => {console.log("Date Changed")}} value={eventDate == null ? eventDate : moment(eventDate)} onOk={(val) => seteventDate(val.toDate())} />
				<Select
          showSearch
          style = {{
            width: '100%'
          }}
          placeholder="Select Club"
          optionFilterProp="children"
          value={club}
          onChange={(val) => setclub(val)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            clubs.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["clubName"]}</Option>)
          }
        </Select>
			</div>
			<div style = {{ height: 20 }} />
			<Name>Terms & Conditions</Name>
			<div style = {{ height: 8 }} />
			<Editor
				apiKey = "xmvxnian23wvi7o7b6mqiwysfyr2ic9uya6is6cko48sezt4"
				onInit={(evt, editor) => editorRef.current = editor}
				initialValue={"<ol><li></li></ol>"}
				init={{
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help'
					],
					toolbar: 'bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist'
					,
					content_style: 'body { font-family: Montserrat,Arial,sans-serif; font-size:14px }'
				}}
			/>
		</Modal>
		<Modal
			visible={ticketModal}
			onOk={() => {
				if (!ticketValidation()) return;
				let newTickets = [
					...tickets,
					{
						type: ticketType,
						originalPrice: ticketOriginalPrice,
						discountPrice: ticketDiscountPrice,
						totalTickets: totalCount,
						coverCharges
					}
				];
				setTickets(newTickets)
				message.success('Ticket added successfully');
				setTicketModal(false)
			}}
			onCancel={() => setTicketModal(false)}
			afterClose={() => {
				setTicketType()
				setTicketOriginalPrice()
				setTicketDiscountPrice()
				setTotalCount()
				setCoverCharges(false)
			}}
			okText = "Add"
			width = {650}
			title = "Add Ticket"
		>
			<Select
				style = {{
					width: '100%'
				}}
				placeholder="Select Ticket Type"
				optionFilterProp="children"
				value={ticketType}
				onChange={(val) => setTicketType(val)}
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
			>
				<Option value={3100} key={3100}>Stag</Option>
				<Option value={3101} key={3101}>Couple</Option>
			</Select>
			<div style = {{ height: 20 }} />
			<div style={{ display: 'flex', gap: '20px' }}>
				<Input placeholder={'Ticket Count'} onChange={(e) => setTotalCount(e.target.value)} />
				<Input placeholder={'Original Ticket Price'} onChange={(e) => setTicketOriginalPrice(e.target.value)} />
				<Input placeholder={'Discount Ticket Price'} onChange={(e) => setTicketDiscountPrice(e.target.value)} />
			</div>
			<div style = {{ height: 20 }} />
			<Checkbox checked = {coverCharges} onChange={(e) => setCoverCharges(e.target.checked)}>Cover Charges</Checkbox><div style = {{ height: 20 }} />
		</Modal>
	</>;
}