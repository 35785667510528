import { DigitalStoreOrders } from '../Screens/Dashboard/Menus/Orders/DigitalStores';
import { Products } from '../Screens/Dashboard/Menus/Home/Products';
import { VoucherOrders } from '../Screens/Dashboard/Menus/Orders/VoucherOrders';
import { Sections } from '../Screens/Dashboard/Menus/Home/Sections';
import { AdminVoucherOrders } from '../Screens/Dashboard/Menus/Orders/AdminVouchers';
import { Inventory } from '../Screens/Dashboard/Menus/Home/Inventory';
import { DigitalStores } from '../Screens/Dashboard/Menus/Home/DigitalStores';
import ProductsOrder from '../Screens/Dashboard/Menus/Orders/Products';
import Banners from '../Screens/Dashboard/Menus/Home/Banners';
import Users from '../Screens/Dashboard/Menus/Users';
import Roles from './Roles'
import { AdminProductOrders } from '../Screens/Dashboard/Menus/Orders/AdminProducts';
import { Sale } from '../Screens/Dashboard/Menus/Home/Sale';
import { Vouchers } from '../Screens/Dashboard/Menus/Home/Voucher';
import { BuyzoneVouchers } from '../Screens/Dashboard/Menus/Home/BuyzoneVoucher';
import SecretCode from '../Screens/Dashboard/Menus/Home/SecretCode';
import BannedUsers from '../Screens/Dashboard/Menus/BannedUsers';
import Reviews from '../Screens/Dashboard/Menus/Reviews';
import { EventsScreen } from '../Screens/Dashboard/Menus/Tickets/Events';
import { ClubsScreen } from '../Screens/Dashboard/Menus/Tickets/Clubs';
import { BuyzoneVoucherOrders } from '../Screens/Dashboard/Menus/Orders/BuyzoneVoucherOrders';
import { BuyzoneVoucherTypes } from '../Screens/Dashboard/Menus/Home/BuyzoneVoucherTypes';


export default [
  {
    component: Users,
    path: '/dashboard/users',
    title: 'Users',
    exact: true,
    permission: [
      Roles.Admin,
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: BannedUsers,
    path: '/dashboard/banned-users',
    title: 'Banned Users',
    exact: true,
    permission: [
      Roles.Admin,
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: VoucherOrders,
    path: '/dashboard/voucherorders',
    title: 'Voucher Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head'],
      Roles['Finance'],
      Roles['Sales Head'],
      Roles['Warehouse Head'],
      Roles['Warehouse Supervisor'],
    ]
  },
  {
    component: ProductsOrder,
    path: '/dashboard/productorders',
    title: 'Product Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head'],
      Roles['Finance'],
      Roles['Sales Head'],
      Roles['Warehouse Head'],
      Roles['Warehouse Supervisor'],
      Roles['Products Supervisor']
    ]
  },
  {
    component: DigitalStoreOrders,
    path: '/dashboard/digitalstoreorders',
    title: 'Digital Store Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head'],
      Roles['Finance'],
      Roles['Sales Head'],
      Roles['Warehouse Head'],
      Roles['Warehouse Supervisor'],
    ]
  },
  {
    component: AdminVoucherOrders,
    path: '/dashboard/adminvoucherorders',
    title: 'Admin Voucher Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
    ]
  },
  {
    component: Banners,
    path: '/dashboard/banners',
    title: 'Banners',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
    ]
  },
  {
    component: Products,
    path: '/dashboard/products',
    title: 'Products',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head'],
      Roles['Finance'],
      Roles['Sales Head'],
      Roles['Warehouse Head'],
      Roles['Warehouse Supervisor'],
      Roles['Products Supervisor']
    ]
  },
  {
    component: Sections,
    path: '/dashboard/sections',
    title: 'Sections',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: Inventory,
    path: '/dashboard/inventory',
    title: 'Inventory',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Products Supervisor']
    ]
  },
  {
    component: DigitalStores,
    path: '/dashboard/digitalstores',
    title: 'Digital Stores',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: AdminProductOrders,
    path: '/dashboard/adminproductorders',
    title: 'Admin Product Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: AdminProductOrders,
    path: '/dashboard/adminproductorders',
    title: 'Admin Product Orders',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Warehouse Head']
    ]
  },
  {
    component: Sale,
    path: '/dashboard/sale',
    title: 'Sale',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
    ]
  },
  {
    component: Vouchers,
    path: '/dashboard/vouchers',
    title: 'Vouchers',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
    ]
  },
  {
    component: BuyzoneVouchers,
    path: '/dashboard/buyzone-vouchers',
    title: 'Buyzone Vouchers',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
    ]
  },
  {
    component: SecretCode,
    path: '/dashboard/secret-codes',
    title: 'Secret Codes',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
    ]
  },
  {
    component: Reviews,
    path: '/dashboard/reviews',
    title: 'Reviews',
    exact: true,
    permission: [
      Roles['Admin'],
      Roles['Customer Care Head'],
      Roles['Customer Care Executive'],
    ]
  },
  {
    component: EventsScreen,
    path: '/dashboard/events',
    title: 'Events',
    exact: true,
    permission: [
      Roles["Admin"],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: ClubsScreen,
    path: '/dashboard/clubs',
    title: 'Clubs',
    exact: true,
    permission: [
      Roles["Admin"],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: BuyzoneVoucherOrders,
    path: '/dashboard/buyzonevoucherorders',
    title: 'Buyzone Voucher Orders',
    exact: true,
    permission: [
      Roles["Admin"],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
  {
    component: BuyzoneVoucherTypes,
    path: '/dashboard/buyzone-vouchers-types',
    title: 'Buyzone Voucher Orders',
    exact: true,
    permission: [
      Roles["Admin"],
      Roles['Customer Care Executive'],
      Roles['Customer Care Head']
    ]
  },
]