import { Button, Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CustomizationsTab } from './customizations';
import {
	ModalTitle,
	Divider,
	MenuButton,
	FooterButton
} from './index.styles';
import { InventoryTab } from './inventory';
import { OtherDetailsTab } from './otherDetails';
import { VisualDetailsTab } from './visualDetails';
import { Context as DataContext } from '../../../../../../api/dataProvider';
import { useContext } from 'react';
import { PrebookingTab } from './prebooking';


export function CreateProductModalData({
	data,
	companies,
	categories
}) {

	const {
		addProduct,
		editProduct
	} = useContext(DataContext);

	const [selectedTab, setSelectedTab] = useState();
	const [customItemList, setCustomItemList] = useState([]);
	const [details, setDetails] = useState({})
	const [edit, setEdit] = useState(false);
	const [save, setSave] = useState(false);
	const [addProductLoader, setAddProductLoader] = useState(false);

	const visualDetailsRef = React.useRef();
	const customizationsRef = React.useRef();
	const otherDetailsRef = React.useRef();
	const inventoryRef = React.useRef();
	const prebookingRef = React.useRef();

	useEffect(() => {
		setSelectedTab(0);
	}, []);

	useEffect(() => {
		if (data["_id"] != null) {
			setEdit(true);
		}
	}, [])

	useEffect(() => {
		console.log("Updated Details");
		if (save) {
			console.log("Saving");
			if (!edit)
				saveProduct();
			if (edit)
				saveEditedProduct();
		}
	}, [details])

	const saveProduct = async () => {
		console.log(details);
		if (!validate())
			return;
		setAddProductLoader(true);
		try {
			let result = await addProduct(details);
			message.success(result.message);
			setAddProductLoader(false);
		} catch (e) {
			console.log("Error")
			setAddProductLoader(false);
			let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
				message.error("Some error occurred.")
      }
		}
	}

	const saveEditedProduct = async () => {
		console.log(details);
		setAddProductLoader(true);
		try {
			details["_id"] = data["_id"]
			let result = await editProduct(details);
			message.success(result.message);
			setAddProductLoader(false);
		} catch (e) {
			console.log("Error")
			setAddProductLoader(false);
			let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
				message.error("Some error occurred.")
      }
		}
	}

	const validate = () => {
		const {
			thumbnailImage,
			images,
			title,
			originalPrice,
			discountPrice,
			description,
			company,
			position,
			category,
			gstPercent,
			dimensions,
			weight,
			limitingQuantity,
			cartQuantity,
			otherDetails,
			charges,
			shipmentType
		} = details;
		// if (!thumbnailImage) {
		// 	showError('Please add a thumbnail image.')
		// 	return false;
		// }
		// if (!shipmentType) {
		// 	showError('Please add a shipment type.')
		// 	return false;
		// }
		// if (!images || images.length == 0) {
		// 	showError('Please add at least one product image')
		// 	return false;
		// }
		// if (!title) {
		// 	showError('Please add product title');
			
		// 	return false;
		// }
		console.log(discountPrice, originalPrice)
		if (!originalPrice) {
			showError('Please add product price');
			return false
		}
		if (discountPrice != null && Number(discountPrice) >= Number(originalPrice)) {
			showError('Offer Price must be less than Original Price')
			return false;
		}
		if (!description || description.length < 50) {
			showError('Description must be at least 50 characters')
			return false;
		}
		if (!company || company == "") {
			showError('Please select company');
			return false;
		}
		if (!category || categories == "") {
			showError('Please select product category');
			return false;
		}
		if (!position) {
			showError('Please add product position');
			return false;
		}

		if (!gstPercent) {
			showError('Please add GST Percent');
			return false;
		}
		if (!dimensions.length) {
			showError('Please add length');
			return false;
		}
		if (!dimensions.breadth) {
			showError('Please add breadth');
			return false;
		}
		if (!dimensions.height) {
			showError('Please add height');
			return false;
		}
		if (!weight.value) {
			showError('Please add weight');
			return false;
		}
		if (!weight.unit) {
			showError('Please add weight unit');
			return false;
		}
		if (!limitingQuantity) {
			showError('Please add limiting quantity');
			return false;
		}
		if (!cartQuantity) {
			showError('Please add cart quantity');
			return false;
		}
		if (otherDetails.warranty == null) {
			showError('Please add warranty');
			return false;
		}
		if (otherDetails.guarantee == null) {
			showError('Please add guarantee');
			return false;
		}
		if (otherDetails.exchange == null) {
			showError('Please add exchange');
			return false;
		}
		if (otherDetails.cod == null) {
			showError('Please add COD');
			return false;
		}
		if (otherDetails.cancellation == null) {
			showError('Please add cancellation');
			return false;
		}
		if (otherDetails.shipment == null) {
			showError('Please add shipment');
			return false;
		}
		if (otherDetails.cod && (charges.cod == null || charges.cod == "")) {
			showError('Please add COD Charges');
			return false;
		}
		if (otherDetails.cancellation && (charges.cancellation == null || charges.cancellation == "")) {
			showError('Please add cancellation charges');
			return false;
		}
		if (otherDetails.shipment && (charges.shipment == null || charges.shipment == "")) {
			showError('Please add shipment charges');
			return false;
		}
		return true;
	}

	const showError = (msg) => {
		message.error(msg);
		setSave(false);
	}

	const addProductToServer = async () => {
		setSave(true);
		const visualDetailsData = await visualDetailsRef.current.handleVisualDetails();
		const customizationData = await customizationsRef.current.handleCustomizationDetails();
		const otherDetailsData = await otherDetailsRef.current.handleOtherDetails();
		const inventoryData = await inventoryRef.current.handleInventoryDetails();
		const prebookingData = await prebookingRef.current.handlePrebookingDetails();
		const finalDetails = {};
		Object.assign(finalDetails, visualDetailsData);
		Object.assign(finalDetails, customizationData);
		Object.assign(finalDetails, otherDetailsData);
		Object.assign(finalDetails, inventoryData);
		Object.assign(finalDetails, prebookingData);
		setDetails(finalDetails)
		console.log(finalDetails, details);
	}

	const editProductToServer = async () => {
		setSave(true);
		const visualDetailsData = await visualDetailsRef.current.handleVisualDetails();
		const customizationData = await customizationsRef.current.handleCustomizationDetails();
		const otherDetailsData = await otherDetailsRef.current.handleOtherDetails();
		const inventoryData = await inventoryRef.current.handleInventoryDetails();
		const prebookingData = await prebookingRef.current.handlePrebookingDetails();
		const finalDetails = {};
		Object.assign(finalDetails, visualDetailsData);
		Object.assign(finalDetails, customizationData);
		Object.assign(finalDetails, otherDetailsData);
		Object.assign(finalDetails, inventoryData);
		Object.assign(finalDetails, prebookingData);
		setDetails(finalDetails)
	}
	
	return (
		<div
		>
			<ModalTitle>Create Product</ModalTitle>
			<div style = {{ height: 25 }} />
			<Divider />
			<div style = {{ height: 45 }} />
			<Row justify="space-between" style = {{
				height: 400,
				maxHeight: 400,
				overflow: 'auto'
			}}>
				<Col span = {5} style = {{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
					<MenuButton selected={selectedTab === 0} onClick = {() => setSelectedTab(0)}>Visual Details</MenuButton>
					<div style = {{ height: 15 }} />
					<MenuButton selected={selectedTab === 2} onClick = {() => setSelectedTab(2)}>Other Details</MenuButton>
					<div style = {{ height: 15 }} />
					<MenuButton selected={selectedTab === 1} onClick = {() => setSelectedTab(1)}>Customizations</MenuButton>
					<div style = {{ height: 15 }} />
					<MenuButton selected={selectedTab === 3} onClick = {() => setSelectedTab(3)}>Inventory</MenuButton>
					<div style = {{ height: 15 }} />
					<MenuButton selected={selectedTab === 4} onClick = {() => setSelectedTab(4)}>Prebooking</MenuButton>
				</Col>
				<Col span = {18}>
					<div style = {{ display: selectedTab == 0 ? 'block' : 'none' }}>
						<VisualDetailsTab ref = {visualDetailsRef} details = {data} setDetails = {setDetails} companies = {companies} categories = {categories} />
					</div>
					<div style = {{ display: selectedTab == 1 ? 'block' : 'none' }}>
						<CustomizationsTab ref = {customizationsRef} details = {data} setDetails = {setDetails} customItemsList = {customItemList} setCustomItemsList = {setCustomItemList} />
					</div>
					<div style = {{ display: selectedTab == 2 ? 'block' : 'none' }}>
						<OtherDetailsTab ref = {otherDetailsRef} details = {data} setDetails = {setDetails} customItemsList = {customItemList} />
					</div>
					<div style = {{ display: selectedTab == 3 ? 'block' : 'none' }}>
						<InventoryTab ref = {inventoryRef} details = {data} setDetails = {setDetails} customItemsList = {customItemList} setCustomItemsList = {setCustomItemList} />
					</div>
					<div style = {{ display: selectedTab == 4 ? 'block' : 'none' }}>
						<PrebookingTab ref = {prebookingRef} details = {data} />
					</div>
				</Col>
			</Row>
			<div style = {{ height: 25 }} />
			<Row justify="end">
				<Button type = 'primary' loading = {addProductLoader} onClick = {() => edit ? editProductToServer() : addProductToServer()}>{edit ? "Edit" : "Add"}</Button>
			</Row>
		</div>
	);
}