import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Modal,
  Input,
  Space,
  Select,
  Table,
  Popover,
  Avatar,
  Tag,
  message,
  Row,
  Col,
  DatePicker,
  Popconfirm
} from 'antd';
import { Context as DataContext } from "../../../../../api/dataProvider";
import { Name, Other, Title, ModalTitle, ModalTitleDesc } from './index.styles';
import moment from 'moment';
import { saleStatusCode } from '../../../../../statusCodes';

const { Option } = Select;
const { RangePicker } = DatePicker

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export function Sale() {

  const [saleList, setSaleList] = useState([]);
  const [saleOpen, setSaleOpen] = useState(false);
  const [saleLoading, setSaleLoading] = useState(true);

  const [title, setTitle] = useState();
  const [discountType, setDiscountType] = useState();
  const [discountNumber, setDiscountNumber] = useState();
  const [productType, setProductType] = useState();
  const [company, setCompany] = useState();
  const [category, setCategory] = useState();
  const [productList, setProductList] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [maxDiscount, setMaxDiscount] = useState();

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [addProduct, setAddProduct] = useState(false);

  const [productID, setProductID] = useState();
	const [customizationIndex, setCustomizationIndex] = useState();

  const {
    getProducts,
    getCompanies,
    getCategories,
    addNewSale,
    getAllSales,
    removeSale
  } = useContext(DataContext);

  const columns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'image',
      render: index => <Popover content={PopoverProductDetails(products[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${products[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'index',
      key: 'name',
      render: index => <Name>{products[index]["title"]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            Remove
          </a>
        );
      },
    }
  ];

  const mainColumns = [
    {
      'title': 'Sale Name',
      dataIndex: 'title',
      key: 'title',
      render: i => <Name>{i}</Name>
    },
    {
      'title': 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 200,
      render: i => <Name>{moment(i).format('DD MMM YYYY, hh:mm a')}</Name>,
    },
    {
      'title': 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      width: 200,
      render: i => <Name>{moment(i).format('DD MMM YYYY, hh:mm a')}</Name>,
    },
    {
      'title': 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: i => <Name>{saleStatusCode[i]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              initiateRemoveSale(index);
            }}
          >
            <a>
              Remove
            </a>
          </Popconfirm>
        );
      },
    }
  ]

  const initiateRemoveSale = async (index) => {
    let saleID = saleList[index]["_id"];
    let hide = message.loading('Removing Sale...')
    try {
      let resp = await removeSale({ _id: saleID });
      hide();
      setSaleLoading(true);
      let newSaleList = await getAllSales();
      setSaleList(newSaleList);
      setSaleLoading(false);
    } catch (e) {
      hide();
      console.log(e);
    }
  }

  const removeItemFromBill = (index) => {
		let newArr = [...productList];
		newArr.splice(index, 1);
		setProductList(newArr);
	}

  useEffect(() => {
    getInitialData();
  }, [])

  const getInitialData = async () => {
    try {
      let companiesList = await getCompanies();
      let categoriesList = await getCategories();
      let productsList = await getProducts();
      let newSaleList = await getAllSales();
      setSaleList(newSaleList);
      setCompanies(companiesList);
      setCategories(categoriesList);
      setProducts(productsList);
      setSaleLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const validateData = () => {
    if (title == null) {
      message.error("Please enter title")
      return false;
    }
    if (discountType == null) {
      message.error("Please select discount type")
      return false;
    }
    if (discountNumber == null) {
      message.error("Please enter discount number")
      return false;
    }
    if (discountNumber <= 0 || (discountType == 1 && discountNumber > 100)) {
      message.error("Please enter a valid discount number")
      return false;
    }
    if (startTime == null) {
      message.error("Please select start time")
      return false;
    }
    if (endTime == null) {
      message.error("Please select end time")
      return false;
    }
    if (productType == null) {
      message.error("Please select item type")
      return false;
    }
    if (productType == 0 && company == null) {
      message.error("Please select company")
      return false;
    }
    if (productType == 1 && category == null) {
      message.error("Please select category")
      return false;
    }
    if (productType == 2 && productList.length == 0) {
      message.error("Please add at least 1 item to the list");
      return false;
    }
    return true;
  }

  const handleNewSale = async () => {
    if (!validateData())
      return;
    let newProductList = productList.map((v) => v["details"])
    const hide = message.loading('Creating new sale');
    const data = {
      title,
      discountType,
      discountNumber,
      productType,
      company,
      category,
      startTime,
      endTime,
      maxDiscount,
      productList: newProductList
    }
    try {
      await addNewSale(data);
      hide();
      setSaleOpen(false);
      setSaleLoading(true);
      let newSaleList = await getAllSales();
      setSaleList(newSaleList);
      setSaleLoading(false);
      message.success("New Sale Created Successfully")
    } catch (e) {
      hide();
      message.error('Some Error Occurred');
      console.log(e);
    }
  }

  return (
    <>
      <Button type="primary"
        onClick = {() => setSaleOpen(true)}
        loading = {saleLoading}
      >
        Create New Sale
      </Button>
      <div style = {{ height: 20 }} />
      <Table loading = {saleLoading} dataSource={saleList} columns={mainColumns} scroll={{ y: '70vh' }} />
      <Modal
        visible = {saleOpen}
        title = "Create New Sale"
        onOk={() => handleNewSale()}
        onCancel={() => setSaleOpen(false)}
        afterClose={() => {
					
        }}
				okText = "Create"
        centered
				width = {450}
      >
        <Input placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
        <div style = {{ height: 15 }} />
        <Space>
          <Select
            placeholder = "Discount Type"
            onSelect = {(v) => setDiscountType(v)}
            style = {{ width: 200 }}
          >
            <Option key="D0" value="0">Flat</Option>
            <Option key="D1" value="1">Percentage</Option>
          </Select>
          <Input placeholder={`Discount ${discountType == 1 ? 'Percent' : 'Amount'}`} onChange={(e) => setDiscountNumber(e.target.value)} />
        </Space>
        {
          discountType == 1 &&
          <Input placeholder="Maximum Discount" onChange={(e) => setMaxDiscount(e.target.value)} type = "number" style = {{ marginTop: 15 }} />
        }
        <div style = {{ height: 15 }} />
        <RangePicker
          style= {{ width: '100%' }}
          showTime={{ format: 'hh:mm A' }}
          format="DD MMM YYYY hh:mm A"
          value={startTime == null ? [] : [moment(startTime), moment(endTime)]}
          onChange={(val) => console.log(val)}
          onOk={(dates) => {
            if (dates[0] != null)
              setStartTime(dates[0].toDate());
            if (dates[1] != null)
              setEndTime(dates[1].toDate())
          }}
        />
        <div style = {{ height: 15 }} />
        <Space>
          <Select
            placeholder = "Item List Type"
            onSelect = {(v) => setProductType(v)}
            style = {{ width: 200 }}
          >
            <Option key="I0" value={0}>Company</Option>
            <Option key="I1" value={1}>Category</Option>
            <Option key="I2" value={2}>Custom</Option>
          </Select>
          {
            productType == 0 &&
            <Select
              showSearch
              placeholder="Select Company"
              optionFilterProp="children"
              onChange={(e) => setCompany(e)}
              value={company}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                companies.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
              }
            </Select>
          }
          {
            productType == 1 &&
            <Select
              showSearch
              placeholder="Select Category"
              optionFilterProp="children"
              value={category}
              onChange={(e) => setCategory(e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                categories.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
              }
            </Select>
          }
        </Space>
        {
          productType == 2 &&
          <>
            <div style = {{ height: 15 }} />
            <Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
            <div style = {{ height: 20 }} />
            <Table dataSource={productList} columns={columns} scroll={{ y: '150px' }} />
          </>
        }
      </Modal>
      <Modal
				visible={addProduct}
				onOk={() => {
					if (productID == null) {
						message.error('Please select a product first.')
						return
					}
					const newProducts = [...productList];
					const index = products.findIndex((val) => val["_id"] == productID);
					newProducts.push({
						"details": productID,
						"index": index,
					})
					setProductList(newProducts);
					setAddProduct(false);
					setProductID();
					message.success('Product added successfully');
				}}
				onCancel={() => setAddProduct(false)}
				okText = "Add"
				width = {450}
        title = "Add Product"
			>
				<div style = {{ height: 20 }} />
				<Select
          showSearch
          style = {{
            width: '100%'
          }}
          placeholder="Select Product"
          optionFilterProp="children"
          value={productID}
          onChange={(val) => setProductID(val)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            products.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
          }
        </Select>
			</Modal>
    </>
  )
}