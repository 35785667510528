import React, { useContext, useState } from 'react';

import { 
    Row,
    Col,
    Carousel,
    Input,
    message,
    Modal
} from 'antd';

import { 
    LeftComponent,
    BuyzoneText,
    CarouselCover,
    DescriptionDiv,
    IconBackground,
    TitleDiv,
    ArrowBackground,
    PageTitle,
    InputTitle,
    RightComponent,
    LoginButton,
    ForgotPasswordText
} from './index.styles';
import loginGraphic from '../../assets/svgs/loginGraphic.svg';
import digitalStoreIcon from '../../assets/svgs/digital_store_icon.svg';
import giftCardIcon from '../../assets/svgs/gift_card.svg';
import shoppingCartIcon from '../../assets/svgs/shopping_cart_icon.svg';
import Cookie from "js-cookie";

import {
  RightOutlined,
  LeftOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import { useHistory } from 'react-router-dom';
import { Context as DataContext } from "../../api/dataProvider";
import AuthContext from '../../contexts/authContext';


export function LoginScreen() {

  const { 
    login,
    sendVerificationOtp,
    verifyVerificationOtp,
  } = useContext(DataContext);
  const {
    setRole
  } = useContext(AuthContext);

  let history = useHistory('/');
  var carousel;
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailOTP, setEmailOTP] = useState();

  const [emailOtpModel, setEmailOtpModel] = useState(false);

  const next = () => {
    carousel.next();
  };
  const previous = () => {
    carousel.prev();
  };

  const handleLogin = async () => {
    try {
      const respData = await login(email, password);
      setRole(respData.user.role)
      Cookie.set("token", respData["token"]);
      history.push("/dashboard");
    } catch (e) {
      console.log(e);
      if (e.status == 404)
        message.info(
          "It seems you are not registered to use this panel. Please ask adminstrator to register you before loggin in."
        );
      else if (e.status == 401)
        message.error("You have entered an incorrect email/password.");
      else if (e.status == 1511)
        message.error("Some Error Occurred.");
    }
  }

  const sendEmailOTP = async () => {
    await sendVerificationOtp(email)
    setEmailOtpModel(true)
  }

  const verifyEmail = async () => {
    try {
      await verifyVerificationOtp(email, emailOTP);
      handleLogin()
    } catch (e) {
      console.log(e);
      if (e.status == 404)
        message.info(
          "It seems you are not registered to use this panel. Please ask adminstrator to register you before loggin in."
        );
      else if (e.status == 401)
        message.error("You have entered an incorrect email/password.");
      else if (e.status == 1511)
        message.error("Some Error Occurred.");
      else if (e.status == 500)
        message.error(e.data.message);
    }
  }

  const allData = [
    {
      image: giftCardIcon,
      title: "Buy E-Vouchers",
      description: "Purchase E-Vouchers of 100+ Brands at discounted rates.",
    },
    {
      image: shoppingCartIcon,
      title: "Buy Products",
      description: "Fill your cart with 1000+ Product Catalogue and buy with our smooth checkout process.",
    },
    {
      image: digitalStoreIcon,
      title: "Digitalize Offline Stores",
      description: "Purchase products from your local KIRANA store right from your place at the comfort of your couch.",
    },
  ];

    return (
      <Row align = "middle">
        <Col
          span = {8}
        >
          <LeftComponent>
            <BuyzoneText onClick={() => history.push("/")}>Buyzone</BuyzoneText>
            <div style={{ height: 100 }} />
            <CarouselCover>
              <Carousel ref={(node) => (carousel = node)} autoplay>
                  {allData.map((v) => {
                  return (
                      <div>
                      <IconBackground>
                          <img src={v["image"]} width = {50} />
                      </IconBackground>
                      <TitleDiv>{v["title"]}</TitleDiv>
                      <DescriptionDiv>{v["description"]}</DescriptionDiv>
                      <div style={{ height: 40 }} />
                      </div>
                  );
                  })}
              </Carousel>
            </CarouselCover>
            <Row justify="end">
                <ArrowBackground
                    style={{
                        marginRight: 5,
                    }}
                    onClick={() => {
                        previous();
                    }}
                >
                    <LeftOutlined />
                </ArrowBackground>
                <ArrowBackground
                    style={{
                        marginRight: 60,
                    }}
                    onClick={() => {
                        next();
                    }}
                >
                    <RightOutlined />
                </ArrowBackground>
            </Row>
          </LeftComponent>
        </Col>
        <Col
            span = {16}
        >
          <RightComponent>
            <PageTitle>Login!</PageTitle>
            <div style={{ height: 50 }} />
            <InputTitle>Email Address</InputTitle>
            <div style={{ height: 20 }} />
            <Input
              className="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <div style={{ height: 35 }} />
            <InputTitle>Password</InputTitle>
            <div style={{ height: 20 }} />
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div style={{ height: 50 }} />
            <Row justify="space-between" align="middle">
              <ForgotPasswordText>Forgot Password?</ForgotPasswordText>
              <LoginButton onClick={() => sendEmailOTP()}>Login</LoginButton>
            </Row>
          </RightComponent>
        </Col>
        <Modal
          visible={emailOtpModel}
          title="Enter OTP"
          onCancel={() => setEmailOtpModel(false)}
          onOk={async () => verifyEmail()}
          afterClose={() => {
            setEmailOTP();
          }}
        >
          <Input placeholder="OTP" value={emailOTP} onChange={e => setEmailOTP(e.target.value)} />
        </Modal>
      </Row>
    )
}

export default LoginScreen;