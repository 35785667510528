import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { 
	Upload,
	message, 
	Row,
	Col,
	Input,
	Select,
	Button,
	Checkbox
} from 'antd';
import { Context as DataContext } from '../../../../../../api/dataProvider';

import {
	Name
} from './index.styles';
import Modal from 'antd/lib/modal/Modal';

import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useEffect } from 'react';

const { Option } = Select

export const VisualDetailsTab = forwardRef(({
	companies,
	categories,
	details,
	setDetails
}, ref) => {

	const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
	const [loading, setLoading] = useState(false);
	const [thumbnailImage, setThumbnailImage] = useState();
	const [images, setImages] = useState([]);
	const [title, setTitle] = useState();
	const [subtitle, setSubtitle] = useState();
	const [price, setPrice] = useState();
	const [offerPrice, setOfferPrice] = useState();
	const [description, setDescription] = useState();
	const [company, setCompany] = useState();
	const [position, setPosition] = useState();
	const [category, setCategory] = useState();
	const [tags, setTags] = useState([]);
	const [visible, setVisible] = useState();

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

	const [value, setValue] = useState('');

	const {
		handleProductThumbnailImageUpload
	} = useContext(DataContext);
	

	useEffect(() => {
		if (details["_id"] != null) {
			let mainImages = [];
			for (let image of details["images"]) {
				mainImages.push({
					uid: image,
					name: 'image.png',
					url: 'https://buyzone.s3.ap-south-1.amazonaws.com/images/' + image,
					status: 'done',
				})
			}
			console.log(details);
			setThumbnailImage(details["thumbnailImage"]);
			setImages(mainImages);
			setTitle(details["title"]);
			setDescription(details["description"]);
			setCompany(details["company"]["_id"]);
			if (details["category"] != null)
				setCategory(details["category"]["_id"]);
			setSubtitle(details["subtitle"]);
			setPrice(details["originalPrice"]);
			setOfferPrice(details["discountPrice"])
			setPosition(details["position"]);
			setTags(details["tags"]);
			setVisible(details["visible"]);
		}
	}, [])

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB');
		}
		return isJpgOrPng && isLt2M;
	}

	const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
			setLoading(false);
    }
	}

	const handleImageListPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	const handleImageListChange = (info) => {
		console.log("Here", info);
    // if (info.file.status === 'uploading') {
    //   setLoading(true);
    //   return;
    // }
    // if (info.file.status === 'done') {
		// 	setLoading(false);
    // }
	}

	const handleKeyCommand = (command) => {
		const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
		if (newState) {
				this.onChange(newState);
				return 'handled';
		}
		return 'not-handled';
	}

	useImperativeHandle(ref, () => ({
		handleVisualDetails() {
			return new Promise ((resolve, reject) => {
				const finalImages = [];
				for (let image of images) {
					finalImages.push(image.uid)
				}
				const finalData = {
					thumbnailImage,
					images: finalImages,
					title,
					subtitle,
					originalPrice: price,
					discountPrice: offerPrice,
					description,
					company,
					position,
					category,
					tags,
					visible
				}
				resolve(finalData)
			})
		}
	}))

	const handleRemoveListImage = (file) => {
		const index = images.findIndex((val) => val["uid"] == file["uid"]);
		const tempArr = [...images];
		tempArr.splice(index, 1);
		setImages(tempArr);
	}

	return(
		<>
			<Row>
				<Col>
					<Name>Thumbnail Image</Name>
					<div style = {{ height: 15 }} />
					<Upload
						listType="picture-card"
						showUploadList={false}
						accept="image/*" 
						onPreview={(file) => handleImageListPreview(file)}
						customRequest={async ({file, onSuccess, onError}) => {
							try {
								console.log(file)
								const key = await handleProductThumbnailImageUpload(file);
								setThumbnailImage(key);
								onSuccess('Ok');
							} catch (e) {
								onError('Error')
							}
						}}
						beforeUpload={beforeUpload}
						onChange={(e) => handleChange(e)}
					>
						{thumbnailImage ? <img src={'https://buyzone.s3.ap-south-1.amazonaws.com/images/' + thumbnailImage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
					</Upload>
				</Col>
				<div style = {{ width: 25 }} />
				<Col>
					<Name>Product Image(s)</Name>
					<div style = {{ height: 15 }} />
					<Upload
						listType="picture-card"
						fileList={images}
						customRequest={async (input) => {
							console.log(input);
							const {file, onSuccess, onError} = input;
							try {
								const key = await handleProductThumbnailImageUpload(file);
								const newImages = [...images];
								newImages.push({
									uid: key,
									name: 'image.png',
									url: 'https://buyzone.s3.ap-south-1.amazonaws.com/images/' + key,
									status: 'done',
								});
								setImages(newImages)
								onSuccess('Ok');
							} catch (e) {
								onError('Error')
							}
						}}
						onPreview={(file) => handleImageListPreview(file)}
						onChange={(e) => handleImageListChange(e)}
						onRemove={(file) => handleRemoveListImage(file)}
					>
						{images.length >= 4 ? null : uploadButton}
					</Upload>
				</Col>
			</Row>
			<div style = {{ height: 15 }} />
			<Row>
				<Col span = {11}>
					<Input placeholder="Product Title" value = {title} onChange={(e) => setTitle(e.target.value)} maxLength = {50} allowClear />
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {11}>
					<Input placeholder="Subtitle" value = {subtitle} onChange={(e) => setSubtitle(e.target.value)} maxLength = {50} allowClear />
				</Col>
			</Row>
			<div style = {{ height: 15 }} />
			<Row>
				<Col span = {7}>
					<Input addonBefore="₹" placeholder="Price" value = {price} onChange={(e) => setPrice(e.target.value)} allowClear />
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {7}>
					<Input addonBefore="₹" placeholder="Offer Price" value = {offerPrice} onChange={(e) => setOfferPrice(e.target.value)} allowClear />
				</Col>
				<Col span = {7}>
					<Checkbox onChange={(e) => setVisible(e.target.checked)} checked = {visible}>Visible</Checkbox>
				</Col>
			</Row>
			<div style = {{ height: 15 }} />
			<Col span = {22}>
				<Input placeholder="Description" value = {description} onChange={(e) => setDescription(e.target.value)} allowClear autoSize />
			</Col>
			<div style = {{ height: 15 }} />
			<Row>
				<Col span = {11}>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder="Category"
						optionFilterProp="children"
						value={category}
						onChange={(e) => setCategory(e)}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{
							categories.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
						}
					</Select>
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {11}>
					<Select
						showSearch
						style={{ width: '100%' }}
						placeholder="Company"
						optionFilterProp="children"
						onChange={(e) => setCompany(e)}
						value={company}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{
							companies.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
						}
					</Select>
				</Col>
			</Row>
			<div style = {{ height: 15 }} />
			<Row>
				<Col span = {11}>
					<Input placeholder="Position" value = {position} type = "number" onChange={(e) => setPosition(e.target.value)} allowClear />
				</Col>
				<div style = {{ width: 15 }} />
				<Col span = {11}>
					<Select 
						placeholder="Tags"
						mode="tags" 
						style={{ width: '100%' }} 
						onChange={(e) => {
							setTags(e);
						}} 
						tokenSeparators={[',']}
						value={tags}
					>
						
					</Select>
				</Col>
			</Row>
			<Modal
				visible={previewVisible}
				title="Preview"
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img alt="Preview" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</>
	);
})