import { message, Table, Avatar, Modal, Input, Space, Button, Select, Checkbox } from 'antd';
import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactFileReader from 'react-file-reader';
import { PlusOutlined } from '@ant-design/icons'
import Papaparse from 'papaparse';

import { Context as DataContext } from "../../../../api/dataProvider";
import AuthContext from '../../../../contexts/authContext';
import { 
  Name,
  Other
} from './index.styles';

import moment from 'moment';

import { Editor } from '@tinymce/tinymce-react';

const { Search } = Input;
const { Option } = Select;

function Users() {

  const {
    fetchUsers,
    handleBans,
    sendSMSToUser,
    sendBulkEmail,
    sendBulkSMS,
    sendNotificationToUser,
    handleBulkImageUpload,
    searchUser,
    getBannedUsers,
    downloadUserEmailExcel
  } = useContext(DataContext);

  const {
    role
  } = useContext(AuthContext);

  const [filteredList, setFilteredList] = useState([]);
  const [search, setSearch] = useState(false);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const [searchType, setSearchType] = useState('phoneNumber');
  const [userBanTypes, setUserBanTypes] = useState('All')

  const [userId, setUserId] = useState();
  const [smsMessage, setSMSMessage] = useState();
  const [messageModal, setMessageModal] = useState(false);

  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [notificationModal, setNotificationModal] = useState(false);

  const [bulkEmailMessage, setBulkEmailMessage] = useState();
  const [bulkEmailComplete, setBulkEmailComplete] = useState(false);
  const [bulkEmailSubject, setBulkEmailSubject] = useState();
  const [bulkEmailModal, setBulkEmailModal] = useState(false);
  const [bulkEmailAddresses, setBulkEmailAddresses] = useState([]);

  const [bulkSMSMessage, setBulkSMSMessage] = useState();
  const [bulkSMSComplete, setBulkSMSComplete] = useState(false);
  const [bulkSMSModal, setBulkSMSModal] = useState(false);
  const [bulkSMSTemplateID, setBulkSMSTemplateID] = useState();
  const [bulkSMSNumbers, setBulkSMSNumbers] = useState([]);
  const [bulkSMSDefaulters, setBulkSMSDefaulters] = useState([]);

  const [tablePage, setTablePage] = useState(1)
  const [tableSize, setTableSize] = useState(10)
  const [loading, setLoading] = useState(true)

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef != null && editorRef.current != null)
      return new Promise (async (resolve, reject) => {
        resolve(editorRef.current.getContent());
      })
  };

  useEffect(() => {
    console.log("Role: ", role);
    getUsers();
  }, []);

  const getUsers = async (page = 1, limit = 10) => {
    try {
      setLoading(true)
      setTablePage(page)
      setTableSize(limit)
      const data = await fetchUsers({
        page,
        limit
      });
      setSearch(false)
      setUserList(data["users"]);
      setFilteredList(data["users"]);
      setTotalCount(data.total);
      setLoading(false)
    } catch (e) {
      console.log(e);
      message.error('Some error occurred');
    }
  }

  const columns = [
    {
        title: '',
        dataIndex: 'profileImage',
        key: 'profileImage',
        render: imgUrl => <Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${imgUrl}`} />,
        width: 60
    },
    {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        render: text => <Other>{text}</Other>,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: text => <Other>{text}</Other>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => <Other>{moment(text).format('DD MMM YYYY, hh:mm A')}</Other>,
    },
    {
      title: 'Ban Status',
      dataIndex: 'bans',
      key: 'bans',
      render: bans => <Other>{
        bans.length > 0 ? bans[0]['permanentBan'] ? 'Permanently Banned' : (new Date() < new Date(bans[0]['banTill'])) ? `Banned till ${moment(bans[0]['banTill']).format('DD MMM YYYY, hh:mm A')}` : `Unbanned` : 'Unbanned'
      }</Other>,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record, index) => (
        <span>
          <a
            href="javascript:;"
            onClick={() => handleBanUser(index)}
            style={{
              marginRight: 8,
            }}
          >
            {
              record["bans"].length > 0 ? record["bans"][0]['permanentBan'] ? 'Remove Ban' : (new Date() < new Date(record["bans"][0]['banTill'])) ? `Remove Ban` : `Ban` : 'Ban'
            }
          </a>
          <a
            href="javascript:;"
            onClick={() => sendMessage(index)}
            style={{
              marginRight: 8,
            }}
          >
            SMS
          </a>
          <a
            href="javascript:;"
            onClick={() => sendNotification(index)}
          >
            Notification
          </a>
        </span>
      )
    }
  ];

  const handleBanUser = async (index) => {
    let user = filteredList[index];
    let hide = message.loading("Please wait...");
    try {
      let resp = await handleBans({ userId: user._id })
      hide();
      message.success(resp["message"])
    } catch (e) {
      hide();
      console.log(e);
    }
  }

  const handleUserSearch = async (phoneNumber) => {
    let hide = message.loading("Please wait...");
    try {
      setTablePage(1)
      setTableSize(10)
      setTotalCount(1)
      if (phoneNumber.length == 0) {
        hide()
        setSearch(false)
        getUsers()
        return
      }
      setSearch(true)
      let resp = await searchUser({ data: phoneNumber, searchType })
      hide()
      if (resp != null) {
        setFilteredList(resp)
        setTotalCount(resp.length)
      }
      else {
        setTotalCount(0)
        setFilteredList()
      }
    } catch (e) {
      hide();
      console.log(e);
    }
  }

  const sendMessage = async (index) => {
    let user = filteredList[index];
    setUserId(user._id);
    setMessageModal(true);
  }

  const sendNotification = async (index) => {
    let user = filteredList[index];
    setUserId(user._id);
    setNotificationModal(true);
  }

  const sendBulkSMSToUsers = async () => {
    if (!bulkSMSComplete && bulkSMSNumbers.length == 0) {
      message.error('Please select CSV First');
      return;
    }
    if (bulkSMSMessage == null || bulkSMSMessage.length == 0) {
      message.error('Please enter message');
      return;
    }
    if (bulkSMSTemplateID == null || bulkSMSTemplateID.length == 0) {
      message.error('Please enter DLT Template ID');
      return;
    }
    let hide = message.loading("Sending Bulk Message...");
    try {
      await sendBulkSMS({
        "numbers": bulkSMSNumbers,
        "message": bulkSMSMessage,
        "templateID": bulkSMSTemplateID,
        "complete": bulkSMSComplete
      });
      hide();
      message.success("Message sent successfully!");
      setMessageModal(false);
    } catch (e) {
      hide();
      console.log(e);
    }
  }

  const sendBulkEmailToUsers = async () => {
    if (!bulkEmailComplete && bulkEmailAddresses.length == 0) {
      message.error('Please select CSV First');
      return;
    }
    if (bulkEmailSubject == null || bulkEmailSubject.length == 0) {
      message.error('Please add Subject');
      return;
    }
    let bulkEmailMessage = await log();
    if (bulkEmailMessage == null || bulkEmailMessage.length == 0) {
      message.error('Please add Content');
      return;
    }
    let hide = message.loading("Sending Message...");
    try {
      await sendBulkEmail({
        "addresses": bulkEmailAddresses,
        "subject": bulkEmailSubject,
        "message": bulkEmailMessage,
        "complete": bulkEmailComplete
      })
      hide();
      message.success("Message sent successfully!");
      setBulkEmailModal(false);
    } catch (e) {
      hide();
      console.log(e);
    }
  }

  const handleBulkEmailCSV = async (files) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const parseResult = Papaparse.parse(reader.result);
      if (parseResult.errors.length != 0) {
        console.error(parseResult.errors);
        message.error("There was some error while parsing CSV File.")
        return;
      }
      let parsedData = parseResult.data;
      if (!(parsedData[0][0] == "Email" && parsedData[0][1] == "Name")) {
        message.error("Invalid CSV Format. Please ensure that the first row has only \"Email\" & \"Name\"");
        return;
      }
      parsedData.splice(0, 1);
      console.log(parsedData)
      let finalEmailAdd = [];
      let i = 0;
      for (var data of parsedData) {
        if ((data[0] == "" || data[1] == "") && i != (parsedData.length - 1)) {
          // message.error("Invalid data at Row " + (i + 1));
          continue;
        }
        if ((data[0] == "" || data[1] == "") && i == (parsedData.length - 1)) {
          i++;
          break;
        }
        finalEmailAdd.push(data[0])
        i++;
      }
      // console.log(finalEmailAdd);
      setBulkEmailAddresses(finalEmailAdd);
      message.success("CSV Parsed Successfully");
    }
    reader.readAsText(files[0]);
  }

  const handleBulkSMSNumbers = async (files) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const parseResult = Papaparse.parse(reader.result);
      if (parseResult.errors.length != 0) {
        console.error(parseResult.errors);
        message.error("There was some error while parsing CSV File.")
        return;
      }
      let parsedData = parseResult.data;
      if (!(parsedData[0][0] == "Phone" && parsedData[0][1] == "Name")) {
        message.error("Invalid CSV Format. Please ensure that the first row has only \"Phone\" & \"Name\"");
        return;
      }
      parsedData.splice(0, 1);
      let defaulters = [];
      let finalPhoneNumbers = [];
      let i = 0;
      for (var data of parsedData) {
        if ((data[0] == "" || data[1] == "") && i != (parsedData.length - 1)) {
          message.error("Invalid data at Row " + (i + 1));
          return;
        }
        if ((data[0] == "" || data[1] == "") && i == (parsedData.length - 1)) {
          i++;
          break;
        }
        if (data[0].length == 10)
          finalPhoneNumbers.push("+91" + data[0])
        else
          defaulters.push({
            "phone": data[0],
            "name": data[1]
          })
        i++;
      }
      setBulkSMSNumbers(finalPhoneNumbers);
      message.success("CSV Parsed Successfully");
    }
    reader.readAsText(files[0]);
  }

  const handlePageWithSearch = async (page, limit) => {
    setTablePage(page)
    setTableSize(tableSize)
  }

  const getAllBannedUsers = async () => {
    try {
      setLoading(true)
      setTablePage(1)
      setTableSize(10)
      setTotalCount(0)
      setSearch(true)
      let resp = await getBannedUsers()
      if (resp != null) {
        setFilteredList(resp)
        setLoading(false)
        setTotalCount(resp.length)
      }
      else {
        setTotalCount(0)
        setFilteredList()
      }
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  }

  const handleUserBanTypes = async (value) => {
    setUserBanTypes(value)
    if (value === "All")
      getUsers()
    else
      getAllBannedUsers()
  }

  const handleDownloadUserExcel = async () => {
    try {
      let resp = await downloadUserEmailExcel();
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Users.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  return (
    <>
      <div>
        <Space>
          <Input.Group compact>
            <Select defaultValue="phoneNumber" value={searchType} onChange={setSearchType} style={{ minWidth: 150 }}>
              <Option value="phoneNumber">Phone Number</Option>
              <Option value="email">Email</Option>
              <Option value="name">Name</Option>
            </Select>
            <Search placeholder="" allowClear onSearch={(v) => handleUserSearch(v)} style={{ width: 300 }} enterButton />
          </Input.Group>
          <Select defaultValue="All" value={userBanTypes} onChange={handleUserBanTypes} style={{ minWidth: 150 }}>
            <Option value="All">All Users</Option>
            <Option value="Banned">Banned</Option>
          </Select>
          <Button type='primary' onClick={() => handleDownloadUserExcel()}>Download Users Excel (Bulk)</Button>
          <Name>Total User Count: {totalCount}</Name>
        </Space>
        <div style = {{ height: 15 }} />
        <Table
          columns={columns}
          scroll={{ y: '70vh' }}
          dataSource={filteredList}
          loading = {loading}
          pagination={
            { 
              defaultPageSize: 10, 
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100'], 
              total: totalCount,
              defaultCurrent: 1,
              current: tablePage,
              size: tableSize,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} orders`,
              onChange: (page, limit) => search ? handlePageWithSearch(page, limit) : getUsers(page, limit)
            }
          }
        />
        <Modal
          visible={bulkSMSModal}
          title="Send Bulk SMS"
          onCancel={() => setBulkSMSModal(false)}
          onOk={async () => sendBulkSMSToUsers()}
          afterClose={() => {
            setBulkSMSMessage();
          }}
        >
          <Checkbox onChange={(e) => setBulkSMSComplete(e.target.checked)}>Complete</Checkbox>
          {
            !bulkSMSComplete &&
            <ReactFileReader handleFiles={(files) => handleBulkSMSNumbers(files)} fileTypes={'.csv'}>
              <Button style = {{ width: '100%' }} type="secondary" icon={<PlusOutlined />} >
                {
                  bulkSMSNumbers.length == 0 ? 'Upload CSV' : 'Uploaded'
                }
              </Button>
            </ReactFileReader>
          }
          <div style = {{ height: 15 }} />
          <Input.TextArea style = {{ width: 600 }} placeholder="Add A Message" value={bulkSMSMessage} onChange={e => setBulkSMSMessage(e.target.value)} />
          <div style = {{ height: 15 }} />
          <Input placeholder="Message Template ID" value={bulkSMSTemplateID} onChange={e => setBulkSMSTemplateID(e.target.value)} />
        </Modal>
        <Modal
          visible={bulkEmailModal}
          title="Send Bulk Email"
          onCancel={() => setBulkEmailModal(false)}
          onOk={() => sendBulkEmailToUsers()}
          afterClose={() => {
            setBulkEmailMessage();
          }}
          width={750}
        >
          <Checkbox onChange={(e) => setBulkEmailComplete(e.target.checked)}>Complete</Checkbox>
          {
            !bulkEmailComplete &&
            <ReactFileReader handleFiles={(files) => handleBulkEmailCSV(files)} fileTypes={'.csv'}>
              <Button style = {{ width: '100%' }} type="secondary" icon={<PlusOutlined />} >
                {
                  bulkEmailAddresses.length == 0 ? 'Upload CSV' : 'Uploaded'
                }
              </Button>
            </ReactFileReader>
          }
          <div style = {{ height: 15 }} />
          <Input placeholder="Add A Subject" value={bulkEmailSubject} onChange={e => setBulkEmailSubject(e.target.value)} />
          <div style = {{ height: 15 }} />
          <Editor
            apiKey = "xmvxnian23wvi7o7b6mqiwysfyr2ic9uya6is6cko48sezt4"
            onInit={(evt, editor) => editorRef.current = editor}
            init={{
              images_upload_handler: async function (blobInfo, success, failure) {
                let key = await handleBulkImageUpload(blobInfo.blob());
                success('https://buyzone.s3.ap-south-1.amazonaws.com/images/' + key);
              },
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
              ],
              toolbar: 'bold italic backcolor forecolor fontname fontsize link image | alignleft aligncenter alignright alignjustify | bullist numlist'
              ,
              content_style: 'body { font-family: Montserrat,Arial,sans-serif; font-size:14px }'
            }}
          />
        </Modal>
        <Modal
          visible={messageModal}
          title="Send Message To User"
          onCancel={() => setMessageModal(false)}
          onOk={async () => {
            let hide = message.loading("Sending Message...");
            try {
              await sendSMSToUser({
                "userId": userId,
                "message": smsMessage
              });
              hide();
              message.success("Message sent successfully!");
              setMessageModal(false);
            } catch (e) {
              hide();
              console.log(e);
            }
          }}
          afterClose={() => {
            setUserId();
            setSMSMessage();
          }}
        >
          <Input.TextArea style = {{ width: 600 }} placeholder="Add A Message" value={smsMessage} onChange={e => setSMSMessage(e.target.value)} />
        </Modal>
        <Modal
          visible={notificationModal}
          title="Send Notification To User"
          onCancel={() => setNotificationModal(false)}
          onOk={async () => {
            let hide = message.loading("Sending Notification...");
            try {
              let data = await sendNotificationToUser({
                "userId": userId,
                "title": title,
                "body": body
              });
              console.log(data);
              hide();
              message.success("Notification sent successfully!");
              setNotificationModal(false);
            } catch (e) {
              hide();
              let { response } = e;
              if (response != null) {
                let { code } = response.data;
                message.error(response.data.message)
                console.log(response.data);
              } else {
                console.error(e);
              }
            }
          }}
          afterClose={() => {
            setUserId();
            setTitle();
            setBody();
          }}
        >
          <Input style = {{ width: '100%' }} placeholder="Notification Title" value={title} onChange={e => setTitle(e.target.value)} />
          <div style = {{ height: 15 }} />
          <Input.TextArea style = {{ width: 600 }} placeholder="Notification Body" value={body} onChange={e => setBody(e.target.value)} />
        </Modal>
      </div>
    </>
  )
}

export default Users;