import React, { useEffect, useState, useContext } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Cookie from "js-cookie";
import { LoginScreen } from "./Login";
import Error from "./Error/404";
import DashboardScreen from "./Dashboard";
import { getAllowedRoutes } from "../config";
import PrivateRoutesConfig from "../config/PrivateRoutesConfig";
import AuthContext from "../contexts/authContext";

const publicRoutes = [
  "/",
  "/login",
];

const Screens = ({ history }) => {

  let [allowedRoutes, setAllowedRoutes] = useState([])
  const { role } = useContext(AuthContext);

  useEffect(() => {
    let routes = getAllowedRoutes(PrivateRoutesConfig, role);
    let finalRoutes = ['/dashboard', ...routes.map((v) => v["path"])]
    setAllowedRoutes(finalRoutes);
    console.log(history.location.pathname);
  }, [])

  return (
    <div>
      {publicRoutes.includes(history.location.pathname) && (
        <Switch>
          <Route exact path="/" render={() => <Redirect to='/dashboard' />} />
          <Route exact path="/login" component={LoginScreen} />
        </Switch>
      )}

      {
      publicRoutes.includes(history.location.pathname) ||
        allowedRoutes.includes(history.location.pathname) &&
        Cookie.get("token") ? (
          <Switch>
            <Route path="/dashboard" component={DashboardScreen} />
          </Switch>
        ) : (
          allowedRoutes.includes(history.location.pathname) && (
            <Redirect to="/login" />
          )
        )}

      {!publicRoutes.includes(history.location.pathname) &&
        !allowedRoutes.includes(history.location.pathname) && <Error />}
    </div>
  );
};

export default withRouter(Screens);
