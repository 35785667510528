import React, { useContext, useEffect, useState } from 'react';
import { Context as DataContext } from "../../../../../../api/dataProvider";
import {
  Button, 
  Table,
  Select,
  Input,
  Modal,
  Row,
  Col,
  Space,
  message,
  DatePicker
} from 'antd';
import {
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  Divider
} from './index.styles';
const { Option } = Select;

export const AdjustmentModal = ({
  visible,
  hideModal,
  inventoryProductsList
}) => {

  const {
    addInventoryAdjustment
  } = useContext(DataContext);
  const [productID, setProductID] = useState()
  const [title, setTitle] = useState();
  const [quantity, setQuantity] = useState();
  const [batchID, setBatchID] = useState();
  const [productData, setProductData] = useState();
  const [reason, setReason] = useState();
  const [loader, setLoader] = useState();

  const validate = () => {
    if (!productID) {
      message.error("Please select product")
      return false;
    }
    if (!quantity || quantity == "") {
      message.error("Please enter quantity.")
      return false;
    }
    if (!batchID) {
      message.error("Please select batch.")
      return false;
    }
    if (!reason || reason == "") {
      message.error("Please enter reason.")
      return false;
    }
    return true;
  }

  const addInventoryAdjustments = async () => {
    if (!validate())
      return
    try {
      setLoader(true);
      let resp = await addInventoryAdjustment({
        productID,
        quantity,
        reason,
        batchID
      });
      setLoader(false);
      message.success(resp.message);
    } catch (e) {
      let { response } = e;
      setLoader(false);
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        console.error(e);
        message.error("Some error occurred");
      }
    }
  }

  return (
    <CustomModalStyle
      centered
      visible={visible}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      afterClose = {() => {
        
      }}
      width = {650}
    >
      <ModalTitle>Add Inventory Adjustment</ModalTitle>
      <div style = {{ height: 20 }} />
      <Divider />
      <div style = {{ height: 20 }} />
      <Select
        showSearch
        style = {{
          width: '100%'
        }}
        placeholder="Select Product"
        optionFilterProp="children"
        onChange={(val) => {
          let product = inventoryProductsList.find((v) => v["_id"] == val);
          setProductData(product);
          setProductID(product["_id"]);
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          inventoryProductsList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
        }
      </Select>
      <div style = {{ height: 20 }} />
      {
        productData != null && Object.keys(productData).length > 0 &&
        <>
          <Space>
            <Input placeholder="Quantity" type="number" value = {quantity} onChange={(e) => setQuantity(e.target.value)} />
            <Select
              style = {{
                width: '100%'
              }}
              placeholder="Select Batch"
              optionFilterProp="children"
              onChange={(val) => {
                setBatchID(val);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                productData["batches"].map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
              }
            </Select>
          </Space>
          <div style = {{ height: 15 }} />
          <Input placeholder="Reason" value = {reason} onChange={(e) => setReason(e.target.value)} />
        </>
      }
      <div style = {{ height: 20 }} />
      <Divider />
      <div style = {{ height: 20 }} />
      <Button onClick = {() => addInventoryAdjustments()} loading = {loader}>Add</Button>
    </CustomModalStyle>
  )
}