import React from 'react';

import { 
  Row,
  Col,
  Avatar,
  Table
} from 'antd';
import { 
  Other,
  StatText,
  StatTitle, 
  Title
} from './index.styles';
import { STATIC_IMG_URL } from '../../../../../../Constants';
import moment from 'moment';

export const DetailsTab = ({
  data
}) => {

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: type => <Title>{type}</Title>,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: reason => <Other>{reason}</Other>,
    },
		{
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => <Other>{amount}</Other>,
    }
  ];


  const {
    typeName,
    buyzoneVoucher
  } = data;

  const {
    transactions,
    voucherNumber,
    usedAmount,
    totalAmount,
    expiry,
    user
  } = buyzoneVoucher

  return (
    <>
      <Row>
        <Col span={8} style = {{ paddingRight: 10 }}>
          <div
            style = {{
              width: '100%',
              borderRadius: 10,
              backgroundColor: '#fafafa',
              padding: 20
            }}
          >
            <Row justify="space-between">
              <StatTitle>Buyzone Voucher Details</StatTitle>
              <StatText>Let's know the buyzone voucher</StatText>
            </Row>
            <div style = {{ height: 25 }} />
            <Title>{voucherNumber}</Title>
            <Other>Voucher Number</Other>
            <div style = {{ height: 15 }} />
            <Title>{typeName}</Title>
            <Other>Type</Other>
            <div style = {{ height: 15 }} />
            <Title>{totalAmount}</Title>
            <Other>Total Amount</Other>
            <div style = {{ height: 15 }} />
            <Title>{usedAmount}</Title>
            <Other>Used Amount</Other>
            <div style = {{ height: 15 }} />
            <Title>{moment(expiry).format("DD MMM yyyy, hh:mm A")}</Title>
            <Other>Expiry</Other>
          </div>
        </Col>
        <Col span={16} style = {{ paddingLeft: 10 }}>
          <div
            style = {{
              width: '100%',
              borderRadius: 10,
              backgroundColor: '#fafafa',
              padding: 20
            }}
          >
            <StatTitle>Transaction Details</StatTitle>
            <StatText>Let's check the transactions</StatText>
            <Table style={{ marginTop: 30 }} dataSource={transactions} columns={columns} scroll={{ y: 200 }} />
          </div>
        </Col>
      </Row>
      {
        user != null &&
        <div
          style = {{
            width: '100%',
            marginTop: 15,
            borderRadius: 10,
            backgroundColor: '#fafafa',
            padding: 20
          }}
        >
          <Row justify="space-between">
            <StatTitle>Redeemer Details</StatTitle>
          </Row>
          <div style = {{ height: 25 }} />
          <Title>{user.firstName} {user.lastName}</Title>
          <Other>Name</Other>
          <div style = {{ height: 15 }} />
          <Title>{user.phone}</Title>
          <Other>Phone</Other>
          <div style = {{ height: 15 }} />
          <Title>{user.email}</Title>
          <Other>Email</Other>
        </div>
      }
    </>
  )
}