import React, { useContext, useEffect, useState } from 'react';

import { Context as DataContext } from "../../../../../api/dataProvider";
import { orderStatusCodes } from '../../../../../statusCodes';

import {
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  MenuButton
} from './index.styles';

import {
  Table,
  Row,
  Col,
  Space,
  Button,
  Tooltip,
  DatePicker,
  Modal,
  message,
  Select,
  Input
} from 'antd'

import Papa from 'papaparse';

import { CalendarOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons'

import moment from 'moment'
import { DetailsTab } from './components/details';
import { BillingDetailsTab } from './components/billingDetails';
import renderHTML from 'react-render-html';

const { RangePicker } = DatePicker
const { Option } = Select
const { Search } = Input;

export const VoucherOrders = () => {

  const [ordersList, setOrdersList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [durationModal, setDurationModal] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [orderIndex, setOrderIndex] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterStatus, setFilterStatus] = useState(0);
  const [dates, setDates] = useState([]);
  const [dateFilter, setDateFilter] = useState(false);
  const [fixLoading, setFixLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [downloadOrdersLoading, setDownloadOrdersLoading] = useState(false)

  const [invoice, setInvoiceOpen] = useState(false);
  var [htmlInvoice, setHTMLInvoice] = useState("");

  const [totalOrders, setTotalOrders] = useState(0)
  const [tablePage, setTablePage] = useState(1)
  const [tableSize, setTableSize] = useState(10)

  const [searchType, setSearchType] = useState('orderId');
  const [searchString, setSearchString] = useState();

  const statusToCode = {
    "All": 0,
    "Successful": 100,
    "Processed": 101,
    "Unpaid": 102,
    "Waiting": 103,
    "Cancelled": 104
  }

  const {
    fetchVoucherOrders,
    getDateVoucherOrders,
    fixVoucherOrder,
    markOrderAsCancelled,
    markOrderAsWaiting,
    getInvoice,
    downloadVoucherOrders,
    createVoucherInvoice
  } = useContext(DataContext);

  useEffect(() => {
    getVoucherOrders();
  }, []);

  const getVoucherOrders = async () => {
    try {
      setLoading(true)
      const orders = await fetchVoucherOrders();
      console.log("Completed")
      setTotalOrders(orders["total"])
      setOrdersList(orders["orderList"]);
      setFilteredList(orders["orderList"]);
      setLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  const getPaginatedOrders = async (page, limit, status = filterStatus, number = "") => {
    try {
      if (searchString != null && searchString.length > 0) {
        handleSearchOrder(searchString, page, limit);
        return;
      }
      setLoading(true)
      setTablePage(page)
      setTableSize(limit)
      if (status != null)
        setFilterStatus(status)
      const orders = await fetchVoucherOrders(
        {
          page,
          limit,
          status,
          number
        }
      );
      setTotalOrders(orders["total"])
      setOrdersList(orders["orderList"]);
      setFilteredList(orders["orderList"]);
      setLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  const handleRowClick = async (data, index) => {
    setOrderData(data);
    setOrderIndex(index);
    setOrderDetailsModal(true);
  }

  const columns = [
    {
        title: 'Order ID',
        dataIndex: 'orderID',
        key: 'orderID',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'phone',
        render: customerDetails => <Other>{customerDetails.firstName} {customerDetails.lastName}</Other>,
    },
    {
        title: 'Amount',
        dataIndex: 'paymentDetails',
        key: 'totalAmount',
        render: paymentDetails => <Other>{paymentDetails.totalAmount + (paymentDetails.buyzoneVoucherDiscount == null ? 0 : paymentDetails.buyzoneVoucherDiscount)}</Other>,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <Other>{orderStatusCodes[status]}</Other>,
    },
    {
      title: 'Voucher Used (If Any)',
      dataIndex: 'paymentDetails',
      key: 'voucher',
      render: paymentDetails => <Other>{paymentDetails['voucherID'] == null || paymentDetails['voucherID'] == '' ? '' : paymentDetails['voucherID']["code"]}</Other>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => <Other>{moment(date).format("DD MMM yyyy, hh:mm A")}</Other>,
      width: 250
    },
  ];

  const getDateData = async () => {
    if (dates == null || dates.length == 0) {
      message.error('Please select necessary dates first');
      return;
    }
    const startDate = moment(dates[0]).format('DD MMM yyyy')
    const endDate = moment(dates[1]).add(1, 'day').format('DD MMM yyyy')
    try {
      setLoading(true);
      let resp = await getDateVoucherOrders(startDate, endDate);
      setOrdersList(resp);
      setLoading(false);
      setDateFilter(true);
    } catch (e) {
      console.log(e)
    }
  }

  const downloadOrders = async () => {
    try {
      console.log("Download Voucher Order");
      let resp = await downloadVoucherOrders();
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "Voucher-Orders-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const downloadInvoices = async () => {

  }

  const fixOrder = async () => {
    setFixLoading(true);
    try {
      const data = await fixVoucherOrder(orderData["orderID"]);
      setFixLoading(false);
      message.success(data["message"]);
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      let newOrderList = [...ordersList]
      newOrderData["status"] = statusToCode.Successful;
      newOrderList[orderIndex] = newOrderData;
      setOrderData(newOrderData);
      setOrdersList(newOrderList)
    } catch (e) {
      setFixLoading(false);
      console.log()
      message.error(e.response.data["message"]);
    }
  }

  const markAsWaiting = async () => {
    setFixLoading(true);
    try {
      const data = await markOrderAsWaiting(orderData["orderID"]);
      setFixLoading(false);
      message.success(data["message"]);
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      let newOrderList = [...ordersList]
      newOrderData["status"] = statusToCode.Waiting;
      newOrderList[orderIndex] = newOrderData;
      setOrderData(newOrderData);
      setOrdersList(newOrderList)
    } catch (e) {
      setFixLoading(false);
      console.log()
      message.error(e.response.data["message"]);
    }
  }

  const markAsCancelled = async () => {
    setFixLoading(true);
    try {
      const data = await markOrderAsCancelled(orderData["orderID"]);
      setFixLoading(false);
      message.success(data["message"]);
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      let newOrderList = [...ordersList]
      newOrderData["status"] = statusToCode.Cancelled;
      newOrderList[orderIndex] = newOrderData;
      setOrderData(newOrderData);
      setOrdersList(newOrderList)
    } catch (e) {
      setFixLoading(false);
      console.log()
      message.error(e.response.data["message"]);
    }
  }

  const downloadInvoice = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getInvoice(orderData["paymentDetails"]["zohoInvoiceID"]);
      hide();
      setHTMLInvoice(resp);
      setInvoiceOpen(true);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const createInvoice = async () => {
    const hide = message.loading('Creating..', 0);
    try {
      const data = await createVoucherInvoice({
        "_id": orderData["_id"]
      });
      hide();
      message.success(data["message"]);
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      let newOrderList = [...ordersList]
      newOrderData["paymentDetails"]["zohoInvoiceID"] = data["invoiceID"];
      newOrderList[orderIndex] = newOrderData;
      setOrderData(newOrderData);
      setOrdersList(newOrderList)
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const handleSearchOrder = async (data, page, limit) => {
    setSearchString(data);
    setLoading(true)
    setTablePage(page)
    setTableSize(limit)
    const orders = await fetchVoucherOrders(
      {
        page: page,
        limit: limit
      },
      {
        searchType,
        data
      }
    );
    // setTotalOrders(orders["total"])
    setOrdersList(orders["orderList"]);
    setFilteredList(orders["orderList"]);
    setLoading(false)
  }

  return (
    <>
      <Space>
        <Tooltip title = "Check orders in between a particular duration">
          <Button shape="circle" icon={<CalendarOutlined />} onClick = {() => setDurationModal(true)} />
        </Tooltip>
        {/* <Tooltip title = "Filter orders based on their status"> */}
        <Input.Group conpact>
          <Select defaultValue="phoneNumber" value={searchType} onChange={setSearchType} style={{ minWidth: 150 }}>
            <Option value="orderId">Order ID</Option>
            <Option value="transactionId">Transaction ID</Option>
            <Option value="phoneNumber">Phone Number</Option>
            <Option value="name">Name</Option>
          </Select>
          <Search placeholder="Search..." allowClear onSearch={(v) => {
            if (v == "")
              getPaginatedOrders(1, tableSize, null, null)
            else
              handleSearchOrder(v, 1, tableSize)
          }} style={{ width: 300 }} enterButton />
        </Input.Group>
        <Select defaultValue={0} style={{ width: 120 }} onChange={async (v) => {getPaginatedOrders(1, tableSize, v)}}>
          <Option value={0}>All</Option>
          <Option value={100}>Successful</Option>
          <Option value={101}>Processed</Option>
          <Option value={102}>Unpaid</Option>
          <Option value={103}>Waiting</Option>
          <Option value={104}>Cancelled</Option>
        </Select>
        {
          dateFilter &&
          <Button type="danger" shape="round" icon={<CloseOutlined />} onClick = {() => { getVoucherOrders(); setDateFilter(false) }} >
            Clear Date Filter
          </Button>
        }
        <Button type="primary" icon={<DownloadOutlined />} onClick = {() => downloadOrders()} loading = {downloadOrdersLoading} >
          Download Orders
        </Button>
        <Button type="secondary" icon={<DownloadOutlined />} onClick = {() => downloadInvoices()} >
          Download Invoices
        </Button>
      </Space>
      <div style = {{ height: 15 }} />
      <Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '75vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource= {filteredList} //{filterStatus == 0 ? filteredList : ordersList.filter((v) => v["status"] == filterStatus)}
        pagination={
          { 
            defaultPageSize: 10, 
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100'], 
            total: totalOrders,
            defaultCurrent: 1,
            current: tablePage,
            size: tableSize,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} orders`,
            onChange: (page, limit) => getPaginatedOrders(page, limit)
          }
        }
      />
      <Modal
        centered
        visible={durationModal}
        onOk={() => {getDateData(); setDurationModal(false)}}
        onCancel={() => setDurationModal(false)}
        // afterClose = {() => {setSelectedTab(0); setOrderData(null);}}
        title = "Select Date Range"
      >
        <RangePicker format = "DD MMM YYYY" onChange={(values) => setDates(values)} disabledDate = {d => !d || d.isAfter(new Date())} />
      </Modal>
      {
        orderData &&
        <CustomModalStyle
          centered
          visible={orderDetailsModal}
          onOk={() => setOrderDetailsModal(false)}
          onCancel={() => setOrderDetailsModal(false)}
          afterClose = {() => {setSelectedTab(0); setOrderData(null);}}
          width = {1300}
        >
          <Row align="middle" justify="space-between">
            <div style = {{ marginLeft: 25 }}>
              <ModalTitle>{orderData.orderID}</ModalTitle>
            </div>
            <Row>
              <Col>
                <ModalTitle>Customer Name</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.firstName} {orderData.customer.lastName}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Phone Number</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.phone}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Paid Amount</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>₹{orderData.paymentDetails.totalAmount}</ModalTitleDesc>
              </Col>
              {
                orderData.paymentDetails.buyzoneVoucherID && orderData.paymentDetails.buyzoneVoucherID.length > 0 &&
                <>
                  <div style = {{ width: 50 }} />
                  <Col>
                    <ModalTitle>Buyzone Voucher(s) Used</ModalTitle>
                    <div style = {{ height: 5 }} />
                    {
                      orderData.paymentDetails["buyzoneVoucherID"].map((v) => <ModalTitleDesc>{v["voucherID"]["voucherNumber"]} (₹{v["discountAmount"]})</ModalTitleDesc>)
                    }
                  </Col>
                </>
              }
              {
                orderData.paymentDetails.voucherID &&
                <>
                  <div style = {{ width: 50 }} />
                  <Col>
                    <ModalTitle>Voucher Used</ModalTitle>
                    <div style = {{ height: 5 }} />
                    <ModalTitleDesc>{orderData.paymentDetails["voucherID"]["code"]} (₹{orderData.paymentDetails["voucherDiscount"]})</ModalTitleDesc>
                  </Col>
                </>
              }
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Order Status</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderStatusCodes[orderData.status]}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Time</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{moment(orderData.createdAt).format("DD MMM yyyy, hh:mm A")}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
            </Row>
          </Row>
          <div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 45 }} />
          <Row justify="space-between" style = {{
            minHeight: 400
          }}>
            <Col span = {5} style = {{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
              <MenuButton selected={selectedTab === 0} onClick = {() => setSelectedTab(0)}>Details</MenuButton>
              <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 1} onClick = {() => setSelectedTab(1)}>Payment Details</MenuButton>
            </Col>
            <Col span = {18}>
              {
                selectedTab == 0 &&
                <DetailsTab data = {orderData} />
              }
              {
                selectedTab == 1  &&
                <BillingDetailsTab data = {orderData} />
              }
            </Col>
          </Row>
          <div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 25 }} />
          <Row justify="end" style={{ gap: 16 }}>
            {
              orderData["paymentDetails"]["zohoInvoiceID"] == '' &&
              <Button
                onClick = {() => createInvoice()}
                loading = {fixLoading}
              >
                Create Invoice
              </Button>
            }
            {
              orderData["paymentDetails"]["zohoInvoiceID"] != '' &&
              <Button
                onClick = {() => downloadInvoice()}
                loading = {fixLoading}
              >
                Download Invoice
              </Button>
            }
            {
              orderData["status"] == statusToCode.Waiting &&
              <Button
                onClick = {() => fixOrder()}
                loading = {fixLoading}
              >
                Fix Order
              </Button>
            }
            {
              orderData["status"] != statusToCode.Successful && orderData["status"] != statusToCode.Waiting &&
              <Button
                onClick = {() => markAsWaiting()}
                loading = {fixLoading}
              >
                Mark as waiting
              </Button>
            }
            {
              orderData["status"] != statusToCode.Successful && orderData["status"] != statusToCode.Cancelled &&
              <Button
                onClick = {() => markAsCancelled()}
                loading = {fixLoading}
                danger
              >
                Mark as cancelled
              </Button>
            }
          </Row>
          <CustomModalStyle
            centered
            visible={invoice}
            onOk={() => setInvoiceOpen(false)}
            onCancel={() => setInvoiceOpen(false)}
            width = {650}
          >
            {
              renderHTML(htmlInvoice)
            }
          </CustomModalStyle>
        </CustomModalStyle>
      }
    </>
  )

}