import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Table,
  Card,
  Row
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import {
  CustomModalStyle,
  Name,
  Other,
  ModalTitle
} from './index.styles'
import { CreateVoucherModal } from './components/createVoucher';
import { Context as DataContext } from "../../../../../api/dataProvider";

export const BuyzoneVouchers = () => {

  const [createVoucherModal, setCreateVoucherModal] = useState(false);
  const [loading, setLoading] = useState();
  const [vouchers, setVouchers] = useState([]);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);

  const [voucherData, setVoucherData] = useState();

  const [validVouchers, setValidVouchers] = useState(0);
  const [totalBuyzoneVoucherValueForApp, setTotalBuyzoneVoucherValueForApp] = useState(0);
  const [totalBuyzoneVoucherUsedValueForApp, setTotalBuyzoneVoucherUsedValueForApp] = useState(0);
  const [expiredVouchersForApp, setExpiredVouchersForApp] = useState(0);
  const [expiredVouchersValueForApp, setExpiredVouchersValueForApp] = useState(0);

  const [totalBuyzoneVoucherValueForPanel, setTotalBuyzoneVoucherValueForPanel] = useState(0);
  const [totalBuyzoneVoucherUsedValueForPanel, setTotalBuyzoneVoucherUsedValueForPanel] = useState(0);
  const [expiredVouchersForPanel, setExpiredVouchersForPanel] = useState(0);
  const [expiredVouchersValueForPanel, setExpiredVouchersValueForPanel] = useState(0);

  const [buyzoneVouchersWithUsersValue, setBuyzoneVouchersWithUsersValue] = useState(0);
  const [buyzoneVoucherWithUsers, setBuyzoneVoucherWithUsers] = useState(0);

  const [adminVouchers, setAdminVouchers] = useState(0);
  const [appVouchers, setAppVouchers] = useState(0);

  const {
    getProducts,
    getCompanies,
    getCategories,
    getBrands,
    getBuyzoneVouchers,
    getBuyzoneVouchersStatistics
  } = useContext(DataContext);

  useEffect(() => {
    getInitialData();
  }, []);

  const numberToProduct = {
    0: 'All',
    1: 'E-Vouchers',
    2: 'Products',
    3: 'Digital Stores'
  }

  const columns = [
    {
        title: 'Number',
        dataIndex: 'voucherNumber',
        key: 'voucherNumber',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Type',
        dataIndex: 'applyConstraints',
        key: 'voucherType',
        render: text => <Other>{numberToProduct[text.voucherType]}</Other>,
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: text => <Other>{text}</Other>,
    },
    {
      title: 'Used Amount',
      dataIndex: 'usedAmount',
      key: 'usedAmount',
      render: text => <Other>{text}</Other>,
    },
    {
      title: 'User (if any)',
      dataIndex: 'user',
      key: 'user',
      render: text => text != null ? <Other>{text.firstName} | {text.phone}</Other> : <></>,
    },
  ];

  const getInitialData = async () => {
    try {
      let companiesList = await getCompanies();
      let categoriesList = await getCategories();
      let productsList = await getProducts();
      let brandsList = await getBrands();
      let vouchersList = await getBuyzoneVouchers();
      let statistics = await getBuyzoneVouchersStatistics()
      setCompanies(companiesList);
      setCategories(categoriesList);
      setProducts(productsList);
      setBrands(brandsList["brands"]);
      setVouchers(vouchersList);
      setValidVouchers(statistics["totalValidVouchers"]);

      setTotalBuyzoneVoucherValueForApp(statistics["totalVouchersValueForApp"]);
      setTotalBuyzoneVoucherUsedValueForApp(statistics["usedVoucherValueForApp"]);
      setExpiredVouchersForApp(statistics["totalExpiredVouchersForApp"]);
      setExpiredVouchersValueForApp(statistics["expiredBuyzoneVoucherValueForApp"]);

      setTotalBuyzoneVoucherValueForPanel(statistics["totalVouchersValueForPanel"]);
      setTotalBuyzoneVoucherUsedValueForPanel(statistics["usedVoucherValueForPanel"]);
      setExpiredVouchersForPanel(statistics["totalExpiredVouchersForPanel"]);
      setExpiredVouchersValueForPanel(statistics["expiredBuyzoneVoucherValueForPanel"]);

      setBuyzoneVouchersWithUsersValue(statistics["buyzoneVouchersWithUsersValue"])
      setBuyzoneVoucherWithUsers(statistics["buyzoneVoucherWithUsers"])

      setAdminVouchers(statistics["adminPanelVouchers"]);
      setAppVouchers(statistics["appVouchers"]);
    } catch (e) {
      console.log(e);
    }
  }

  const handleRowClick = async (record, index) => {
    setVoucherData(record);
    setCreateVoucherModal(true);
  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} loading = {loading} onClick = {() => setCreateVoucherModal(true)}>
        Create Buyzone Voucher
      </Button>
      <div style = {{ height: 15 }} />
      <ModalTitle>Buyzone Vouchers purchased on App (Total: {appVouchers})</ModalTitle>
      <div style = {{ height: 15 }} />
      <Row style = {{ gap: 15 }}>
        <Card title="Total Voucher Value">
          <p>{totalBuyzoneVoucherValueForApp}</p>
        </Card>
        <Card title="Total Value Redeemed">
          <p>{totalBuyzoneVoucherUsedValueForApp}</p>
        </Card>
        <Card title="Total Value Needed">
          <p>{totalBuyzoneVoucherValueForApp - totalBuyzoneVoucherUsedValueForApp - expiredVouchersValueForApp}</p>
        </Card>
        <Card title="Total Expired Count">
          <p>{expiredVouchersValueForApp}</p>
        </Card>
        <Card title="Total Expired Value">
          <p>{expiredVouchersValueForApp}</p>
        </Card>
      </Row>
      <div style = {{ height: 15 }} />
      <ModalTitle>Buyzone Vouchers purchased by Admin Panel (Total: {adminVouchers})</ModalTitle>
      <div style = {{ height: 15 }} />
      <Row style = {{ gap: 15 }}>
        <Card title="Total Voucher Value">
          <p>{totalBuyzoneVoucherValueForPanel}</p>
        </Card>
        <Card title="Total Value Redeemed">
          <p>{totalBuyzoneVoucherUsedValueForPanel}</p>
        </Card>
        <Card title="Total Value Needed">
          <p>{totalBuyzoneVoucherValueForPanel - totalBuyzoneVoucherUsedValueForPanel - expiredVouchersValueForPanel}</p>
        </Card>
        <Card title="Total Expired Count">
          <p>{expiredVouchersForPanel}</p>
        </Card>
        <Card title="Total Expired Value">
          <p>{expiredVouchersValueForPanel}</p>
        </Card>
      </Row>
      <div style = {{ height: 15 }} />
      <Row style = {{ gap: 15 }}>
        <Card title="Total Buyzone Vouchers Added by Users" style={{ width: 460 }}>
          <p>{buyzoneVoucherWithUsers}</p>
        </Card>
        <Card title="Total Buyzone Voucher Value Remaining" style={{ width: 460 }}>
          <p>{buyzoneVouchersWithUsersValue}</p>
        </Card>
      </Row>
      <div style = {{ height: 15 }} />
      <Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={vouchers}
      />
      <CustomModalStyle
        centered
        visible={createVoucherModal}
        onOk={() => setCreateVoucherModal(false)}
        onCancel={() => setCreateVoucherModal(false)}
        afterClose = {() => setVoucherData()}
        width = {1100}
        style = {{
          height: 700
        }}
      >
        <CreateVoucherModal data = {voucherData} brands = {brands} products = {products} categories = {categories} companies = {companies} setModalVisibility = {setCreateVoucherModal} />
      </CustomModalStyle>
    </>
  )
}