import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Form,
  Input,
  InputNumber,
  Table,
  Popconfirm
} from 'antd';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const InventoryTab = forwardRef(({ customItemsList, setCustomItemsList }, ref) => {

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.title === editingKey;

  const mainColumns = [
    {
      title: 'Name',
      dataIndex: 'title',
      width: '30%',
    },
    {
      title: 'Price',
      dataIndex: 'originalPrice',
      editable: true
    },
    {
      title: 'D Price',
      dataIndex: 'discountPrice',
      editable: true
    },
    // {
    //   title: 'HSN',
    //   dataIndex: 'hsnCode',
    //   editable: true
    // },
    {
      title: 'SKU',
      dataIndex: 'sku',
      editable: true
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.title)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        );
      },
    }
  ];

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record) => {
    console.log(record);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.title);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields(["originalPrice", "discountPrice"]);
      const newData = [...customItemsList];
      const index = newData.findIndex((item) => key === item.title);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setCustomItemsList(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setCustomItemsList(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }

  useImperativeHandle(ref, () => ({
		handleInventoryDetails() {
      return {"customizations": customItemsList};
		}
	}))

  const columns = mainColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  

  return (
    <Form form={form} component={false}>
      <Table
        rowClassName="editable-row"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns = {columns}
        dataSource = {customItemsList}
        bordered
      >

      </Table>
    </Form>
  );

})