import React, { useContext, useState } from 'react';

import {
  Col,
  message,
  Row,
  Table,
  Tag,
  Popconfirm,
  Avatar,
  Popover,
  Space
} from 'antd';

import {
  Title,
  Other
} from './index.styles';

import { Context as DataContext } from "../../../../../../api/dataProvider";

import renderHTML from 'react-render-html';

import moment from 'moment';
import Iframe from 'react-iframe';
import { useEffect } from 'react';

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

function ProductsTab({ shipmentDetails, products, packed, shipped, orderID, setShipments }) {

  const {
    createAPackage,
    shipAPackage,
    returnAPackage,
    getInvoice
  } = useContext(DataContext);

  const [invoice, setInvoiceOpen] = useState(false);
  const [pickrrSlip, setPickrrSlipOpen] = useState(false);
  const [journey, setJourney] = useState(false);
  var [htmlInvoice, setHTMLInvoice] = useState("");
  var [pickrrManifest, setPickrrManifest] = useState("");

  const columns = [
    {
      title: '',
      dataIndex: 'details',
      key: 'productImage',
      render: details => <Popover content={PopoverProductDetails(details)}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${details.thumbnailImage}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
      width: 45
    },
    {
      title: 'Title',
      dataIndex: 'details',
      key: 'productTitle',
      render: details => <Title>{details.title}</Title>,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: quantity => <Title>{quantity}</Title>,
    },
    {
      title: 'Customizations',
      dataIndex: 'customization',
      key: 'customization',
      render: (customizations, record) => <div style = {{
        display: 'flex',
        flexDirection: 'row'
      }}>
        {
          customizations != null && customizations["color"] != null &&
          <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + customizations["color"]["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />
        }
        {
          customizations != null && customizations["size"] != null && record["details"]["sizes"].length > 0 &&
          <Tag color="purple">{record["details"]["sizes"].find((val) => {return val["_id"].toString() == customizations["size"].toString()}) == null ? "" : record["details"]["sizes"].find((val) => {return val["_id"].toString() == customizations["size"].toString()})["value"].toString()}</Tag>
        }
      </div>,
    },
  ];

  // const createPackage = async (index) => {
  //   const hide = message.loading('Creating..', 0);
  //   try {
  //     const data = {
  //       "orderID": orderID,
  //       "index": index
  //     };
  //     const resp = await createAPackage(data);
  //     shipments[index]["packed"] = resp.data;
  //     hide();
  //     message.success(resp.message);
  //     setShipments(orderID, shipments);
  //   } catch (e) {
  //     hide();
  //     console.log(e)
  //     if (e.data.message)
  //       message.error(e.data.message);
  //   }
  // }

  // const shipPackage = async (index) => {
  //   const hide = message.loading('Shipping..', 0);
  //   try {
  //     const data = {
  //       "orderID": orderID,
  //       "index": index
  //     };
  //     const resp = await shipAPackage(data);
  //     shipments[index]["shipped"] = resp.data;
  //     hide();
  //     message.success(resp.message);
  //     setShipments(orderID, shipments);
  //   } catch (e) {
  //     hide();
  //     console.log(e)
  //     if (e.data.message)
  //       message.error(e.data.message);
  //   }
  // }

  // const returnPackage = async (index) => {
  //   const hide = message.loading('Returning..', 0);
  //   try {
  //     const data = {
  //       "orderID": orderID,
  //       "index": index
  //     };
  //     const resp = await returnAPackage(data);
  //     shipments[index]["returned"] = resp.data;
  //     hide();
  //     message.success(resp.message);
  //     setShipments(orderID, shipments);
  //   } catch (e) {
  //     hide();
  //     console.log(e)
  //     if (e.data.message)
  //       message.error(e.data.message);
  //   }
  // }

  // const handleReceipt = async (index) => {
  //   const hide = message.loading('Fetching..', 0);
  //   try {
  //     const resp = await getInvoice(shipments[index]["zohoInvoiceID"]);
  //     hide();
  //     setHTMLInvoice(resp)
  //     setInvoiceOpen(true)
  //   } catch (e) {
  //     hide();
  //     console.log(e)
  //     if (e.data.message)
  //       message.error(e.data.message);
  //   }
  // }
  
  useEffect(() => {
    console.log(products);
  }, [])

  return (
    <>
      <Table
        columns={columns}
        pagination={{
          total: products.length,
          pageSize: products.length,
          hideOnSinglePage: true
        }}
        // onRow={(record, index) => ({
        //     onClick: () => {handleRowClick(record, index)}
        // })}
        dataSource={products}
      />
    </>
  )
}

export default ProductsTab;