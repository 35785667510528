import createDataContext from "./createDataContext";
import Api from "./index";
import Cookie from "js-cookie";
import { auth } from 'firebase';
import Axios from "axios";


const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INTERNSHIPS":
      return {
        ...state,
        internships: action.payload,
      };
    case "SET_GIGS":
      return {
        ...state,
        gigs: action.payload,
      };
    case "SET_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: action.payload,
      };
    default:
      return state;
  }
};

const login = (dispatch) => (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      var authResult = await auth().signInWithEmailAndPassword(email, password);
      const resp = await Api.post("auth/login/admin", {
        email,
        "uidToken": authResult.user.uid,
      });
      resolve(resp.data);
    } catch (e) {
      console.log(e.response);
      if (e.response != null)
        reject(e.response);
      else if (e.code == "auth/wrong-password")
        reject({"status": 401})
      else if (e.code == "auth/user-not-found")
        reject({"status": 404})
      else
        reject({"status": 1511, "errorLog": e})
    }
  });
};

const sendVerificationOtp = () => (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post("auth/send-otp/admin", {
        email
      });
      resolve(resp.data);
    } catch (e) {
      console.log(e.response);
      if (e.response != null)
        reject(e.response);
      else
        reject({"status": 1511, "errorLog": e})
    }
  });
};

const verifyVerificationOtp = () => (email, otp) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post("auth/verify-otp/admin", {
        email,
        otp
      });
      resolve(resp.data);
    } catch (e) {
      console.log(e.response);
      if (e.response != null)
        reject(e.response);
      else
        reject({"status": 1511, "errorLog": e})
    }
  });
};

const fetchUsers = (dispatch) => (headers) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get('auth/users', { headers });
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchUserReviews = (dispatch) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get('user-review');
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchBanners = (dispatch) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get('home/banner');
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchDigitalStoreOrders = (dispatch) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get('orders/digitalstore/all');
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchProductOrders = (dispatch) => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get('orders/products/all');
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchVoucherOrders = (dispatch) => (headers, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/vouchers/all',
        data,
        {
          headers
        },
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const fetchBuyzoneVoucherOrders = (dispatch) => (headers) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'orders/buyzone-voucher/admin',
        {
          headers
        },
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const createAPackage = (dispatch) => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/products/pack_shipment',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const shipAPackage = (dispatch) => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/products/ship_package',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const markProductOrderAsDelivered = (dispatch) => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/products/mark_as_delivered',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const returnAPackage = (dispatch) => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/products/return_package',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getInvoice = (dispatch) => (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'orders/invoice/' + id
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getManifest = (dispatch) => (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'orders/manifest/' + id
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getEvents = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'event'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getSocieties = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'society'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getProducts = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'products/product'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const createDigitalStoreOrder = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/digitalstore/admin',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const uploadImage = (url, file) => {
  return new Promise (async (resolve, reject) => {
    console.log(file)
    var resp = await Axios.put(
      url,
      file
    );
    console.log(resp.status)
    if (resp.status == 200)
      resolve(true)
    else
      resolve(false)
  })
}

const handleProductThumbnailImageUpload = () => (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'products/product/image'
      );
      await uploadImage(resp.data["url"], file);
      resolve(resp.data["key"]);
    } catch (e) {
      reject(e.response);
    }
  })
}

const handleBulkImageUpload = () => (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'auth/email/image'
      );
      await uploadImage(resp.data["url"], file);
      resolve(resp.data["key"]);
    } catch (e) {
      reject(e.response);
    }
  })
}

const handleEventThumbnailImageUpload = () => (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'products/product/image'
      );
      await uploadImage(resp.data["url"], file);
      resolve(resp.data["key"]);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getCompanies = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'company'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getCategories = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'category'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getDateVoucherOrders = () => (startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(`orders/duration?start=${startDate}&end=${endDate}`)
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getHomeSections = () => () => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(`home/section`)
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const getBrands = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(`brand`)
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  });
}

const addSection = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `home/section`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const editSection = () => (data, id) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.put(
        `home/section/` + id,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const fixVoucherOrder = () => (orderID) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/vouchers/fix',
        {
          orderID
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const editBuyzoneVoucherType = () => (buyzoneVoucherTypeId, data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.put(
        'buyzonevouchers/types/' + buyzoneVoucherTypeId,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const markOrderAsCancelled = () => (orderId) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/vouchers/mark-as-cancelled',
        {
          orderId
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const markOrderAsWaiting = () => (orderId) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/vouchers/mark-as-waiting',
        {
          orderId
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllAdminVoucherOrders = () => (id) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createAdminVoucherOrder = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin`,
        data
      )
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  })
}

const approveAdminVoucherOrder = () => ({ orderID, otp }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/approve?orderID=${orderID}&OTP=${otp}`,
      )
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  })
}

const downloadInventoryExcel = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `inventory/products/excel`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadAdminVoucherOrder = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin/successful?orderID=${orderID}`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadVoucherOrders = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/vouchers/download`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadBuyzoneVoucherOrders = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/buyzone-voucher/download`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadEventInventory = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `event/inventory/download`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadUserEmailExcel = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `auth/download-users/admin`,
        {},
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const downloadAllAdminVoucherOrder = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin/download/all`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  });
}

const checkStatusAdminVoucherOrder = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin/status?orderID=${orderID}`,
      )
      resolve(resp);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const cancelAdminVoucherOrder = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/cancel`,
        {
          orderID
        }
      )
      resolve(resp);
    } catch (e) {
      console.log(e);
      reject(e)
    }
  })
}

const addProduct = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `products/product`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const editProduct = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `products/product/${data["_id"]}`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getInventoryProducts = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `inventory/products`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const addBatchInventory = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `inventory/batch`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const addInventoryAdjustment = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `inventory/adjustment`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getProductOrderBatch = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/products/batch?orderID=${orderID}`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const addEvent = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `event`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const editEvent = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.put(
        `event/${data["_id"]}`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const cancelDigitalStoreOrder = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/digitalstore/cancel_as_admin/${orderID}`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const deliverDigitalStoreOrder = () => ({ orderID }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/digitalstore/mark_as_delivered/${orderID}`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const searchUserByPhoneNumber = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `auth/user`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const searchUser = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `auth/search-user`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getBannedUsers = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `auth/banned-users`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createProductOrder = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'orders/products/counter',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e.response);
    }
  })
}

const sendSMSToUser = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'auth/sms/user',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const sendBulkEmail = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'auth/email/bulk',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const sendBulkSMS = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'auth/sms/bulk',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const sendNotificationToUser = () => (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.post(
        'auth/notification',
        data
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAdminData = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'auth/login/admin'
      );
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllAdminProductOrders = () => (id) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin/product`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createAdminProductOrder = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/product`,
        data
      )
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  })
}

const approveAdminProductOrder = () => ({ orderID, otp }) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/product/approve?orderID=${orderID}&OTP=${otp}`,
      )
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  })
}

const addNewSale = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `sale`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const removeSale = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `sale/delete`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllSales = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `sale`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const downloadSaleReport = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/digitalstore/salereport`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const verifyAddressPin = () => (pin) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `auth/pin/${pin}`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const downloadAdminProductOrderExcel = () => (orderID) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/product/download?orderID=${orderID}`,
        {},
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const cancelAdminProductOrder = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/admin/product/cancel`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const downloadAllAdminProductOrder = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `orders/admin/product/download/all`,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createProductOrderInvoice = () => (orderID) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/products/invoice`,
        {
          orderID
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createProductOrderPickrr = () => (orderID) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/products/pickrr`,
        {
          orderID
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createApplyVoucher = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `vouchers`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const saveApplyVoucher = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `vouchers/update`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const deleteVoucher = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `vouchers/delete`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}


const getVouchers = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `vouchers`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createBuyzoneVoucher = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `buyzonevouchers`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const saveBuyzoneVoucher = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `buyzonevouchers/update`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getBuyzoneVouchers = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `buyzonevouchers`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getBuyzoneVouchersStatistics = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `buyzonevouchers/statistics`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getBuyzoneVoucherTypes = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `buyzonevouchers/types/admin`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createVoucherInvoice = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `orders/vouchers/invoice`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllCampaigns = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `campaign`,
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const createNewCampaign = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `campaign/create`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  })
}

const saveACampaign = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `campaign/update`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e)
    }
  })
}

const downloadCampaignSecretCodes = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `campaign/secret-code-csv`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const downloadCampaignUsage = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `campaign/usage`,
        data,
        {
          responseType: 'blob'
        }
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const handleBans = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `auth/ban-user/admin`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}


const handleCreateClub = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `ticket-events/club/admin`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllClubs = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `ticket-events/club/admin`
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const handleEventImageUpload = () => (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await Api.get(
        'ticket-events/image'
      );
      await uploadImage(resp.data["url"], file);
      resolve(resp.data["key"]);
    } catch (e) {
      reject(e.response);
    }
  })
}

const createTicketEvent = () => (data) => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.post(
        `ticket-events/event`,
        data
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

const getAllEvents = () => () => {
  return new Promise (async (resolve, reject) => {
    try {
      const resp = await Api.get(
        `ticket-events/admin`
      )
      resolve(resp.data);
    } catch (e) {
      reject(e);
    }
  })
}

export const { Context, Provider } = createDataContext(
  reducer,
  {
    login,
    sendVerificationOtp,
    verifyVerificationOtp,
    fetchUsers,
    fetchUserReviews,
    fetchProductOrders,
    fetchVoucherOrders,
    fetchBuyzoneVoucherOrders,
    fetchBanners,
    createAPackage,
    shipAPackage,
    returnAPackage,
    markProductOrderAsDelivered,
    getInvoice,
    getManifest,
    getEvents,
    getProducts,
    getBrands,
    createDigitalStoreOrder,
    handleProductThumbnailImageUpload,
    handleBulkImageUpload,
    getCompanies,
    getCategories,
    getDateVoucherOrders,
    getHomeSections,
    addSection,
    editSection,
    fixVoucherOrder,
    getAllAdminVoucherOrders,
    createAdminVoucherOrder,
    approveAdminVoucherOrder,
    downloadAdminVoucherOrder,
    downloadAllAdminVoucherOrder,
    cancelAdminVoucherOrder,
    checkStatusAdminVoucherOrder,
    addProduct,
    editProduct,
    getInventoryProducts,
    addBatchInventory,
    addInventoryAdjustment,
    getSocieties,
    addEvent,
    editEvent,
    getProductOrderBatch,
    fetchDigitalStoreOrders,
    cancelDigitalStoreOrder,
    deliverDigitalStoreOrder,
    downloadInventoryExcel,
    searchUserByPhoneNumber,
    searchUser,
    createProductOrder,
    sendSMSToUser,
    sendBulkEmail,
    sendBulkSMS,
    sendNotificationToUser,
    getAdminData,
    getAllAdminProductOrders,
    createAdminProductOrder,
    approveAdminProductOrder,
    addNewSale,
    getAllSales,
    removeSale,
    downloadSaleReport,
    verifyAddressPin,
    downloadAdminProductOrderExcel,
    cancelAdminProductOrder,
    downloadAllAdminProductOrder,
    downloadVoucherOrders,
    downloadBuyzoneVoucherOrders,
    createProductOrderInvoice,
    createProductOrderPickrr,
    createApplyVoucher,
    saveApplyVoucher,
    deleteVoucher,
    getVouchers,
    downloadEventInventory,
    createVoucherInvoice,
    getBuyzoneVouchers,
    getBuyzoneVouchersStatistics,
    createBuyzoneVoucher,
    saveBuyzoneVoucher,
    getAllCampaigns,
    createNewCampaign,
    downloadCampaignSecretCodes,
    downloadCampaignUsage,
    saveACampaign,
    handleBans,
    getBannedUsers,
    downloadUserEmailExcel,
    markOrderAsCancelled,
    markOrderAsWaiting,
    handleCreateClub,
    getAllClubs,
    handleEventImageUpload,
    createTicketEvent,
    getAllEvents,
    getBuyzoneVoucherTypes,
    editBuyzoneVoucherType
  },
  {
    internships: [],
    gigs: [],
    companyDetails: {},
  }
);
