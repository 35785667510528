import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Context as DataContext } from "../../../../../api/dataProvider";
import {
	Table,
	Button,
  message
} from 'antd';
import {
  CustomModalStyle,
  Name,
  Other
} from './index.styles'
import { PlusOutlined } from '@ant-design/icons';
import { CreateCampaignModal } from './components/createCampaign';

function SecretCode() {
  const [campaignsList, setCampaignsList] = useState([]);
	const [createCampaignModal, setCreateCampaignModel] = useState();
	const [loading, setLoading] = useState(true)
	const [campaignData, setCampaignData] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)

	const {
    getBrands,
    getAllCampaigns,
    downloadCampaignSecretCodes,
    downloadCampaignUsage,
    getProducts,
    getCompanies,
    getCategories,
  } = useContext(DataContext);

	useEffect(() => {
		getInitialData();
	}, [])

	const getInitialData = async () => {
		let campaignsList = await getAllCampaigns();
    let brandsList = await getBrands();
    let companiesList = await getCompanies();
    let categoriesList = await getCategories();
    let productsList = await getProducts();
    setCompanies(companiesList);
    setCategories(categoriesList);
    setProducts(productsList);
		setCampaignsList(campaignsList);
    setBrands(brandsList["brands"]);
		setLoading(false);
	}

	const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Short Code',
        dataIndex: 'shortCode',
        key: 'shortCode',
        render: text => <Other>{text}</Other>,
    },
    {
      title: 'Expiry',
      dataIndex: 'expiry',
      key: 'expiry',
      render: text => <Other>{moment(text).format('DD MMM YYYY')}</Other>,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, __, index) => {
        return ( 
          <>
            <a onClick={() => downloadUsage(index + ((currentPage-1)*10))} style={{ marginRight: 15 }}>
              Download Usage
            </a>
            <a onClick={() => downloadCampaignCSV(index + ((currentPage-1)*10))}>
              Download CSV
            </a>
          </>
        );
      },
    }
  ];

  const downloadUsage = async (index) => {
    try {
      console.log(index, campaignsList[index]["title"])
      let resp = await downloadCampaignUsage({"campaignId": campaignsList[index]["_id"]});
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', campaignsList[index]["shortCode"] + "-Usage-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
			message.success('Usage excel has been downloaded successfully!')
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const downloadCampaignCSV = async (index) => {
    try {
      console.log(index, campaignsList[index]["title"])
      let resp = await downloadCampaignSecretCodes({"campaignId": campaignsList[index]["_id"]});
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', campaignsList[index]["shortCode"] + "-Secret-Codes-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
			message.success('Secret Code excel has been downloaded successfully!')
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const handleRowClick = async (record, index) => {
    setCampaignData(record)
    setCreateCampaignModel(true)
  }

	return(
		<>
			<Button type="primary" icon={<PlusOutlined />} loading = {loading} onClick = {() => setCreateCampaignModel(true)}>
        Create Campaign
      </Button>
      <div style = {{ height: 15 }} />
			<Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={campaignsList}
        pagination={{ onChange: (e) => setCurrentPage(e), current: currentPage }}
      />
			<CustomModalStyle
        centered
        visible={createCampaignModal}
        onOk={() => setCreateCampaignModel(false)}
        onCancel={() => setCreateCampaignModel(false)}
        afterClose = {() => setCampaignData()}
        width = {1100}
        style = {{
          height: 700
        }}
      >
				<CreateCampaignModal 
          brands={brands} 
          products = {products} 
          categories = {categories} 
          companies = {companies}
          data = {campaignData} 
          setModalVisibility = {setCreateCampaignModel} 
        />
			</CustomModalStyle>
		</>
	)

}

export default SecretCode;