import React, { useEffect, useState } from 'react';
import {
	ModalTitle,
	Divider,
	Name,
	Other,
	Title,
	SelectSubTitle
} from '../index.styles'
import { 
	Col,
	Row,
	Select,
	Input,
	Modal,
	Button,
	message,
	Table,
	Space,
	Tag,
	Popover,
	Avatar,
	Checkbox
} from 'antd';
import { Context as DataContext } from '../../../../../../api/dataProvider';
import { useContext } from 'react';


const { Option } = Select;
const { Search } = Input;

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export function CreateOrder({
	events,
	productsList,
	createOrder,
	loader
}) {

	const {
		searchUserByPhoneNumber
	} = useContext(DataContext);

	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [addressLine1, setAddressLine1] = useState();
	const [addressLine2, setAddressLine2] = useState();
	const [addressPhone, setAddressPhone] = useState();
	const [pin, setPin] = useState();
	const [state, setState] = useState();
	const [city, setCity] = useState();
	const [addressArr, setAddressArr] = useState([]);
	const [checkUser, setCheckUser] = useState(false);
	const [existingUser, setExistingUser] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState();
	const [cod, setCOD] = useState(false);

	const [products, setProducts] = useState([]);

	const [addProduct, setAddProduct] = useState(false);

	const [pinLoading, setPinLoading] = useState(false);
	const [userLoader, setUserLoading] = useState(false);

	const [productID, setProductID] = useState();
	const [customizationID, setCustomizationID] = useState();
	const [customizationIndex, setCustomizationIndex] = useState();
	const [productData, setProductData] = useState();
	const [quantity, setQuantity] = useState();
	const [price, setPrice] = useState();
	const [sku, setSKU] = useState();

	const [shipmentCharges, setShipmentCharges] = useState(0);
	const [codCharges, setCODCharges] = useState(0);

	const [totalAmount, setTotalAmount] = useState(0);

	const getSKUProduct = async () => {
		console.log(productsList.map((v) => v["sku"]));
		const product = productsList.find((val) => val["sku"] == Number(sku));
		if (product != null) {
			setProductID(product["_id"])
			setProductData(product)
		}
		else {
			message.info('No matching SKU found')
			setProductID();
		}
	}
	
	useEffect(() => {
		getTotalAmount();
	}, [products])

	useEffect(() => {
		if (cod)
			setCODCharges(30)
		else
			setCODCharges(0)
	}, [cod])

	const getTotalAmount = async () => {
		if (products.length > 0) {
			var totalAmount = 0;
			for (let product of products) {
				totalAmount += (product["price"] * product["quantity"]);
				if (totalAmount >= 500)
					setShipmentCharges(0);
				else
					setShipmentCharges(49)
				setTotalAmount(totalAmount);
			}
		} else {
			// console.log("No products");
			setTotalAmount(0);
		}
	}

	const removeItemFromBill = (index) => {
		let newArr = [...products];
		newArr.splice(index, 1);
		setProducts(newArr);
	}

	const columns = [
    {
			title: '',
			dataIndex: 'index',
			key: 'image',
			render: index => <Popover content={PopoverProductDetails(productsList[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${productsList[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
			width: 60
    },
    {
			title: 'Name',
			dataIndex: 'index',
			key: 'name',
			render: index => <Name>{productsList[index]["title"]}</Name>,
    },
    {
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
      title: '',
      dataIndex: 'operation',
			width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            Remove
          </a>
        );
      },
    }
  ];

	const searchUser = async (val) => {
		try {
			setFirstName();
			setLastName();
			setUserLoading(true);
			let data = await searchUserByPhoneNumber({
				"phoneNumber": val
			});
			setCheckUser(true);
			setUserLoading(false);
			if (data != null) {
				console.log(data);
				setExistingUser(true);
				setFirstName(data.firstName);
				setLastName(data.lastName);
				setAddressArr(data.deliveryAddress)
			} else
				setExistingUser(false);
		} catch (e) {
			console.log(e);
			if (e.data && e.data.message) {
				message.error(e.data.message)
				if (e.data.code != null) {
					message.error("Code: ", e.data.code)
				}
			} else {
				message.error('Some error occurred');
			}
		}
	}

	return (
		<>
			<div>
				<ModalTitle>Create Order</ModalTitle>
			</div>
			<div style = {{ height: 25 }} />
			<Divider />
			<div style = {{ height: 20 }} />
			<Row justify="space-between">
				{
					checkUser &&
					<>
						<Col span={7}>
							<Input placeholder="First Name" disabled = {existingUser} value = {firstName} onChange={(e) => setFirstName(e.target.value)} />
						</Col>
						<Col span={7}>
							<Input placeholder="Last Name" disabled = {existingUser} value = {lastName} onChange={(e) => setLastName(e.target.value)} />
						</Col>
					</>
				}
				<Col span={7}>
					<Search placeholder="Phone Number" type="number" value = {phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} onSearch={searchUser} loading = {userLoader} />
				</Col>
			</Row>
			{
				existingUser && addressArr.length > 0 &&
				<Select
					style = {{
						width: '100%',
						marginTop: 15
					}}
					// disabled = {editProduct}
					placeholder="Select Address"
					optionFilterProp="children"
					value={selectedAddress}
					onChange={(val) => setSelectedAddress(val)}
				>
					{
						addressArr.map((v, i) => <Option value={i} key={i} 
							// disabled = {(v["stockOnHand"] - v["stockCommitted"]) <= 0}
						>
							{v["addressLine1"]}<br />
							{v["addressLine2"]}<br />
							<SelectSubTitle>
								{v["city"]}<br />
								{v["state"]}<br />
								{v["pin"]}
							</SelectSubTitle>
						</Option>)
					}
					<Option value={addressArr.length} key={addressArr.length}>Other Address</Option>
				</Select>
			}
			{
				(checkUser && ((existingUser && addressArr.length == 0) || (existingUser && selectedAddress == addressArr.length) || (!existingUser))) &&
				<>
					<div style = {{ height: 20 }}></div>
					<Row justify="space-between">
						<Input placeholder="Address Line 1" style = {{ width: '49%' }} value = {addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
						<Input placeholder="Address Line 2" style = {{ width: '49%' }} value = {addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
					</Row>
					<div style = {{ height: 15 }} />
					<Search placeholder="Pin Code" onSearch={(v) => console.log(v)} enterButton loading = {pinLoading} onChange={(e) => setPin(e.target.value)} />
				</>
			}
			<div style = {{ height: 20 }} />
			<Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
			<div style = {{ height: 20 }} />
			<Table dataSource={products} columns={columns} scroll={{ y: '150px' }} />
			<div style = {{ height: 20 }} />
			<Divider />
			<div style = {{ height: 20 }} />
			<div
				style = {{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<div>
					{
						shipmentCharges != 0 &&
						<Name>
							Shipment Charges: ₹{shipmentCharges}
						</Name>
					}
					{
						cod &&
						<Name>
							COD Charges: ₹{codCharges}
						</Name>
					}
					<Name>
						Total: ₹{totalAmount}
					</Name>
					<Name>
						Grand Total: ₹{totalAmount + codCharges + shipmentCharges}
					</Name>
				</div>
				<Space>
					<Checkbox onChange={(e) => setCOD(e.target.checked)} value={cod}>Cash On Delivery</Checkbox>
					<Button loading = {loader} type='primary' onClick={() => {
						let finalAddress = {};
						if (addressArr != null && addressArr.length > 0 && selectedAddress != addressArr.length)
							finalAddress = addressArr[selectedAddress]
						else
							finalAddress = {
								addressLine1,
								addressLine2,
								city,
								state,
								pin
							}
						if (phoneNumber == null || phoneNumber.length == 0) {
							message.error('Please enter phone number');
							return;
						}
						if (firstName == null || firstName.length == 0) {
							message.error('Please enter first');
							return;
						}
						if (lastName == null || lastName.length == 0) {
							message.error('Please enter first');
							return;
						}
						if (Object.keys.length == 0) {
							message.error('Please select an address');
							return;
						}
						if (products.length == 0) {
							message.error('Please select at least one product');
							return;
						}
						let finalData = {
							firstName,
							lastName,
							phoneNumber,
							selectedAddress: finalAddress,
							products,
							totalAmount,
							shipmentCharges,
							codCharges,
							cod
						}
						createOrder(finalData);
					}}>Create</Button>
				</Space>
			</div>
			<Modal
				visible={addProduct}
				onOk={() => {
					if (productID == null) {
						message.error('Please select a product first.')
						return
					}
					if (quantity == null) {
						message.error('Please add a quantity first.')
						return
					}
					if ((price != null && price != '') && price <= 0) {
						message.error('Please add a valid price.')
						return
					}
					if (productData["customizations"].length != 0 && customizationID == null) {
						message.error('Please select a customization.')
						return
					}
					const newProducts = [...products];
					const index = productsList.findIndex((val) => val["_id"] == productID);
					console.log(productData);
					console.log(customizationID);
					let customizationObject = productData["customizations"].find((val) => val["size"].toString() == customizationID.toString());
					if (customizationObject != null)
						customizationObject = customizationObject["compositeProduct"];
					newProducts.push({
						"product": productID,
						"quantity": quantity,
						"price": price == null ? customizationObject != null ? (customizationObject["discountPrice"] || productsList[index]["originalPrice"]) : (productsList[index]["discountPrice"] == null ? productsList[index]["originalPrice"] : productsList[index]["discountPrice"]) : price,
						"index": index,
						"customization": customizationID
					})
					setProducts(newProducts);
					setAddProduct(false);
					setProductID();
					setQuantity();
					setPrice();
					message.success('Product added successfully');
				}}
				onCancel={() => setAddProduct(false)}
				okText = "Add"
				width = {950}
			>
				<div>
					<ModalTitle>Select Product</ModalTitle>
				</div>
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={20}>
						<Input placeholder="SKU" type="number" value = {sku} onChange={(e) => setSKU(e.target.value)} />
					</Col>
					<Col span={3}>
						<Button type='primary' onClick={() => getSKUProduct()}>Search</Button>
					</Col>
				</Row>
				<div style = {{ height: 20 }} />
				<Divider />
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={7}>
						<Select
							showSearch
							style = {{
								width: '100%'
							}}
							placeholder="Select Product"
							optionFilterProp="children"
							value={productID}
							onChange={(val) => {setProductID(val); setProductData(productsList.find((v) => v["_id"] == val))}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								productsList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
							}
						</Select>
					</Col>
					<Col span={7}>
						<Input placeholder="Quantity" type="number" value = {quantity} onChange={(e) => setQuantity(e.target.value)} />
					</Col>
					<Col span={7}>
						<Input placeholder="Price (Optional)" type="number" value = {price} onChange={(e) => setPrice(e.target.value)} />
					</Col>
					{
						productData && productData["customizations"].length > 0 &&
						<Select
              showSearch
              style = {{
                width: '100%',
								marginTop: 15
              }}
              placeholder="Select Customization"
              optionFilterProp="children"
              onChange={(val) => setCustomizationID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                productData.customizations.map((v) => <Option value={v["_id"]} key={v["_id"]}>{
                  (productData.sizes.length > 0 ? (productData.sizes.find((val) => val["_id"] == v["size"])["value"] + ' ') : '') + 
                  (productData.colors.length > 0 ? productData.colors.find((val) => val["_id"] == v["color"])["name"] : '')
                }</Option>)
              }
            </Select>
					}
				</Row>
			</Modal>
		</>
	)
}