import React, { useContext, useEffect, useState } from 'react';
import {
	ModalTitle,
	Divider,
	MenuButton,
	FooterButton,
	Name,
  Title
} from './index.styles';
import {
	Button,
	Col,
	message,
	Row,
	Input,
	Select,
  Space,
  DatePicker,
	Table,
	Modal,
	Tag,
	Popover,
	Avatar
} from 'antd';
import { Context as DataContext } from '../../../../../../api/dataProvider';
import moment from 'moment';
const { Option } = Select

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export function CreateCampaignModal({
	categories,
	companies,
	products,
	brands,
	allUsers,
	data,
	setModalVisibility
}) {

	const [editModal, setEditModal] = useState(false);
	const [title, setTitle] = useState()
	const [shortCode, setShortCode] = useState()
	const [expiry, setExpiry] = useState()

	const [denominationModal, setDenominationModal] = useState()
	const [denominations, setDenominations] = useState([])
	const [denomination, setDenomination] = useState()
	const [rewardType, setRewardType] = useState()
	const [total, setTotal] = useState()
	const [brand, setBrand] = useState()

	const [editIndex, setEditIndex] = useState()

	const [voucherType, setVoucherType] = useState();
  const [minAmount, setMinAmount] = useState();
  const [maxUsableAmount, setMaxUsableAmount] = useState();

  const [brandType, setBrandType] = useState();
  const [brandList, setBrandList] = useState([]);

  const [addBrand, setAddBrand] = useState(false);
  const [brandID, setBrandID] = useState();

  const [productType, setProductType] = useState();
  const [company, setCompany] = useState();
  const [category, setCategory] = useState();
  const [productList, setProductList] = useState([]);

  const [addProduct, setAddProduct] = useState(false);
  const [productID, setProductID] = useState();

	const voucherColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'image',
      render: index => <Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${brands[index]["logo"]}`} size="large" style = {{ cursor: 'pointer' }} />,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'index',
      key: 'name',
      render: index => <Name>{brands[index]["name"]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeBrandFromList(index)}>
            {
							!editModal && 'Remove'
						}
          </a>
        );
      },
    }
  ];

  const productColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'image',
      render: index => <Popover content={PopoverProductDetails(products[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${products[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'index',
      key: 'name',
      render: index => <Name>{products[index]["title"]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            {
							!editModal && 'Remove'
						}
          </a>
        );
      },
    }
  ];

	const removeBrandFromList = (index) => {
		let newArr = [...brandList];
		newArr.splice(index, 1);
		setBrandList(newArr);
	}

	const removeItemFromBill = (index) => {
		let newArr = [...productList];
		newArr.splice(index, 1);
		setProductList(newArr);
	}

	const {
		createNewCampaign,
		saveACampaign
	} = useContext(DataContext);

	useEffect(() => {
		if (data != null) {
			setEditModal(true);
			setTitle(data["title"])
			setShortCode(data["shortCode"])
			setExpiry(data["expiry"])
			setDenominations(data["denominations"])
		}
	}, [])

	const denominationColumns = [
    {
      title: 'Value',
      dataIndex: 'denomination',
      key: 'denomination',
      render: text => <Name>{text}</Name>,
    },
		{
      title: 'Total Count',
      dataIndex: 'total',
      key: 'total',
      render: text => <Name>{text}</Name>,
    },
		{
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: text => <Name>{text == null ? "Buyzone Voucher" : text["name"]}</Name>,
    },
		{
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => editDenomination(index)}>
            Edit
          </a>
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeDenomination(index)}>
            Remove
          </a>
        );
      },
    }
  ];

	const editDenomination = (index) => {
		setEditIndex(index)
		let denomination = denominations[index]
		setDenomination(denomination["denomination"])
		setRewardType(denomination["rewardType"])
		setTotal(denomination["total"])
		if (denomination["brand"] != null)
			setBrand(denomination["brand"]["_id"])
		if (denomination["rewardType"] == 1 || denomination["rewardType"] == 2) {
			let buyzoneVoucherConstraints = denomination["buyzoneVoucherConstraints"]
			setVoucherType(buyzoneVoucherConstraints["voucherType"])
			setMinAmount(buyzoneVoucherConstraints["minAmount"])
			setProductType(buyzoneVoucherConstraints["productData"]["productType"])
			setCompany(buyzoneVoucherConstraints["productData"]["company"])
			setProductList(buyzoneVoucherConstraints["productData"]["productList"].map(v => {return {"details": v}}))
			setBrandType(buyzoneVoucherConstraints["brandData"]["brandType"])
			const newBrands = [];
			for (let v of buyzoneVoucherConstraints["brandData"]["brandList"]) {
				const brandIndex = brands.findIndex(val => val["_id"] == v);
				newBrands.push({
					"details": v,
					index: brandIndex
				});
			}
			setBrandList(newBrands)
			setMaxUsableAmount(buyzoneVoucherConstraints["maxUsableAmount"])
		}
		setDenominationModal(true)
	}

	const removeDenomination = (index) => {
		let newArr = [...denominations];
		newArr.splice(index, 1);
		setDenominations(newArr);
	}

	const saveCampaign = async () => {
		const finalData = {
			campaignId: data["_id"],
			denominations
		}
		try {
      let resp = await saveACampaign(finalData);
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', shortCode + "-Secret-Codes-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
			setModalVisibility(false);
			message.success('Campaign saved! Updated Secret Code excel has been downloaded successfully!')
			window.location.reload();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
	}

	const createCampaign = async () => {
		if (title == null || title.length == 0) {
			message.error('Please add a title.')
			return;
		}
		if (shortCode != null && shortCode.length != 4) {
			message.error('Short code must be 4 letters')
			return;
		}
		if (denominations.length == 0) {
			message.error('Please add denominations')
			return;
		}
		const finalData = {
			title,
			shortCode,
			expiry,
			denominations
		}
		try {
      let resp = await createNewCampaign(finalData);
      const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "Campaign.xlsx"); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
			setModalVisibility(false);
			message.success('Campaign created! Secret Code excel has been downloaded successfully!')
			window.location.reload();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
	}
	
	return (
		<>
			<div>
				<ModalTitle>{editModal ? 'Edit' : 'Create'} Campaign</ModalTitle>
				<div style={{ height: 25 }} />
				<Divider />
				<div style={{ height: 25 }} />
				<Space>
					<Input 
						placeholder={'Title'} 
						value = {title} 
						onChange={(e) => setTitle(e.target.value)} 
						maxLength = {50} 
						disabled = {editModal}
						allowClear />
					<Input 
						placeholder={'Short Code (Optional)'} 
						value = {shortCode}
						onChange={(e) => setShortCode(e.target.value)} 
						maxLength = {4} 
						disabled = {editModal}
						allowClear />
					<DatePicker disabledDate={(current) => current && current < moment().endOf('day')} disabled = {editModal} placeholder="Expiry" format="DD MMM YYYY" onChange={(val) => {setExpiry(val.toDate())}} value={expiry == null ? expiry : moment(expiry)} onOk={(val) => setExpiry(val.toDate())} />
				</Space>
				<div style = {{ height: 15 }} />
				<Button type='primary' disabled = {editModal} onClick={() => setDenominationModal(true)}>Add Denominations</Button>
				<div style = {{ height: 20 }} />
				<Table dataSource={denominations} columns={denominationColumns} scroll={{ y: '150px' }} />
				<div style={{ height: 25 }} />
				<Divider />
				<div style={{ height: 25 }} />
				<Row justify="end">
					{/* {
						editModal &&
						<>
							<Button color="secondary" onClick={() => handleDelete()}>Delete</Button>
							<div style={{ width: 15 }} />
						</>
					} */}
					<Button type='primary' onClick={() => editModal ? saveCampaign() : createCampaign()}>{editModal ? 'Save' : 'Create'}</Button>
				</Row>
				<Modal
					visible={denominationModal}
					onOk={() => {
						if (denomination == null || denomination.length == 0) {
							message.error('Please add a denomination value.')
							return;
						}
						if (total == null || total.length == 0) {
							message.error('Please add total count of secret codes.')
							return;
						}
						if (rewardType == null) {
							message.error('Please select reward type.')
							return;
						}
						if (rewardType == 0 && brand == null) {
							message.error('Please select a brand first.')
							return;
						}
						let newDenominations = [...denominations];
						if (editIndex == null)
							newDenominations.push({
								denomination,
								rewardType,
								total,
								brand: rewardType == 0 ? brand : null,
								buyzoneVoucherConstraints: rewardType == 1 ? {
									voucherType,
									minAmount,
									productData: {
										productType,
										company,
										category,
										productList: productList.map(v => v["details"])
									},
									brandData: {
										brandType,
										brandList: brandList.map(v => v["details"])
									},
									maxUsableAmount,
								} : null
							});
						else
							newDenominations[editIndex] = {
								denomination,
								rewardType,
								total,
								brand: rewardType == 0 ? brand : null,
								buyzoneVoucherConstraints: rewardType == 1 ? {
									voucherType,
									minAmount,
									productData: {
										productType,
										company,
										category,
										productList: productList.map(v => v["details"])
									},
									brandData: {
										brandType,
										brandList: brandList.map(v => v["details"])
									},
									maxUsableAmount,
								} : null
							}
						setDenominations(newDenominations)
						setDenominationModal(false)
						setDenomination();
						setTotal();
						setRewardType();
						setBrand();
						setEditIndex();
						message.success(`Denomination ${editIndex == null ? 'added' : 'edited'} successfully!`);
					}}
					onCancel={() => setDenominationModal(false)}
					okText = {editModal ? "Save" : "Add"}
					width = {950}
					title = "Add Denomination"
				>
					<Space>
						<Input 
							placeholder={'Value'} 
							value = {denomination} 
							onChange={(e) => setDenomination(e.target.value)} 
							maxLength = {50}
							disabled = {editModal}
							allowClear />
						<Input 
							placeholder={'Total Count'} 
							value = {total}
							onChange={(e) => setTotal(e.target.value)} 
							maxLength = {4}
							allowClear />
						<Select
              placeholder = "Reward Type"
              onSelect = {(v) => setRewardType(v)}
              style = {{ width: 200 }}
							disabled = {editModal}
              value = {rewardType}
            >
              <Option key="I3" value={0}>Brands</Option>
              <Option key="I0" value={1}>Conditional Buyzone Vouchers</Option>
			  <Option key="I1" value={2}>Non-Conditional Buyzone Vouchers</Option>
            </Select>
						{
							rewardType == 0 &&
							<Select
								disabled = {editModal}
								showSearch
								style = {{
									width: '100%'
								}}
								placeholder="Select Brand"
								optionFilterProp="children"
								value={brand}
								onChange={(val) => setBrand(val)}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									brands.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["name"]}</Option>)
								}
							</Select>
						}
					</Space>
					<div style = {{ height: 15 }} />
					{
						(rewardType === 1 || rewardType === 2) &&
						<>
							<Space>
								<Select
									disabled = {editModal}
									style={{ width: 150 }}
									placeholder="Benefit Type"
									optionFilterProp="children"
									value={voucherType}
									onChange={(e) => setVoucherType(e)}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									<Option key={0} value={0}>All</Option>
									<Option key={1} value={1}>E-Vouchers</Option>
									<Option key={2} value={2}>Products</Option>
									<Option key={3} value={3}>Digital Stores</Option>
								</Select>
								{
									rewardType === 1 &&
									<Input 
										placeholder="Min. Order Amount(Optional)"
										disabled = {editModal}
										value = {minAmount}
										onChange={(e) => setMinAmount(e.target.value)} 
										maxLength = {50} 
										allowClear 
									/>
								}
								{
									rewardType === 1 &&
									<Input 
										placeholder="Max Usable Amt (Optional)" 
										disabled = {editModal}
										value = {maxUsableAmount} 
										onChange={(e) => setMaxUsableAmount(e.target.value)} 
										maxLength = {50} 
										allowClear
									/>
								}
							</Space>
							<div style = {{ height: 15 }} />
							{
								voucherType == 1 &&
								<>
									<Space>
										<Select
											placeholder = "E-Voucher Selection"
											disabled = {editModal}
											onSelect = {(v) => setBrandType(v)}
											style = {{ width: 200 }}
											value = {brandType}
										>
											<Option key="I3" value={0}>All</Option>
											<Option key="I0" value={1}>Custom</Option>
										</Select>
									</Space>
									{
										brandType == 1 &&
										<>
											<div style = {{ height: 15 }} />
											<Button disabled = {editModal} type='primary' onClick={() => {console.log("Here"); setAddBrand(true);}}>Add Brands</Button>
											<div style = {{ height: 20 }} />
											<Table dataSource={brandList} columns={voucherColumns} scroll={{ y: '150px' }} />
										</>
									}
									<Modal
										visible={addBrand}
										onOk={() => {
											if (brandID == null) {
												message.error('Please select a brand first.')
												return;
											}
											const newBrands = [...brandList];
											const index = brands.findIndex(val => val["_id"] == brandID)
											newBrands.push({
												"details": brandID,
												index
											});
											setBrandList(newBrands);
											setAddBrand(false);
											setBrandID();
											message.success('Brand added successfully!');
										}}
										onCancel={() => setBrandType(false)}
										okText = "Add"
										width = {450}
										title = "Add Brand"
									>
										<div style = {{ height: 20 }} />
										<Select
											showSearch
											style = {{
												width: '100%'
											}}
											placeholder="Select Brand"
											optionFilterProp="children"
											value={brandID}
											onChange={(val) => setBrandID(val)}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{
												brands.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["name"]}</Option>)
											}
										</Select>
									</Modal>
								</>
							}
							{
								voucherType == 2 &&
								<>
									<Space>
										<Select
											placeholder = "Products Selection"
											onSelect = {(v) => setProductType(v)}
											style = {{ width: 200 }}
											value = {productType}
											disabled = {editModal}
										>
											<Option key="I3" value={0}>All</Option>
											<Option key="I0" value={1}>Company</Option>
											<Option key="I1" value={2}>Category</Option>
											<Option key="I2" value={3}>Custom</Option>
										</Select>
										{
											productType == 1 &&
											<Select
												showSearch
												disabled = {editModal}
												placeholder="Select Company"
												optionFilterProp="children"
												onChange={(e) => setCompany(e)}
												value={company}
												filterOption={(input, option) =>
													option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{
													companies.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
												}
											</Select>
										}
										{
											productType == 2 &&
											<Select
												showSearch
												disabled = {editModal}
												placeholder="Select Category"
												optionFilterProp="children"
												value={category}
												onChange={(e) => setCategory(e)}
												filterOption={(input, option) =>
													option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{
													categories.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
												}
											</Select>
										}
									</Space>
									{
										productType == 3 &&
										<>
											<div style = {{ height: 15 }} />
											<Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
											<div style = {{ height: 20 }} />
											<Table dataSource={productList} columns={productColumns} scroll={{ y: '150px' }} />
										</>
									}
									<Modal
										visible={addProduct}
										onOk={() => {
											if (productID == null) {
												message.error('Please select a product first.')
												return
											}
											const newProducts = [...productList];
											const index = products.findIndex((val) => val["_id"] == productID);
											newProducts.push({
												"details": productID,
												"index": index,
											})
											setProductList(newProducts);
											setAddProduct(false);
											setProductID();
											message.success('Product added successfully');
										}}
										onCancel={() => setAddProduct(false)}
										okText = "Add"
										width = {450}
										title = "Add Product"
									>
										<div style = {{ height: 20 }} />
										<Select
											showSearch
											style = {{
												width: '100%'
											}}
											placeholder="Select Product"
											optionFilterProp="children"
											value={productID}
											onChange={(val) => setProductID(val)}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{
												products.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
											}
										</Select>
									</Modal>
								</>
							}
						</>
					}
				</Modal>
			</div>
		</>
	)
}