import React, { useContext, useEffect, useState } from 'react';

import {
  Button, 
  Table,
  message,
  Avatar,
  Row,
  Col,
  Space
} from 'antd';

import {
  Name,
} from './index.styles';

import { PlusOutlined } from '@ant-design/icons';

import { Context as DataContext } from "../../../../../api/dataProvider";
import { STATIC_IMG_URL } from '../../../../../Constants';
import { EventModal } from './components/eventModal';


export const DigitalStores = () => {

  const [loader, setLoader] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [eventsList, setEventList] = useState([]);
  const [societiesList, setSocietiesList] = useState([]);
  const [productsList, setProductsList] = useState([]);

  const [eventData, setEventData] = useState();

  const {
    getEvents,
    getSocieties,
    getProducts
  } = useContext(DataContext);

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = async () => {
    try {
      setLoader(true);
      let eventsList = await getEvents();
      let societiesList = await getSocieties();
      let productsList = await getProducts();
      setEventList(eventsList);
      setSocietiesList(societiesList)
      setProductsList(productsList);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      let { response } = e;
      if (response != null) {
        console.error(response);
        message.error(response.data.message);
      } else {
        message.error("Some error occurred");
        console.error(e);
      }
    }
  }

  const eventColumns = [
    {
      'title': '',
      dataIndex: 'society',
      key: 'img',
      width: 80,
      render: i => <Avatar src={STATIC_IMG_URL + i.image} />
    },
    {
      'title': 'Event Name',
      dataIndex: 'title',
      key: 'title',
      render: i => <Name>{i}</Name>
    },
    {
      'title': 'Society',
      dataIndex: 'society',
      key: 'society',
      render: i => <Name>{i.name}</Name>,
    },
    {
      'title': 'Products Count',
      dataIndex: 'inventory',
      key: 'inventory',
      render: i => <Name>{i.length}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        return ( 
          <a onClick={() => editEvent(record)}>
            Edit
          </a>
        );
      },
    }
  ];

  const editEvent = (data) => {
    setEventData(data);
    setEventModal(true);
  }

  return(
    <>
      <Space>
        <Button type="primary" icon={<PlusOutlined />} loading = {loader} onClick = {() => setEventModal(true)}>
          Add Event
        </Button>
        <Button icon={<PlusOutlined />} loading = {loader} onClick = {() => setEventModal(true)}>
          Audit Event
        </Button>
      </Space>
      <div style = {{ height: 20 }} />
      <Table loading = {loader} dataSource={eventsList} columns={eventColumns} scroll={{ y: '70vh' }} />
      {
        eventModal &&
        <EventModal visible = {eventModal} hideModal = {() => setEventModal(false)} societiesList = {societiesList} productsList = {productsList} eventData = {eventData} />
      }
    </>
  );
};