import { message, Table, Avatar, Modal, Input, Space, Button, Select, Checkbox } from 'antd';
import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactFileReader from 'react-file-reader';
import { PlusOutlined } from '@ant-design/icons'
import Papaparse from 'papaparse';

import { Context as DataContext } from "../../../../api/dataProvider";
import AuthContext from '../../../../contexts/authContext';
import { 
  Name,
  Other
} from './index.styles';

import moment from 'moment';

import { Editor } from '@tinymce/tinymce-react';

const { Search } = Input;
const { Option } = Select;

function Reviews() {

  const {
    fetchUserReviews
  } = useContext(DataContext);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (page = 1, limit = 10) => {
    try {
      const data = await fetchUserReviews();
      setUserList(data);
    } catch (e) {
      console.log(e);
      message.error('Some error occurred');
    }
  }

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'user',
      key: 'user',
      render: user => <Name>{user["firstName"]}</Name>,
    },
    {
      title: 'Customer Phone',
      dataIndex: 'user',
      key: 'user',
      render: user => <Other>{user["phone"]}</Other>,
    },
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (_, object) => <Other>{object["voucherOrder"] != null ? object["voucherOrder"]["orderID"] : object["productOrder"]["orderID"]}</Other>,
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: rating => <Other>{rating}</Other>,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: message => <Other>{message}</Other>,
    }
  ];

  return (
    <>
      <div>
        <div style = {{ height: 15 }} />
        <Table
          columns={columns}
          scroll={{ y: '70vh' }}
          dataSource={userList}
          // pagination={
          //   { 
          //     defaultPageSize: 10, 
          //     showSizeChanger: true,
          //     pageSizeOptions: ['10', '50', '100'], 
          //     total: totalCount,
          //     defaultCurrent: 1,
          //     current: tablePage,
          //     size: tableSize,
          //     showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} orders`,
          //     onChange: (page, limit) => search ? handlePageWithSearch(page, limit) : getUsers(page, limit)
          //   }
          // }
        />
      </div>
    </>
  )
}

export default Reviews;