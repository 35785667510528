import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';

export const StatsBackground = styled.div`
    margin: 0rem 12rem 0rem 12rem;
`;

export const StatTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #323441;
`;

export const StatText = styled.div`
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
    margin-top: 25px;
    color: ${(props) => (props.color ? props.color : "#333e49")};
`;

export const SecondaryStatText = styled.div`
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    margin-top: 5px;
    color: ${(props) => (props.color ? props.color : "#707070")};
`;

export const Title = styled.div`
    opacity: 0.8;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const Other = styled.div`
    font-size: 12px;
    opacity: 0.5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const TableBackground = styled.div`
    .ant-table-cell {
        background: #f9f9f9
    }
`;

export const Divider = styled.div`
    height: 1px;
    background: #e1e1e1;
`;

export const ModalFooterButton = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    opacity: 0.8;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: solid 1px ${(props) => props.color ? props.color : '#5643f1'};
    background-color: #f9f9f9;
    color: ${(props) => props.color ? props.color : '#5643f1'};
`;

export const CustomModalStyle = styled(Modal)`
    .ant-modal-content {
        border-radius: 16px !important;
    }
    .ant-modal-footer {
        display: none;
    }
`;