import React, { useContext, useEffect, useState } from 'react';

import {
  Button, 
  Table,
  Select,
  Input,
  Modal,
  Row,
  Col,
  Space,
  message
} from 'antd';

import {
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  Divider
} from './index.styles'

import { PlusOutlined } from '@ant-design/icons';

import { Context as DataContext } from "../../../../../api/dataProvider";
import Avatar from 'antd/lib/avatar/avatar';
import { STATIC_IMG_URL } from '../../../../../Constants';

const { Option } = Select;

export const Sections = () => {

  const [sectionsList, setSectionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [positionOrder, setPositionOrder] = useState(true);
  const [sectionData, setSectionData] = useState();
  const [sectionModal, setSectionModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);

  const [itemIndex, setItemIndex] = useState();
  const [itemPosition, setItemPosition] = useState();

  const [brandsArr, setBrandsArr] = useState([]);
  const [productsArr, setProductsArr] = useState([]);
  const [bannersArr, setBannersArr] = useState([]);

  const [sectionID, setSectionID] = useState();
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [maxDisplayCount, setMaxDisplayCount] = useState();
  const [position, setPosition] = useState();
  const [axis, setAxis] = useState();
  const [chosenItems, setChosenItems] = useState([]);

  const [sectionLoading, setSectionLoading] = useState(false);

  const typeToText = {
    0: "Brands",
    1: "Products",
    2: "Banners",
    3: "Spacers"
  }

  const {
    getHomeSections,
    fetchBanners,
    getBrands,
    getProducts,
    addSection,
    editSection
  } = useContext(DataContext);

  useEffect(() => {
    getInitialData();
  }, [])

  const getInitialData = async () => {
    setLoading(false);
    const sections = await getHomeSections();
    setSectionsList(sections);
    const banners = await fetchBanners();
    setBannersArr(banners);
    const brands = await getBrands();
    setBrandsArr(brands.brands)
    const products = await getProducts();
    console.log(products.length);
    setProductsArr(products);
    setLoading(false);
  }

  const editSectionModal = async (record, index) => {
    console.log(record, index);
    setSectionID(record["_id"]);
    setTitle(record["title"]);
    setPosition(record["position"]);
    setType(record["type"]);
    setAxis(record["axis"])
    setMaxDisplayCount(record["maxDisplayCount"]);
    var main = [];
    for (var data of record["data"]) {
      const finalData = {
        "index": (record["type"] == 0 ? brandsArr : record["type"] == 1 ? productsArr : bannersArr).findIndex((val) => val["_id"] == data["_id"]),
        "position": data["position"]
      };
      main.push(finalData);
    }
    setChosenItems(main);
    setSectionModal(true);
  }

  const editSectionMethod = async () => {
    var main;
    setSectionLoading(true);
    if (type <= 2) {
      main = [];
      for (var i of chosenItems) {
        const data = {
          "_id": (type == 0 ? brandsArr : type == 1 ? productsArr : bannersArr)[i["index"]]["_id"],
          "position": i["position"]
        };
        main.push(
          data
        );
      }
    }
    console.log(main);
    var finalArr = [];
    if (main != null) {
      finalArr = [];
      for (var data of main) {
        finalArr.push(data["_id"])
      }
    }
    var finalData = {
      "title": title,
      "type": type,
      "data": main,
      "axis": axis,
      "position": position
    };
    try {
      const resp = await editSection(finalData, sectionID);
      console.log(resp);
      setSectionModal(false);
      setSectionLoading(false);
      message.success('Section added successfully');
    } catch(e) {
      console.log(e);
    }
  }

  const addSectionMethod = async () => {
    var main;
    setSectionLoading(true);
    if (type <= 2) {
      main = [];
      for (var i of chosenItems) {
        main.push(
          type == 0 ? 
          brandsArr[i["index"]] : 
          type == 1 ? productsArr[i["index"]] : 
          bannersArr[i["index"]]
        )
      }
    }
    console.log(main);
    var finalArr = [];
    if (main != null) {
      finalArr = [];
      for (var data of main) {
        finalArr.push(data["_id"])
      }
    }
    var finalData = {
      "title": title,
      "type": type,
      "data": main,
      "axis": axis,
      "position": position
    };
    try {
      const resp = await addSection(finalData);
      console.log(resp);
      setSectionModal(false);
      setSectionLoading(false);
      message.success('Section added successfully');
    } catch(e) {
      console.log(e);
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <Name>{text}</Name>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: text => <Other>{typeToText[text]}</Other>,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: position => <Other>{position}</Other>,
      sorter: (a, b) => a.position - b.position,
      sortOrder: positionOrder == null ? null : positionOrder ? 'ascend' : 'descend',
      ellipsis: true
    },
  ]

  const sectionColumns = [
    {
      'title': '',
      dataIndex: 'index',
      key: 'img',
      width: 80,
      render: i => <Avatar src={STATIC_IMG_URL + (type == 0 ? brandsArr[i]["logo"] : type == 1 ? productsArr[i]["thumbnailImage"] : bannersArr[i]["imageURL"])} />
    },
    {
      'title': 'Title',
      dataIndex: 'index',
      key: 'title',
      render: i => <Name>{type == 0 ? brandsArr[i]["name"] : (type == 1 ? productsArr[i] : bannersArr[i])["title"]}</Name>
    },
    {
      'title': 'Position',
      dataIndex: 'position',
      key: 'position',
      width: 120,
      render: i => <Name>{i}</Name>,
      sorter: (a, b) => a.position - b.position,
      sortOrder: 'ascend',
    },
    {
      'title': '',
      'dataIndex': 'index',
      'key': 'action',
      width: 120,
      render: i => <a href onClick = {() => {
        const index = chosenItems.findIndex((val) => val["index"] == i); 
        const finalArr = [...chosenItems];
        finalArr.splice(index, 1);
        setChosenItems(finalArr)
      }}>Delete</a>
    }
  ]

  const checkBeforeModalClose = async () => {

  }

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} loading = {loading} onClick = {() => {setSectionData({}); setSectionModal(true)}}>
        Add Section
      </Button>
      <div style = {{ height: 15 }} />
      <Table
        loading = {loading}
        columns={columns}
        scroll={{ y: '65vh' }}
        onRow={(record, index) => ({
            onClick: () => editSectionModal(record, index)
        })}
        onChange = {(pagination, filters, sorter) => {
          console.log(positionOrder);
          if (positionOrder == null) {
            setPositionOrder(true)
          }
          else if (!positionOrder) {
            setPositionOrder(null);
          }
          else if (positionOrder) {
            setPositionOrder(false);
          }
        }}
        dataSource = {sectionsList}
      />
      <CustomModalStyle
        style={{ top: 20 }}
        visible={sectionModal}
        onOk={() => setSectionModal(false)}
        onCancel={() => setSectionModal(false)}
        afterClose = {() => {
          setSectionID();
          setTitle();
          setType();
          setChosenItems([]);
          setAxis();
          setPosition();
          setMaxDisplayCount();
        }}
        width = {650}
      >
        <ModalTitle>Add/Edit Section</ModalTitle>
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        <Select
          style={{ width: '100%' }}
          placeholder="Type"
          onChange={(e) => {console.log(e); setType(e)}}
          value={type}
        >
          {
            Object.keys(typeToText).map((k) => <Option key={k} value={k}>{typeToText[k]}</Option>)
          }
        </Select>
        {
          type != null && type != 3 &&
          <>
            <div style = {{ height: 15 }} />
            <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
          </>
        }
        {
          type == 1 &&
          <>
            <div style = {{ height: 15 }} />
            <Space>
              <Input value={maxDisplayCount} onChange={(e) => setMaxDisplayCount(e.target.value)} placeholder="Max Display Count" type = "number" min={4} />
              <Select
                style={{ width: 150 }}
                placeholder="Axis"
                onChange={(e) => setAxis(e)}
                value={axis}
              >
                {
                  ["Vertical", "Horizontal"].map((v, i) => <Option key={v} value={i}>{v}</Option>)
                }
              </Select>
            </Space>
          </>
        }
        <div style = {{ height: 15 }} />
        <Input value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Position" type="number" min="0" />
        {
          type != null && type != 3 &&
          <>
            <div style = {{ height: 20 }} />
            <Button type='primary' onClick = {() => setItemModal(true)}>Add Items</Button>
            <div style = {{ height: 15 }} />
            <Table dataSource={chosenItems} columns={sectionColumns} scroll={{ y: '150px' }} />
          </>
        }
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        <Button
          onClick = {() => sectionID ? editSectionMethod() : addSectionMethod()}
          loading = {sectionLoading}
        >
          {
            sectionID ? 'Save' : 'Add'
          }
        </Button>
      </CustomModalStyle>
      {
        <Modal
          visible = {itemModal}
          title={`Add ${typeToText[type]}`}
          centered
          onOk = {() => {
            const data = {
              "index": itemIndex,
              "position": Number(itemPosition)
            };
            const finalArr = [...chosenItems];
            console.log(finalArr);
            if (finalArr.length > 1) {
              let atIndexElem = finalArr.findIndex((val) => val["position"] == itemPosition)
              if (atIndexElem == -1) {
                console.log("No position found")
                atIndexElem = finalArr.findIndex((val) => val["position"] > itemPosition)
                console.log(atIndexElem);
                if (atIndexElem == -1) {
                  finalArr.push(data);
                } else {
                  console.log("Position: ", atIndexElem);
                  if (finalArr[atIndexElem]["position"] - itemPosition == 0)
                    for (let i = atIndexElem; i < finalArr.length; i++) {
                      finalArr[i]["position"]++;
                    }
                  finalArr.splice(atIndexElem, 0, data);
                }
              } else {
                console.log("Position found: ", atIndexElem)
                for (let i = atIndexElem; i < finalArr.length; i++) {
                  finalArr[i]["position"]++;
                }
                finalArr.splice(atIndexElem, 0, data);
              }
              // console.log()
            } else {
              finalArr.push(data);
            }
            setChosenItems(finalArr)
            setItemModal(false)
          }}
          onCancel = {() => setItemModal(false)}
        >
          <Select
            showSearch
            value = {itemIndex}
            style = {{ width: '100%' }}
            onChange = {(v) => setItemIndex(v)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              ((type == 0 ? brandsArr : (type == 1 ? productsArr : bannersArr))).map((v, i) => <Option key={v["_id"]} value={i}>{v["name"] ? v["name"] : v["title"]}</Option>)
            }
          </Select>
          <div style = {{ height: 15 }} />
          <Input value={itemPosition} onChange={(e) => setItemPosition(e.target.value)} placeholder="Position" type="number" min="0" />
        </Modal>
      }
    </>
  );
}