import React from 'react';

import { 
  Row,
  Col,
  Avatar,
  Table
} from 'antd';
import { 
  Other,
  StatText,
  StatTitle, 
  Title
} from './index.styles';
import { STATIC_IMG_URL } from '../../../../../../Constants';

export const DetailsTab = ({
  data
}) => {

  const {
    brand,
    pineLabsOrder
  } = data;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'price',
      key: 'price',
      render: price => <Title>Voucher(s) worth ₹{price}</Title>,
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'quantity',
      render: quantity => <Other>{quantity}</Other>,
    }
  ]

  return (
    <>
      <Row>
        <Col span={8} style = {{ paddingRight: 10 }}>
          <div
            style = {{
              width: '100%',
              borderRadius: 10,
              backgroundColor: '#fafafa',
              padding: 20
            }}
          >
            <Row justify="space-between">
              <Col>
                <StatTitle>Brand Details</StatTitle>
                <StatText>Let's know the brand</StatText>
              </Col>
              <Col>
                <Avatar size="large" src={STATIC_IMG_URL + brand["logo"]} />
              </Col>
            </Row>
            <div style = {{ height: 25 }} />
            <Title>{brand["name"]}</Title>
            <Other>Name</Other>
            <div style = {{ height: 15 }} />
            <Title>{brand["category"]}</Title>
            <Other>Category</Other>
            <div style = {{ height: 15 }} />
            <Title>{brand["discountPercent"]}%</Title>
            <Other>Discount</Other>
            <div style = {{ height: 15 }} />
            <Title>{brand["position"]}</Title>
            <Other>Position</Other>
          </div>
        </Col>
        <Col span={16} style = {{ paddingLeft: 10 }}>
          <div
            style = {{
              width: '100%',
              borderRadius: 10,
              backgroundColor: '#fafafa',
              padding: 20
            }}
          >
            <StatTitle>Order Details</StatTitle>
            <StatText>Let's check the order</StatText>
            <Table
              columns = {columns}
              scroll={{ y: 200 }}
              dataSource = {pineLabsOrder.products}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}