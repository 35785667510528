import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
	Modal,
	Input,
	message,
	Table
} from 'antd';
import {Context as DataContext} from '../../../../../api/dataProvider';
import { Name, Other, Title, ModalTitle, ModalTitleDesc } from './index.styles';


export function ClubsScreen() {

	const columns = [
		{
      title: 'Merchant ID',
      dataIndex: 'merchantId',
      key: 'merchantId',
      render: data => <Name>{data}</Name>,
    },
    {
      title: 'Name',
      dataIndex: 'clubName',
      key: 'clubName',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      render: data => <Name>{data}</Name>,
    },
		{
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: data => <Name>{data}</Name>,
    },
  ];

	const [clubModal, setClubModal] = useState(false);
	const [screenLoading, setScreenLoading] = useState(false);
	const [clubs, setClubs] = useState([]);
	
	const [clubName, setClubName] = useState();
	const [email, setEmail] = useState();
	const [userName, setUserName] = useState();
	const [location, setLocation] = useState();
	const [phoneNumber, setPhoneNumber] = useState();

	const [addClubLoading, setAddClubLoading] = useState(false)

	const {
    handleCreateClub,
		getAllClubs
  } = useContext(DataContext);

	useEffect(() => {
		handleFetchAllClubs();
	}, [])

	const handleFetchAllClubs = async () => {
		setScreenLoading(true);
		let data = await getAllClubs()
		setClubs(data);
		setScreenLoading(false);
	}

	const handleClubEmail = async () => {
		if (clubName == null) {
			message.error('Please add a club name.')
			return
		}
		if (email == null) {
			message.error('Please add club email.')
			return
		}
		if (userName == null) {
			message.error('Please add club userName.')
			return
		}
		if (location == null) {
			message.error('Please add club location.')
			return
		}
		if (phoneNumber == null) {
			message.error('Please add club phoneNumber.')
			return
		}
		setAddClubLoading(true);
		try {
			let resp = await handleCreateClub({
				clubName,
				email,
				userName,
				phone: phoneNumber,
				location
			})
			setAddClubLoading(false);
			message.success('Club added successfully!')
			window.location.reload();
			setClubModal(false);
		} catch (e) {
			setAddClubLoading(false);
			message.error(e.response.data.message)
			console.log(e);
		}
	}

	return <>
		<Button type="primary"
			onClick = {() => setClubModal(true)}
			loading = {screenLoading}
		>
			Create New Club
		</Button>
		<div style = {{ height: 20 }} />
		<Table loading = {screenLoading} dataSource={clubs} columns={columns} scroll={{ y: '70vh' }} />
		<Modal
			visible={clubModal}
			onOk={handleClubEmail}
			onCancel={() => setClubModal(false)}
			afterClose={() => {
				setClubName()
				setEmail()
			}}
			okText = "Add"
			width = {450}
			title = "Add Club"
			okButtonProps={{ loading: addClubLoading }}
		>
			<Input placeholder={'Club Name'} onChange={(e) => setClubName(e.target.value)} />
			<div style = {{ height: 20 }} />
			<Input placeholder={'Email'} onChange={(e) => setEmail(e.target.value)} />
			<div style = {{ height: 20 }} />
			<Input placeholder={'User Name'} onChange={(e) => setUserName(e.target.value)} />
			<div style = {{ height: 20 }} />
			<Input placeholder={'Phone'} onChange={(e) => setPhoneNumber(e.target.value)} />
			<div style = {{ height: 20 }} />
			<Input placeholder={'Location'} onChange={(e) => setLocation(e.target.value)} />
		</Modal>
	</>;
}