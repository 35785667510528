import React, { useContext, useEffect, useState } from 'react';
import {
	ModalTitle,
	ModalTitleDesc,
	Divider,
	MenuButton,
	FooterButton
} from './index.styles';
import {
	Button,
	Col,
	message,
	Row
} from 'antd';
import { Context as DataContext } from '../../../../../../api/dataProvider';
import { VoucherDetailsTab } from './details';
import { VoucherConstraintsTab } from './constraints';

export function CreateVoucherModal({
	categories,
	companies,
	products,
	brands,
	data,
	setModalVisibility
}) {

	const {
		createBuyzoneVoucher,
    saveBuyzoneVoucher,
	} = useContext(DataContext);

	const [selectedTab, setSelectedTab] = useState(0);
	const detailsRef = React.useRef();
	const constraintsRef = React.useRef();
	const [loader, setLoader] = useState(false);

	const [editModal, setEditModal] = useState(false);

	useEffect(() => {
		if (data != null)
			setEditModal(true);
	}, [])

	const createVoucher = async () => {
		const detailsData = await detailsRef.current.handleVoucherDetails();
		const constraintsData = await constraintsRef.current.handleConstraintDetails();
		detailsData["applyConstraints"] = constraintsData;
		try {
			let resp = await createBuyzoneVoucher(detailsData);
			const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'download.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
			setLoader(false);
		} catch (e) {
			console.log("Error")
			setLoader(false);
			let { response } = e;
			if (response != null) {
				let { code } = response.data;
				message.error(response.data.message)
				setModalVisibility(false);
			} else {
				console.error(e);
				message.error("Some error occurred.")
			}
		}
	}

	const saveEditedVoucher = async () => {
		const detailsData = await detailsRef.current.handleVoucherDetails();
		const constraintsData = await constraintsRef.current.handleConstraintDetails();
		detailsData["applyConstraints"] = constraintsData;
		detailsData["_id"] = data["_id"];
		try {
			let result = await saveBuyzoneVoucher(detailsData);
			message.success(result.message);
			setLoader(false);
		} catch (e) {
			console.log("Error")
			setLoader(false);
			let { response } = e;
			if (response != null) {
				let { code } = response.data;
				message.error(response.data.message)
				setModalVisibility(false);
			} else {
				console.error(e);
				message.error("Some error occurred.")
			}
		}
	}

	// const handleDelete = async () => {
	// 	let hide = message.loading('Deleting...');
	// 	try {
	// 		let result = await deleteVoucher({
	// 			"voucherID": data["_id"]
	// 		});
	// 		message.success(result.message);
	// 		hide();
	// 	} catch (e) {
	// 		console.log("Error")
	// 		hide();
	// 		let { response } = e;
	// 		if (response != null) {
	// 			let { code } = response.data;
	// 			message.error(response.data.message)
	// 		} else {
	// 			console.error(e);
	// 			message.error("Some error occurred.")
	// 		}
	// 	}
	// }

	return (
		<div>
			<Row align="middle" justify="space-between" style = {{ marginRight: 25 }}>
				<ModalTitle>{data != null ? "Edit Voucher" : "Create Voucher"}</ModalTitle>
				{
					data != null &&
					<Row>
						{
							data["user"] != null &&
							<Col>
								<ModalTitle>Phone Number</ModalTitle>
								<div style = {{ height: 5 }} />
								<ModalTitleDesc>{data["user"]["phone"]}</ModalTitleDesc>
							</Col>
						}
						<div style = {{ width: 50 }} />
						<Col>
							<ModalTitle>Voucher Number</ModalTitle>
							<div style = {{ height: 5 }} />
							<ModalTitleDesc>{data["voucherNumber"]}</ModalTitleDesc>
						</Col>
						<div style = {{ width: 50 }} />
						{
							data["user"] == null &&
							<Col>
								<ModalTitle>Voucher Pin</ModalTitle>
								<div style = {{ height: 5 }} />
								<ModalTitleDesc>{data["voucherPin"]}</ModalTitleDesc>
							</Col>
						}
					</Row>
				}
			</Row>
			<div style={{ height: 25 }} />
			<Divider />
			<div style={{ height: 45 }} />
			<Row justify="space-between" style={{
				height: 400,
				maxHeight: 400,
				overflow: 'auto'
			}}>
				<Col span={5} style={{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
					<MenuButton selected={selectedTab === 0} onClick={() => setSelectedTab(0)}>Details</MenuButton>
					<div style={{ height: 15 }} />
					<MenuButton selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>Constraints</MenuButton>
				</Col>
				<Col span={18}>
					<div style={{ display: selectedTab == 0 ? 'block' : 'none' }}>
						<VoucherDetailsTab data={data} ref={detailsRef} />
					</div>
					<div style={{ display: selectedTab == 1 ? 'block' : 'none' }}>
						<VoucherConstraintsTab data={data} ref={constraintsRef} brands={brands} companies={companies} categories={categories} products={products} />
					</div>
				</Col>
			</Row>
			<div style={{ height: 25 }} />
			<Divider />
			<div style={{ height: 25 }} />
			<Row justify="end">
				{/* {
					editModal &&
					<>
						<Button color="secondary" onClick={() => handleDelete()}>Delete</Button>
						<div style={{ width: 15 }} />
					</>
				} */}
				<Button type='primary' loading={loader} onClick={() => editModal ? saveEditedVoucher() : createVoucher()}>{editModal ? 'Save' : 'Create'}</Button>
			</Row>
		</div>
	)

}