import React, { useContext, useEffect, useState } from 'react';
import { Context as DataContext } from "../../../../../api/dataProvider";

import { message } from 'antd';

import { 
  Name,
  Other,
  // CustomModalStyle,
  // ModalTitle,
  // ModalTitleDesc,
  // Divider,
  // MenuButton
} from './index.styles';

import moment from 'moment';

function Banners() {

	const {
    fetchProductOrders
	} = useContext(DataContext);
	
	const [bannerList, setBannerList] = useState([]);

	useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const data = await fetchProductOrders();
      setBannerList(data);
    } catch (e) {
      console.log(e);
      message.error('Some error occurred');
    }
	}

	const columns = [
    {
			title: 'Order ID',
			dataIndex: 'orderID',
			key: 'orderID',
			render: text => <Name>{text}</Name>,
    },
    {
			title: 'Customer Name',
			dataIndex: 'customerDetails',
			key: 'phone',
			render: customerDetails => <Other>{customerDetails.name}</Other>,
    },
    {
			title: 'Amount',
			dataIndex: 'paymentDetails',
			key: 'amount',
			render: paymentDetails => <Other>{paymentDetails.totalAmount}</Other>,
    },
  ];

	return (
		<>
			
		</>
	);

}

export default Banners;