import { Button, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Context as DataContext } from '../../../../../api/dataProvider';
import { digitalStoreCodes } from '../../../../../statusCodes';
import { CreateOrder } from './components/createOrder';
import {
	Table,
	Row,
	Col,
	Space,
	Popconfirm
} from 'antd'
import { 
	CustomModalStyle,
	ModalTitle,
	ModalTitleDesc,
	Name,
	Other,
	Divider,
	MenuButton
} from './index.styles';
import moment from 'moment';
import ProductsTab from './components/products';
import { PaymentDetailsTab } from './components/paymentDetails';
import renderHTML from 'react-render-html';

export function DigitalStoreOrders() {
	
	const {
		getEvents,
		getProducts,
		createDigitalStoreOrder,
		fetchDigitalStoreOrders,
		cancelDigitalStoreOrder,
    deliverDigitalStoreOrder,
		getInvoice
	} = useContext(DataContext);

	const [events, setEvents] = useState([]);
	const [createOrderModal, setCreateOrderModal] = useState(false);
	const [products, setProducts] = useState([]);
	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	
	const [createModalLoader, setCreateModalLoader] = useState(false);
	const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [orderIndex, setOrderIndex] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
	var [htmlInvoice, setHTMLInvoice] = useState("");
  const [invoice, setInvoiceOpen] = useState(false);

	const columns = [
    {
        title: 'Order ID',
        dataIndex: 'orderID',
        key: 'orderID',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'phone',
        render: customer => <Name>{customer.firstName} {customer.lastName}</Name>,
    },
    {
      title: 'Society',
      dataIndex: 'event',
      key: 'event',
      render: event => <Name>{event == null ? '' : event["society"]["name"]}</Name>,
    },
    {
        title: 'Amount',
        dataIndex: 'paymentDetails',
        key: 'amount',
        render: paymentDetails => <Name>{paymentDetails.totalAmount}</Name>,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <Name>{status == null ? '' : digitalStoreCodes[status["code"]]}</Name>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => <Name>{moment(date).format("DD MMM yyyy, hh:mm A")}</Name>,
      width: 250
    },
    {
      title: 'COD',
      dataIndex: 'paymentDetails',
      key: 'createdAt',
      render: paymentDetails => <Other>{paymentDetails["cod"] ? "Yes": "No"}</Other>,
    },
  ];

	useEffect(() => {
		getInitialData();
	}, [])
	
	const getInitialData = async () => {
		try {
			setLoading(true);
			const events = await getEvents();
			const products = await getProducts();
			const digitalStoreOrders = await fetchDigitalStoreOrders();
			setEvents(events);
			setProducts(products);
			setOrders(digitalStoreOrders);
			setLoading(false);
		} catch (e) {
			console.log(e);
			if (e.data && e.data.message) {
				message.error(e.data.message)
				if (e.data.code != null) {
					message.error("Code: ", e.data.code)
				}
			} else {
				message.error('Some error occurred');
			}
		}
	}

	const createOrder = async (data) => {
		// const hide = message.loading('Creating..', 0);
		setCreateModalLoader(true);
		try {
			await createDigitalStoreOrder(data);
			setCreateModalLoader(false);
			// hide();
			message.success('Order created successfuly!');
			setCreateOrderModal(false);
			setLoading(true);
			const digitalStoreOrders = await fetchDigitalStoreOrders();
			setOrders(digitalStoreOrders);
			setLoading(false);
		} catch (e) {
			setCreateModalLoader(false);
			// hide();
			console.log(e);
			if (e.data && e.data.message) {
				message.error(e.data.message)
			} else {
				message.error('Some error occurred');
			}
		}
	}

	const handleRowClick = async (data, index) => {
    // console.log(data);
    setOrderData(data);
    setOrderIndex(index);
    setOrderDetailsModal(true);
  }

	const cancelOrder = async () => {
		const hide = message.loading('Cancelling this order..', 0);
		try {
      const data = {
        "orderID": orderData["_id"],
      };
      const resp = await cancelDigitalStoreOrder(data);
      orderData["status"] = {
        "code": 204,
        "timestamp": new Date()
      };
      hide();
      message.success(resp.message);
      setOrderData(orderData);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
	}

	const markAsDelivered = async () => {
		const hide = message.loading('Marking as delivered..', 0);
		try {
      const data = {
        "orderID": orderData["_id"],
      };
      const resp = await deliverDigitalStoreOrder(data);
      orderData["status"] = {
        "code": 206,
        "timestamp": new Date()
      };
      hide();
      message.success(resp.message);
      setOrderData(orderData);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
	}

	const downloadInvoice = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getInvoice(orderData["paymentDetails"]["zohoInvoiceID"]);
      hide();
      setHTMLInvoice(resp);
      setInvoiceOpen(true);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

	return (
		<>
			<Button loading = {loading} type='primary' onClick={() => setCreateOrderModal(true)}>Create Order</Button>
      <div style = {{ height: 15 }} />
			<Table
        columns={columns}
				loading = {loading}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={orders}
      />
			{
				orderData &&
				<CustomModalStyle
          centered
          visible={orderDetailsModal}
          onOk={() => setOrderDetailsModal(false)}
          onCancel={() => setOrderDetailsModal(false)}
          afterClose = {() => setSelectedTab(0)}
          width = {1300}
        >
					<Row align="middle" justify="space-between">
            <div style = {{ marginLeft: 25 }}>
              <ModalTitle>{orderData.orderID}</ModalTitle>
            </div>
            <Row>
              <Col>
                <ModalTitle>Customer Name</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.firstName} {orderData.customer.lastName}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Phone Number</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.phone}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Order Status</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{digitalStoreCodes[orderData.status["code"]]}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
							<Col>
                <ModalTitle>COD</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.paymentDetails.cod ? "Yes" : "No"}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
							<Col>
                <ModalTitle>Pickup</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.pickup ? "Yes" : "No"}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
            </Row>
          </Row>
					<div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 45 }} />
					<Row justify="space-between" style = {{
            minHeight: 400
          }}>
						<Col span = {5} style = {{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
              <MenuButton selected={selectedTab === 0} onClick = {() => setSelectedTab(0)}>Products</MenuButton>
              <div style = {{ height: 15 }} />
              {/* <MenuButton selected={selectedTab === 1} onClick = {() => setSelectedTab(1)}>Details</MenuButton> */}
              {/* <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 2} onClick = {() => setSelectedTab(2)}>Customer Details</MenuButton> */}
              {/* <div style = {{ height: 15 }} /> */}
              <MenuButton selected={selectedTab === 2} onClick = {() => setSelectedTab(2)}>Payment Details</MenuButton>
            </Col>
            <Col span = {18}>
              {
                selectedTab == 0 &&
                <ProductsTab orderID = {orderData["_id"]} products = {orderData["products"]} />
              }
              {
                selectedTab == 2 &&
                <PaymentDetailsTab data = {orderData} />
              }
            </Col>
					</Row>
					{
            (orderData["status"]["code"] != 200 || (orderData["paymentDetails"]["zohoInvoiceID"] != null && orderData["paymentDetails"]["zohoInvoiceID"] != "")) &&
            <>
              <div style = {{ height: 25 }} />
              <Divider />
              <div style = {{ height: 25 }} />
              <Row justify="end">
                <Space>
									<Button type="secondary" onClick={() => downloadInvoice()}>Download Invoice</Button>
									<Popconfirm
										title="Are you sure you want to cancel this order?"
										onConfirm = {() => cancelOrder()}
									>
										<Button type="danger">Cancel Order</Button>
									</Popconfirm>
                  {
                    orderData["status"]["code"] != 206 &&
                    <Popconfirm
                      title="Are you sure you want to mark this order as delivered?"
                      onConfirm = {() => markAsDelivered()}
                    >
                      <Button type="primary">Mark as delivered</Button>
                    </Popconfirm>
                  }
                </Space>
              </Row>
            </>
          }
				</CustomModalStyle>
			}
			<CustomModalStyle
				centered
				visible={invoice}
				onOk={() => setInvoiceOpen(false)}
				onCancel={() => setInvoiceOpen(false)}
				width = {650}
			>
				{
					renderHTML(htmlInvoice)
				}
			</CustomModalStyle>
			<CustomModalStyle
				centered
				visible={createOrderModal}
				onOk={() => setCreateOrderModal(false)}
				onCancel={() => setCreateOrderModal(false)}
				width = {950}
			>
				<CreateOrder events = {events} productsList = {products} createOrder = {createOrder} loader = {createModalLoader} />
			</CustomModalStyle>
		</>
	)

}