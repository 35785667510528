import axios from 'axios'
import Cookie from 'js-cookie'

const instance=axios.create({
	// baseURL:'http://localhost:8081/',
	baseURL:'https://stagingapi.buyzone.co.in/'
})

instance.interceptors.request.use(
	async (config) => {
		const token = Cookie.get('token')
		config.headers['Content-Type']='application/json';
		if (token) {
			config.headers.token=`${token}`
		}
		return config;
	},
	(err)=>{
		return Promise.reject(err)
	}
)

export default instance;