import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const row = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LinksContainer = styled.div`
    ${row};
`;

export const Navbar = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 10px 1.3rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    z-index: 1500;
    ${row};
`;
export const H1 = styled.div`
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #333e49;
`;

export const NavLink = styled(Link)`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #333e49;
    margin-left: 7.5px;
    opacity: ${(props) => (props.selected ? 1 : 0.7)};
    &:hover {
        opacity: 1;
        color: #333e49;
    }
`;