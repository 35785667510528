import styled, { css } from "styled-components";

export const LeftComponent = styled.div`
    height: 100vh;
    background-color: #6C63FF;
`;

export const BuyzoneText = styled.div`
    margin-left: 60px;
    padding-top: 30px;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
`;

export const IconBackground = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 11px;
    background-color: rgba(256, 256, 256, .4);
    display: flex;
    justify-content: center;
    margin-left: 60px;
`;

export const TitleDiv = styled.div`
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-left: 60px;
    margin-top: 30px;
    margin-right: 50px;
`;

export const DescriptionDiv = styled.div`
    
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-left: 60px;
    margin-top: 20px;
    margin-right: 50px;
`;

export const CarouselCover = styled.div`
    .slick-dots li button {
        display: none;
    }
`;

export const ArrowBackground = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 7px;
    background-color: rgba(256, 256, 256, .4);
    display: flex;
    justify-content: center;
    cursor: pointer;
    .anticon {
        display: flex;
    }
    .anticon svg {
        align-self: center;
        color: #fff
    }
`;

export const PageTitle = styled.div`
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const InputTitle = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const LoginButton = styled.div`
    width: 179px;
    height: 50px;
    border-radius: 8px;
    background-color: #323441;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #ffffff;
    cursor: pointer;
`;

export const RightComponent = styled.div`
    width: 585px;
    margin-left: 130px;
    .email {
        border-radius: 8px;
        background-color: #f4f4f4;
        width: 586px;
        height: 45px;
    }
    .ant-input-password {
        border-radius: 8px;
        background-color: #f4f4f4;
        width: 586px;
        height: 45px;
    }
    .ant-input {
        background-color: #f4f4f4;
    }
`;

export const ForgotPasswordText = styled.div`
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: right;
    color: #323441;
    cursor: pointer;
`;