import styled from 'styled-components';

export const Name = styled.div`
    opacity: 0.8;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;

export const Other = styled.div`
    font-size: 13px;
    opacity: 0.5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
`;