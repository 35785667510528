import React, { useEffect, useContext, useState } from 'react';
import { Context as DataContext } from "../../../../../api/dataProvider";
import Papaparse from 'papaparse';

import ReactFileReader from 'react-file-reader';

import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { 
  message,
  Button,
  Select,
  Table,
  Row,
  Popconfirm,
  Modal,
  Input,
  Popover,
  Space,
  Tag,
  Avatar,
  Col,
  Checkbox
} from 'antd';

import { 
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  FooterButton,
  Name,
	Other,
	Title
} from './index.styles';
import { errorCodes, adminProductOrderStatusCodes } from '../../../../../statusCodes';
import renderHTML from 'react-render-html';

import moment from 'moment';

const { Option } = Select;
const { Search } = Input;

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export const AdminProductOrders = () => {

  const [adminOrdersList, setAdminOrdersList] = useState([]);
  const [adminClientsList, setAdminClientsList] = useState([]);
  const [adminOrdersLoading, setAdminOrdersLoading] = useState([]);

  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [orderData, setOrderData] = useState();

  const [selectedClient, setSelectedClient] = useState();
  const [createInvoiceBool, setCreateInvoiceBool] = useState(false);

  const [clientName, setClientName] = useState();
  const [gst, setGST] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [receiverName, setReceiverName] = useState();

  const [description, setDescription] = useState();

  const [billingAddress, setBillingAddress] = useState();
  const [billingPin, setBillingPin] = useState();
  const [billingState, setBillingState] = useState();
  const [billingCity, setBillingCity] = useState();

  const [sameAsBillingAddressBool, setSameAsBillingAddressBool] = useState(false);
  const [shippingAddress, setShippingAddress] = useState();
  const [shippingPin, setShippingPin] = useState();
  const [shippingState, setShippingState] = useState();
  const [shippingCity, setShippingCity] = useState();

  const [productsList, setProductsList] = useState([]);
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [addOrderLoading, setAddOrderLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const [finalOrdersArr, setFinalOrdersArr] = useState([]);

  var [htmlInvoice, setHTMLInvoice] = useState("");
  const [invoice, setInvoiceOpen] = useState(false);

  const [products, setProducts] = useState([]);
	const [addProduct, setAddProduct] = useState(false);

  const [productID, setProductID] = useState();
	const [customizationID, setCustomizationID] = useState();
	const [customizationIndex, setCustomizationIndex] = useState();
	const [productData, setProductData] = useState();
	const [quantity, setQuantity] = useState();
	const [price, setPrice] = useState();
	const [sku, setSKU] = useState();

	const [totalAmount, setTotalAmount] = useState(0);
  const [shipmentCharges, setShipmentCharges] = useState();

  const [orderID, setOrderID] = useState();
  const [addOTPModal, setAddOTPModal] = useState(false)
  const [otp, setOTP] = useState();

  const {
    getProducts,
    getAllAdminProductOrders,
    createAdminProductOrder,
    approveAdminProductOrder,
    verifyAddressPin,
    downloadAdminProductOrderExcel,
    getInvoice,
    downloadAllAdminProductOrder,
    cancelAdminProductOrder
  } = useContext(DataContext);

  useEffect(() => {
		getTotalAmount();
	}, [products])

	const getTotalAmount = async () => {
		if (products.length > 0) {
			var totalAmount = 0;
			for (let product of products) {
				totalAmount += (product["price"] * product["quantity"]);
				setTotalAmount(totalAmount);
			}
		} else {
			// console.log("No products");
			setTotalAmount(0);
		}
	}

  const viewModalTableColumns = [
    {
			title: '',
			dataIndex: 'details',
			key: 'image',
			render: index => index == null ? <div /> : <Popover content={PopoverProductDetails(index)}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${index["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
			width: 60
    },
    {
			title: 'Name',
			dataIndex: 'details',
			key: 'name',
			render: index => index == null ? <Name>{index}</Name> : <Name>{index["title"]}</Name>,
    },
    {
			title: 'Quantity',
			dataIndex: 'details',
			key: 'quantity',
			render: (_, record, __) => <Other>{record.quantity}</Other>,
    },
		{
			title: 'Price (₹)',
			dataIndex: 'operation',
			key: 'price',
			render: (_, record, __) => <Other>{record.price}</Other>,
    },
  ];

  const addModalTableColumns = [
    {
			title: '',
			dataIndex: 'index',
			key: 'image',
			render: index => <Popover content={PopoverProductDetails(productsList[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${productsList[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
			width: 60
    },
    {
			title: 'Name',
			dataIndex: 'index',
			key: 'name',
			render: index => <Name>{productsList[index]["title"]}</Name>,
    },
    {
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
			title: 'Price (₹)',
			dataIndex: 'price',
			key: 'price',
			render: quantity => <Other>{quantity}</Other>,
    },
		{
      title: '',
      dataIndex: 'operation',
			width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            Remove
          </a>
        );
      },
    }
  ];

  const removeItemFromBill = (index) => {
		let newArr = [...products];
		newArr.splice(index, 1);
		setProducts(newArr);
	}

  const adminVoucherOrderColumns = [
    {
      title: 'ID',
      dataIndex: 'orderID',
      key: 'orderID',
    },
    {
      title: 'Total Amount (₹)',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: client => (
        <>
          {client && client["name"]}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <>
          {adminProductOrderStatusCodes[status]}
        </>
      )
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   dataIndex: '_id',
    //   render: (_, __,) => (
    //     <>
    //       <Button type="link" onClick={() => handleRowAction(adminOrdersList.find((val) => val["_id"] == id))}>{adminOrdersList.find((val) => val["_id"] == id)["status"] == 403 ? 'Download' : 'Check Status'}</Button>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    getAllProducts();
    getAllOrders();
  }, []);

  const getAllProducts = async () => {
    try {
      const productsList = await getProducts();
      setProductsList(productsList);
    } catch (e) {
      console.error(e);
      message.error("Some error occurred while fetching all brands.")
    }
  }

  const getAllOrders = async () => {
    try {
      let { orders, adminClients } = await getAllAdminProductOrders();
      setAdminOrdersList(orders);
      setAdminClientsList(adminClients);
    } catch (e) {
      console.error(e);
      message.error('Some error occurred while fetching Admin Orders');
    }
  }

  const checkInput = () => {
    if (products.length == 0) {
      message.error('No Items added.')
      return false;
    }
    if (selectedClient == null) {
      message.error('Select a client first');
      return false;
    }
    if (paymentMethod == null) {
      message.error('Please select a payment method');
      return false;
    }
    if (selectedClient == '') {
      if (clientName == null || clientName == '') {
        message.error('Please add a valid client name');
        return false;
      }
      if (gst == null || gst == '' || gst.length != 15) {
        message.error('Please add a valid GST');
        return false;
      }
      if (billingAddress == null || billingAddress == '') {
        message.error('Please enter Address Line for Billing Address');
        return false;
      }
      if (billingPin == null || billingPin == '') {
        message.error('Please enter valid billing pin');
        return false;
      }
      if (billingState == null || billingState == '') {
        message.error('Please verify the pin code for Billing Address');
        return false;
      }
      if (!sameAsBillingAddressBool) {
        if (shippingAddress == null || shippingAddress == '') {
          message.error('Please enter Address Line for Shipping Address');
          return false;
        }
        if (shippingPin == null || shippingPin == '') {
          message.error('Please enter valid pin for Shipping Address');
          return false;
        }
        if (shippingState == null || shippingState == '') {
          message.error('Please verify the pin code for Shipping Address');
          return false;
        }
      }
    }
    return true;
  }

  const handleCreate = async () => {
    if (!checkInput())
      return;
    setAddOrderLoading(true);
    try {
      let finalBillingAddress = {
        addressLine: billingAddress,
        pinCode: billingPin,
        state: billingState,
        city: billingCity
      }
      let finalShippingAddress = sameAsBillingAddressBool ? finalBillingAddress : {
        addressLine: shippingAddress,
        pinCode: shippingPin,
        state: shippingState,
        city: shippingCity
      }
      let resp = await createAdminProductOrder({
        totalAmount,
        products,
        selectedClientID: selectedClient,
        billingAddress: finalBillingAddress,
        shippingAddress: finalShippingAddress,
        clientName,
        gst,
        paymentMethod,
        receiverName,
        createInvoiceBool,
        description,
        shipmentCharges
      });
      setAddOrderLoading(false);
      if (resp.status == 200) {
        setOrderID(resp.data["orderID"])
        message.success(resp.data["message"]);
        setAddOTPModal(true);
      }
      else
        console.log(resp.data);
    } catch (e) {
      setAddOrderLoading(false);
      console.log(e.response);
      message.error(e.response.data.message)
    }
  }

  const handleOTP = async () => {
    if (!otp) {
      message.error('Please enter OTP before proceeding');
      return;
    }
    setAddOTPModal(false);
    setAddOrderLoading(true);
    try {
      let resp = await approveAdminProductOrder({
        orderID,
        otp
      });
      setAddOrderLoading(false);
      if (resp.status == 200) {
        message.success(resp.data["message"]);
      }
      else
        console.log(resp.data);
    } catch (e) {
      let { response}  = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
        if (code == errorCodes.ADMIN_VOUCHERS.OTP_EXPIRED || code == errorCodes.ADMIN_VOUCHERS.WRONG_OTP) {
          setAddOTPModal(true);
        }
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  const getSKUProduct = async () => {
		const product = productsList.find((val) => val["sku"] == Number(sku));
		if (product != null) {
			setProductID(product["_id"])
			setProductData(product)
		}
		else {
			message.info('No matching SKU found')
			setProductID();
		}
	}

  const verifyPin = async (type) => {
    const pin = type == 'Billing' ? billingPin : shippingPin;
    if (pin == null || pin.length == 0)
      message.error('Please enter some Pin Code first');
    try {
      let data = await verifyAddressPin(pin);
      if (type == "Billing") {
        setBillingCity(data["city"]);
        setBillingState(data["state"])
      } else {
        setShippingCity(data["city"]);
        setShippingState(data["state"])
      }
    } catch (e) {
      let { response }  = e;
      if (response != null) {
        if (response.status == 404)
          message.error("Invalid Pin Code. Please recheck and enter")
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  const downloadExcel = async () => {
    try {
      let resp = await downloadAdminProductOrderExcel(orderData["orderID"]);
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', orderData["orderID"] + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  const handleCancel = async () => {
    let hide = message.loading('Cancelling');
    try {
      let resp = await cancelAdminProductOrder({
        orderID: orderData["orderID"]
      });
      hide();
      if (resp.status == 200) {
        message.success(resp.data["message"]);
      }
      else
        console.log(resp.data);
    } catch (e) {
      hide();
      let { response}  = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
      } else {
        message.error('An unknown error occurred');
        console.error(e);
      }
    }
  }

  const viewInvoice = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getInvoice(orderData["paymentDetails"]["invoiceID"]);
      hide();
      setHTMLInvoice(resp);
      setInvoiceOpen(true);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const downloadAllOrders = async () => {
    try {
      let resp = await downloadAllAdminProductOrder();
      const downloadUrl = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', "Admin-Product-Orders-" + moment().format("DD-MM-YYYY") + '.xlsx'); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
      } else {
        console.error(e);
      }
    }
  }

  return (
    <>
      <Space>
        <Button type="primary" icon={<PlusOutlined />} onClick = {() => setAddOrderModal(true)} loading = {addOrderLoading} >
          Add Order
        </Button>
        <Button type="secondary" icon={<DownloadOutlined />} onClick = {() => downloadAllOrders()} >
          Download Orders
        </Button>
      </Space>
      <div style = {{ height: 15 }} />
      <Table 
        dataSource={adminOrdersList} 
        columns={adminVoucherOrderColumns} 
        onRow={(record, index) => ({
            onClick: () => {console.log(record); setOrderData(record); setViewOrderModal(true)}
        })}
      />
      {
        orderData != null &&
        <CustomModalStyle
          centered
          visible={viewOrderModal}
          onOk={() => setViewOrderModal(false)}
          onCancel={() => setViewOrderModal(false)}
          afterClose = {() => {
            setOrderData();
          }}
          width = {1300}
        >
          <Row align="middle" justify="space-between">
            <ModalTitle style = {{ marginLeft: 25 }}>{orderData.orderID}</ModalTitle>
            <Row>
              <Col>
                <ModalTitle>Admin Name</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.admin.firstName} {orderData.admin.lastName}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Admin Email</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.admin.email}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Total</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>₹{orderData.totalAmount}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Order Status</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{adminProductOrderStatusCodes[orderData.status]}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Time</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{moment(orderData.createdAt).format("DD MMM yyyy, hh:mm A")}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
            </Row>
          </Row>
          <div style = {{ height: 25 }} />
          <Divider />
          {
            orderData.client != null &&
            <>
              <div style = {{ height: 25 }} />
              <Space
                align = "start"
                size = "large"
              >
                <div>
                  <ModalTitle>Client Details</ModalTitle>
                  <div style = {{ height: 5 }} />
                  <ModalTitleDesc>{orderData.client.name}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.gst}</ModalTitleDesc>
                </div>
                <div>
                  <ModalTitle>Billing Address</ModalTitle>
                  <div style = {{ height: 5 }} />
                  <ModalTitleDesc>{orderData.client.billingAddress.addressLine}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.billingAddress.city}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.billingAddress.state}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.billingAddress.pinCode}</ModalTitleDesc>
                </div>
                <div>
                  <ModalTitle>Shipping Address</ModalTitle>
                  <div style = {{ height: 5 }} />
                  <ModalTitleDesc>{orderData.client.shippingAddress.addressLine}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.shippingAddress.city}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.shippingAddress.state}</ModalTitleDesc>
                  <ModalTitleDesc>{orderData.client.shippingAddress.pinCode}</ModalTitleDesc>
                </div>
                <div>
                  <ModalTitle>Payment Method</ModalTitle>
                  <div style = {{ height: 5 }} />
                  <ModalTitleDesc>{orderData.paymentDetails.method}</ModalTitleDesc>
                  {orderData.paymentDetails.method == 'Cash' && <ModalTitleDesc>Received by {orderData.paymentDetails.receiverName}</ModalTitleDesc>}
                </div>
                {
                  orderData.description != null &&
                  <div>
                    <ModalTitle>Description</ModalTitle>
                    <div style = {{ height: 5 }} />
                    <ModalTitleDesc>{orderData.description}</ModalTitleDesc>
                  </div>
                }
                {
                  orderData.paymentDetails.shipmentCharges != null &&
                  <div>
                    <ModalTitle>Shipment Charges</ModalTitle>
                    <div style = {{ height: 5 }} />
                    <ModalTitleDesc>₹{orderData.paymentDetails.shipmentCharges}</ModalTitleDesc>
                  </div>
                }
              </Space>
            </>
          }
          <div style = {{ height: 25 }} />
          <Table scroll={{ y: '30vh' }} pagination = {false} dataSource = {orderData.products} columns = {viewModalTableColumns}  />
          <div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 25 }} />
          <Row justify="end">
            {
              orderData["status"] == 500 &&
              <>
                <Button color="secondary" onClick = {() => handleCancel()}>Cancel Order</Button>
                <div style = {{ width: 15 }} />
              </>
            }
            {
              orderData["status"] == 503 && orderData["paymentDetails"]["invoiceID"] != null &&
              <>
                <Button color="secondary" onClick = {() => viewInvoice()}>View Invoice</Button>
                <div style = {{ width: 15 }} />
              </>
            }
            <Button color="primary" onClick = {() => downloadExcel()}>Download Products Excel</Button>
          </Row>
        </CustomModalStyle>
      }
      <CustomModalStyle
        centered
        visible={addOrderModal}
        onOk={() => setAddOrderModal(false)}
        onCancel={() => setAddOrderModal(false)}
        afterClose = {() => {
          setProducts([]);
        }}
        width = {1100}
      >
        <ModalTitle>Add Admin Product Order</ModalTitle>
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        {
          selectedClient == null || selectedClient != '' ?
          <Space>
            <Select
              style={{ width: 350 }}
              placeholder="Select Client"
              onSelect={(val) => setSelectedClient(val)}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <a
                      style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                      onClick={() => setSelectedClient('')}
                    >
                      <PlusOutlined /> Add New Client
                    </a>
                  </div>
                </div>
              )}
            >
              {adminClientsList.map(item => (
                <Option key={item["_id"]} value={item["_id"]}>{item["name"]}</Option>
              ))}
            </Select>
            <Select
              onChange={(e) => setPaymentMethod(e)}
              value={paymentMethod}
              placeholder="Payment Method"
              style = {{
                width: 250
              }}
            >
              <Option value="Cash" key="Cash">Cash</Option>
              <Option value="Credit" key="Credit">Credit</Option>
              <Option value="Online" key="Online">Online</Option>
              <Option value="QR Code" key="QR Code">QR Code</Option>
              <Option value="Bank" key="Bank">Bank</Option>
            </Select>
          </Space>
          :
          selectedClient == '' &&
          <Row
            align="start"
            justify="space-between"
          >
            <div>
              <ModalTitle>Client Details</ModalTitle>
              <div style = {{ height: 15 }} />
              <Input
                onChange={(e) => setClientName(e.target.value)}
                value={clientName}
                placeholder="Client Name"
                width={250}
              />
              <div style = {{ height: 5 }} />
              <Input
                onChange={(e) => setGST(e.target.value)}
                value={gst}
                placeholder="GST Number"
              />
              <div style = {{ height: 5 }} />
              <Select
                onChange={(e) => setPaymentMethod(e)}
                value={paymentMethod}
                placeholder="Payment Method"
                style = {{
                  width: 250
                }}
              >
                <Option value="Cash" key="Cash">Cash</Option>
                <Option value="Credit" key="Credit">Credit</Option>
                <Option value="Online" key="Online">Online</Option>
                <Option value="QR Code" key="QR Code">QR Code</Option>
                <Option value="Bank" key="Bank">Bank</Option>
              </Select>
              {
                paymentMethod == "Cash" &&
                <>
                  <div style = {{ height: 5 }} />
                  <Input
                    onChange={(e) => setReceiverName(e.target.value)}
                    value={receiverName}
                    placeholder="Receiver Name"
                  />
                </>
              }
            </div>
            <div>
              <ModalTitle>Billing Address</ModalTitle>
              <div style = {{ height: 15 }} />
              <Input
                onChange={(e) => setBillingAddress(e.target.value)}
                value={billingAddress}
                placeholder="Billing Address"
                style = {{
                  width: 350
                }}
              />
              <div style = {{ height: 5 }} />
              <Search
                onChange={(e) => setBillingPin(e.target.value)}
                value={billingPin}
                placeholder="Pin Code"
                style = {{
                  width: 350
                }}
                type="number"
                enterButton="Verify"
                onSearch={() => verifyPin('Billing')}
              />
              <div style = {{ height: 5 }} />
              <Space>
                <Input
                  onChange={(e) => setBillingState(e.target.value)}
                  value={billingState}
                  placeholder="State"
                  disabled
                />
                <Input
                  onChange={(e) => setBillingCity(e.target.value)}
                  value={billingCity}
                  placeholder="City"
                  disabled
                />
              </Space>
            </div>
            <div>
              <Space>
                <ModalTitle>Shipping Address</ModalTitle>
                <Checkbox onChange={(e) => setSameAsBillingAddressBool(e.target.checked)}>Same as billing address</Checkbox>
              </Space>
              <div style = {{ height: 10 }} />
              <Input
                onChange={(e) => setShippingAddress(e.target.value)}
                value={sameAsBillingAddressBool ? billingAddress : shippingAddress}
                disabled={sameAsBillingAddressBool}
                placeholder="Shipping Address"
                style = {{
                  width: 350
                }}
              />
              <div style = {{ height: 5 }} />
              <Search
                onChange={(e) => setShippingPin(e.target.value)}
                value={sameAsBillingAddressBool ? billingPin : shippingPin}
                disabled={sameAsBillingAddressBool}
                placeholder="Pin Code"
                style = {{
                  width: 350
                }}
                type="number"
                enterButton="Verify"
                onSearch={() => verifyPin('Shipping')}
              />
              <div style = {{ height: 5 }} />
              <Space>
                <Input
                  onChange={(e) => setShippingState(e.target.value)}
                  value={sameAsBillingAddressBool ? billingState : shippingState}
                  placeholder="State"
                  disabled
                />
                <Input
                  onChange={(e) => setShippingCity(e.target.value)}
                  value={sameAsBillingAddressBool ? billingCity : shippingCity}
                  placeholder="City"
                  disabled
                />
              </Space>
            </div>
          </Row>
        }
        <div style = {{ height: 25 }} />
        <Input
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          placeholder="Description"
        />
        <div style = {{ height: 25 }} />
        <Input
          placeholder="Shipment Charge (Optional)"
          type = "number"
          onChange={(e) => setShipmentCharges(e.target.value)}
          value={shipmentCharges}
        />
        <div style = {{ height: 25 }} />
        <Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
        <div style = {{ height: 25 }} />
        <Table scroll={{ y: '30vh' }} pagination = {false} dataSource = {products} columns = {addModalTableColumns}  />
        <div style = {{ height: 25 }} />
        <Divider />
        <div style = {{ height: 25 }} />
        <Row justify="space-between">
          <Name>
            Total: ₹{totalAmount}
          </Name>
          <Space>
            <Checkbox onChange={(e) => setCreateInvoiceBool(e.target.checked)}>Create Invoice</Checkbox>
            <Popconfirm
              title="Are you sure to create this order?"
              onConfirm={() => handleCreate()}
              okText="Create"
              placement="topRight"
              okButtonProps={{ loading: addOrderLoading }}
            >
              <Button color="primary" loading = {addOrderLoading}>Create</Button>
            </Popconfirm>
          </Space>
        </Row>
      </CustomModalStyle>
      <Modal
        centered
        visible={addOTPModal}
        onOk={() => handleOTP()}
        onCancel={() => setAddOTPModal(false)}
        title="Verification"
      >
        <p>Please enter the OTP to approve the order and process it.</p>
        <Input placeholder="OTP" value = {otp} onChange={(e) => setOTP(e.target.value)} allowClear />
      </Modal>
      <Modal
				visible={addProduct}
				onOk={() => {
					if (productID == null) {
						message.error('Please select a product first.')
						return
					}
					if (quantity == null) {
						message.error('Please add a quantity first.')
						return
					}
					if ((price != null && price != '') && price <= 0) {
						message.error('Please add a valid price.')
						return
					}
					if (productData["customizations"].length != 0 && customizationID == null) {
						message.error('Please select a customization.')
						return
					}
					const newProducts = [...products];
					const index = productsList.findIndex((val) => val["_id"] == productID);
					let customizationObject = productData["customizations"].find((val) => val["size"].toString() == customizationID.toString());
					if (customizationObject != null)
						customizationObject = customizationObject["compositeProduct"];
					newProducts.push({
						"details": productID,
						"quantity": quantity,
						"price": price == null ? customizationObject != null ? (customizationObject["discountPrice"] || productsList[index]["originalPrice"]) : (productsList[index]["discountPrice"] == null ? productsList[index]["originalPrice"] : productsList[index]["discountPrice"]) : price,
						"index": index,
						"customization": customizationID
					})
					setProducts(newProducts);
					setAddProduct(false);
					setProductID();
					setQuantity();
					setPrice();
					message.success('Product added successfully');
				}}
				onCancel={() => setAddProduct(false)}
				okText = "Add"
				width = {950}
			>
				<div>
					<ModalTitle>Select Product</ModalTitle>
				</div>
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={20}>
						<Input placeholder="SKU" type="number" value = {sku} onChange={(e) => setSKU(e.target.value)} />
					</Col>
					<Col span={3}>
						<Button type='primary' onClick={() => getSKUProduct()}>Search</Button>
					</Col>
				</Row>
				<div style = {{ height: 20 }} />
				<Divider />
				<div style = {{ height: 20 }} />
				<Row justify="space-between">
					<Col span={7}>
						<Select
							showSearch
							style = {{
								width: '100%'
							}}
							placeholder="Select Product"
							optionFilterProp="children"
							value={productID}
							onChange={(val) => {setProductID(val); setProductData(productsList.find((v) => v["_id"] == val))}}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								productsList.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
							}
						</Select>
					</Col>
					<Col span={7}>
						<Input placeholder="Quantity" type="number" value = {quantity} onChange={(e) => setQuantity(e.target.value)} />
					</Col>
					<Col span={7}>
						<Input placeholder="Price (Optional)" type="number" value = {price} onChange={(e) => setPrice(e.target.value)} />
					</Col>
					{
						productData && productData["customizations"].length > 0 &&
						<Select
              showSearch
              style = {{
                width: '100%',
								marginTop: 15
              }}
              placeholder="Select Customization"
              optionFilterProp="children"
              onChange={(val) => setCustomizationID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                productData.customizations.map((v) => <Option value={v["_id"]} key={v["_id"]}>{
                  (productData.sizes.length > 0 ? (productData.sizes.find((val) => val["_id"] == v["size"])["value"] + ' ') : '') + 
                  (productData.colors.length > 0 ? productData.colors.find((val) => val["_id"] == v["color"])["name"] : '')
                }</Option>)
              }
            </Select>
					}
				</Row>
			</Modal>
      <CustomModalStyle
				centered
				visible={invoice}
				onOk={() => setInvoiceOpen(false)}
				onCancel={() => setInvoiceOpen(false)}
				width = {650}
			>
				{
					renderHTML(htmlInvoice)
				}
			</CustomModalStyle>
    </>
  )
}