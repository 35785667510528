import React, { useEffect } from 'react';
import { Provider as DataProvider, Context as DataContext } from "./api/dataProvider";
import AuthContext from "./contexts/authContext";
import Screens from './Screens';
import * as firebase from 'firebase';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import Api from "./api/index";

const firebaseConfig = {
  apiKey: "AIzaSyBbWgVj-59aTlSfawocFRKwI8-q7do_G9s",
  authDomain: "buyzone-b4c1b.firebaseapp.com",
  databaseURL: "https://buyzone-b4c1b.firebaseio.com",
  projectId: "buyzone-b4c1b",
  storageBucket: "buyzone-b4c1b.appspot.com",
  messagingSenderId: "740614728733",
  appId: "1:740614728733:web:9fbdbb3168c87fea302af4",
  measurementId: "G-BQZGMZ39HK"
};

function App() {

  const [role, setRole] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    initialCheck();
  }, [])

  const initialCheck = async () => {
    let token = Cookies.get('token');
    if (token != null && token != '') {
      try {
        let data = await Api.get(
          'auth/login/admin'
        );
        data = data.data;
        setRole(data.role);
        setLoading(true);
      } catch (e) {
        console.log("Error: ", e);
      }
    } else {
      setLoading(true);
    }
  }

  return (
    <DataProvider>
      <AuthContext.Provider value = {{ role, setRole }}>
        {
          loading ?
          <Screens />:
          <div style = {{ 
            width: '100%', 
            height: '100vh', 
            background: '#8171f6', 
          }}>
            <div style = {{
              justifyContent: 'center', 
              display: 'flex',
              alignContent: 'center',
              fontSize: 60, 
              fontWeight: 'bold', 
              color: 'white' 
            }}>
              B U Y Z O N E
            </div>
          </div>
        }
      </AuthContext.Provider>
    </DataProvider>
  );
}

export default App;
