import React from 'react';

import {
  StatTitle,
  StatText,
  Title,
  Other
} from './index.styles';

import {
  Row,
  Col
} from 'antd';

export const BillingDetailsTab = ({
  data
}) => {

  let {
    customer,
    pineLabsOrder,
    paymentDetails
  } = data;

  return (
    <div
      style = {{
        width: '100%',
        borderRadius: 10,
        backgroundColor: '#fafafa',
        padding: 20
      }}
    >
      <StatTitle>Billing Details</StatTitle>
      <StatText>Let's know the billing details</StatText>
      <div style = {{ height: 25 }} />
      <Row>
        <Col span={12}>
          <Title>{customer["firstName"]} {customer["lastName"]}</Title>
          <Other>Name</Other>
        </Col>
        <Col span={12}>
          <Title>{customer["email"]}</Title>
          <Other>Email</Other>
        </Col>
      </Row>
      <div style = {{ height: 15 }} />
      <Row>
        <Col span={12}>
          <Title>{pineLabsOrder["refno"]}</Title>
          <Other>Ref No.</Other>
        </Col>
        <Col span={12}>
          <Title>{customer["phone"]}</Title>
          <Other>Phone</Other>
        </Col>
      </Row>
      <div style = {{ height: 15 }} />
      <Row>
        <Col span={12}>
          <Title>{paymentDetails["gateway"]}</Title>
          <Other>Gateway</Other>
        </Col>
        <Col span={12}>
          <Title>{paymentDetails["method"]}</Title>
          <Other>Method</Other>
        </Col>
      </Row>
    </div>
  )
}