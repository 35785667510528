import React, { useContext, useEffect, useState } from 'react';

import { Button, Avatar, Table, Input, Space } from 'antd';
import { 
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  MenuButton,
} from './index.styles';
import { CreateProductModalData } from './components/createProduct';
import { Context as DataContext } from '../../../../../api/dataProvider';
import { STATIC_IMG_URL } from '../../../../../Constants';

const { Search } = Input;

export function Products() {

	const [createProductModal, setCreateProductModal] = useState(false);
	const [productData, setProductData] = useState();
	const [companies, setCompanies] = useState([]);
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
	const [loader, setLoader] = useState(false);

	const {
		getCompanies,
    getCategories,
		getProducts,
	} = useContext(DataContext);

	useEffect(() => {
		getInitialData();
	}, []);

	const getInitialData = async () => {
		const companies = await getCompanies();
		const categories = await getCategories();
		const products = await getProducts();
		setProducts(products);
    setFilteredList(products);
		console.log(products);
		setCompanies(companies);
		setCategories(categories);
	}

	const handleNewProduct = async () => {
		setProductData({
			"title": "",
      "originalPrice": "",
      "description": "",
      "company": "",
      "details": [],
      "instructions": [],
      "sizes": [],
      "colors": [],
      "category": "",
      "images": [],
      "hsnCode": "",
      "gstPercent": "",
      "sku": "",
      "thumbnailImage": "",
      "shipmentType": "",
      "otherDetails": {},
      "charges": {},
      "overlay": {}
		});
		setCreateProductModal(true);
	}

	const initaiteEditEvent = (data) => {
		setProductData(data);
		setCreateProductModal(true);
	}

	const eventColumns = [
    {
      'title': '',
      dataIndex: 'thumbnailImage',
      key: 'img',
      width: 80,
      render: i => <Avatar src={STATIC_IMG_URL + i} />
    },
    {
      'title': 'Title',
      dataIndex: 'title',
      key: 'title',
      render: i => <Name>{i}</Name>
    },
    {
      'title': 'Original Price',
      dataIndex: 'originalPrice',
      key: 'society',
      render: i => <Name>{i}</Name>,
    },
    {
      'title': 'Discount Price',
      dataIndex: 'discountPrice',
      key: 'inventory',
      render: i => <Name>{i}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        return ( 
          <a onClick={() => initaiteEditEvent(record)}>
            Edit
          </a>
        );
      },
    }
  ];

	return (
		<>
      <Space>
        <Search placeholder="Search..." allowClear onSearch={(v) => {
          if (v == "")
            setFilteredList(products)
          else
            setFilteredList(products.filter((val) => val["title"].toString().toLowerCase().includes(v.toLowerCase())));
        }} style={{ width: 300 }} enterButton />
        <Button type="primary"
          onClick = {() => handleNewProduct()}
        >
          Create Product
        </Button>
      </Space>
			<div style = {{ height: 15 }} />
			<Table loading = {loader} dataSource={filteredList} columns={eventColumns} scroll={{ y: '70vh' }} />
			{
				productData != null &&
				<CustomModalStyle
					centered
					visible={createProductModal}
					onOk={() => setCreateProductModal(false)}
					onCancel={() => setCreateProductModal(false)}
					width = {1100}
          style = {{
            height: 700
          }}
				>
					<CreateProductModalData data = {productData} companies = {companies} categories = {categories} />
				</CustomModalStyle>
			}
		</>
	)
}