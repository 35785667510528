import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  Name,
  Title
} from './index.styles';
import {
	Input,
	Select,
  Space,
  Checkbox,
  Button,
  Table,
  Popover,
  Tag,
  Avatar,
  message,
  Modal
} from 'antd';

const { Option } = Select;

const PopoverProductDetails = ({ thumbnailImage, title, images, sizes, colors }) => {
  return (
    <>
      <Space>
        <img height={200} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${thumbnailImage}`} />
        <Space>
          {
            images.map((v) => <img height={100} src={`https://buyzone.s3.ap-south-1.amazonaws.com/images/${v}`} />)
          }
        </Space>
      </Space>
      <div style = {{ height: 15 }}/>
      <Title style = {{ fontWeight: 'bold', fontSize: 16 }}>{title}</Title>
      <div style = {{ height: 15 }}/>
      {
        sizes != null && sizes.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Sizes</Title>
          <div style = {{ height: 10 }}/>
          {
            sizes.map((v) => <Tag color="purple">{v["value"]}</Tag>)
          }
        </>
      }
      <div style = {{ height: 15 }}/>
      {
        colors != null && colors.length > 0 &&
        <>
          <Title style = {{ fontSize: 14 }}>Available Colours</Title>
          <div style = {{ height: 10 }}/>
          {
            colors.map((v) => <div
            style = {{
                width: 30,
                height: 30,
                border: "1px black solid",
                backgroundColor: "#" + v["value"],
                borderRadius: "50%",
                float: "left",
                marginRight: 5,
            }}
          />)
          }
        </>
      }
    </>
  );
}

export const VoucherConstraintsTab = forwardRef(({
  categories,
  companies,
  products,
  brands,
  data
}, ref) => {

  const [voucherType, setVoucherType] = useState();
  const [minAmount, setMinAmount] = useState();
  const [maxUsableAmount, setMaxUsableAmount] = useState();

  const [brandType, setBrandType] = useState();
  const [brandList, setBrandList] = useState([]);

  const [addBrand, setAddBrand] = useState(false);
  const [brandID, setBrandID] = useState();

  const [productType, setProductType] = useState();
  const [company, setCompany] = useState();
  const [category, setCategory] = useState();
  const [productList, setProductList] = useState([]);

  const [addProduct, setAddProduct] = useState(false);
  const [productID, setProductID] = useState();

  const voucherColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'image',
      render: index => <Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${brands[index]["logo"]}`} size="large" style = {{ cursor: 'pointer' }} />,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'index',
      key: 'name',
      render: index => <Name>{brands[index]["name"]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeBrandFromList(index)}>
            Remove
          </a>
        );
      },
    }
  ];

  const productColumns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'image',
      render: index => <Popover content={PopoverProductDetails(products[index])}><Avatar src = {`https://buyzone.s3.ap-south-1.amazonaws.com/images/${products[index]["thumbnailImage"]}`} size="large" style = {{ cursor: 'pointer' }} /></Popover>,
      width: 60
    },
    {
      title: 'Name',
      dataIndex: 'index',
      key: 'name',
      render: index => <Name>{products[index]["title"]}</Name>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, __, index) => {
        return ( 
          <a onClick={() => removeItemFromBill(index)}>
            Remove
          </a>
        );
      },
    }
  ];

  useEffect(() => {
    if (data != null) {
      let applyData = data["applyConstraints"];
      setVoucherType(applyData["voucherType"]);
      setMinAmount(applyData["minAmount"]);
      if (applyData["productData"] != null) {
        setProductType(applyData["productData"]["productType"]);
        setCompany(applyData["productData"]["company"]);
        setCategory(applyData["productData"]["category"]);
        setProductList(applyData["productData"]["productList"].map((val) => ({
            "details": val,
            "index": products.findIndex((v) => v["_id"] == val)
          })
        ))
      }
      if (applyData["brandData"] != null) {
        console.log(applyData["brandData"]);
        setBrandType(applyData["brandData"]["brandType"]);
        setBrandList(applyData["brandData"]["brandList"].map(val => ({
            "details": val,
            "index": brands.findIndex((v) => v["_id"] == val)
          })
        ))
      }
      setMaxUsableAmount(applyData["maxUsableAmount"]);
    }
  }, []);

  const removeBrandFromList = (index) => {
		let newArr = [...brandList];
		newArr.splice(index, 1);
		setBrandList(newArr);
	}

  const removeItemFromBill = (index) => {
		let newArr = [...productList];
		newArr.splice(index, 1);
		setProductList(newArr);
	}

  useImperativeHandle(ref, () => ({
		handleConstraintDetails() {
			return new Promise ((resolve, reject) => {
				const finalData = {
					voucherType,
          minAmount,
          productData: {
            productType,
            company,
            category,
            productList: productList.map(v => v["details"])
          },
          brandData: {
            brandType,
            brandList: brandList.map(v => v["details"])
          },
          maxUsableAmount,
				}
				resolve(finalData)
			})
		}
	}))

  return (
    <>
      <Space>
        <Select
          style={{ width: 150 }}
          placeholder="Benefit Type"
          optionFilterProp="children"
          value={voucherType}
          onChange={(e) => setVoucherType(e)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option key={0} value={0}>All</Option>
          <Option key={1} value={1}>E-Vouchers</Option>
          <Option key={2} value={2}>Products</Option>
          <Option key={3} value={3}>Digital Stores</Option>
        </Select>
        <Input 
          placeholder="Min. Order Amount(Optional)"
          value = {minAmount}
          onChange={(e) => setMinAmount(e.target.value)} 
          maxLength = {50} 
          allowClear 
        />
        <Input 
          placeholder="Max Usable Amt (Optional)" 
          value = {maxUsableAmount} 
          onChange={(e) => setMaxUsableAmount(e.target.value)} 
          maxLength = {50} 
          allowClear />
      </Space>
      <div style = {{ height: 15 }} />
      {
        voucherType == 1 &&
        <>
          <Space>
            <Select
              placeholder = "E-Voucher Selection"
              onSelect = {(v) => setBrandType(v)}
              style = {{ width: 200 }}
              value = {brandType}
            >
              <Option key="I3" value={0}>All</Option>
              <Option key="I0" value={1}>Custom</Option>
            </Select>
          </Space>
          {
            brandType == 1 &&
            <>
              <div style = {{ height: 15 }} />
              <Button type='primary' onClick={() => {console.log("Here"); setAddBrand(true);}}>Add Brands</Button>
              <div style = {{ height: 20 }} />
              <Table dataSource={brandList} columns={voucherColumns} scroll={{ y: '150px' }} />
            </>
          }
          <Modal
            visible={addBrand}
            onOk={() => {
              if (brandID == null) {
                message.error('Please select a brand first.')
                return;
              }
              const newBrands = [...brandList];
              const index = brands.findIndex(val => val["_id"] == brandID)
              newBrands.push({
                "details": brandID,
                index
              });
              setBrandList(newBrands);
              setAddBrand(false);
              setBrandID();
              message.success('Brand added successfully!');
            }}
            onCancel={() => setBrandType(false)}
            okText = "Add"
            width = {450}
            title = "Add Brand"
          >
            <div style = {{ height: 20 }} />
            <Select
              showSearch
              style = {{
                width: '100%'
              }}
              placeholder="Select Brand"
              optionFilterProp="children"
              value={brandID}
              onChange={(val) => setBrandID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                brands.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["name"]}</Option>)
              }
            </Select>
          </Modal>
        </>
      }
      {
        voucherType == 2 &&
        <>
          <Space>
            <Select
              placeholder = "Products Selection"
              onSelect = {(v) => setProductType(v)}
              style = {{ width: 200 }}
              value = {productType}
            >
              <Option key="I3" value={0}>All</Option>
              <Option key="I0" value={1}>Company</Option>
              <Option key="I1" value={2}>Category</Option>
              <Option key="I2" value={3}>Custom</Option>
            </Select>
            {
              productType == 1 &&
              <Select
                showSearch
                placeholder="Select Company"
                optionFilterProp="children"
                onChange={(e) => setCompany(e)}
                value={company}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  companies.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
                }
              </Select>
            }
            {
              productType == 2 &&
              <Select
                showSearch
                placeholder="Select Category"
                optionFilterProp="children"
                value={category}
                onChange={(e) => setCategory(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  categories.map((v) => <Option key={v["_id"]} value={v["_id"]}>{v["name"]}</Option>)
                }
              </Select>
            }
          </Space>
          {
            productType == 3 &&
            <>
              <div style = {{ height: 15 }} />
              <Button type='primary' onClick={() => setAddProduct(true)}>Add Items</Button>
              <div style = {{ height: 20 }} />
              <Table dataSource={productList} columns={productColumns} scroll={{ y: '150px' }} />
            </>
          }
          <Modal
            visible={addProduct}
            onOk={() => {
              if (productID == null) {
                message.error('Please select a product first.')
                return
              }
              const newProducts = [...productList];
              const index = products.findIndex((val) => val["_id"] == productID);
              newProducts.push({
                "details": productID,
                "index": index,
              })
              setProductList(newProducts);
              setAddProduct(false);
              setProductID();
              message.success('Product added successfully');
            }}
            onCancel={() => setAddProduct(false)}
            okText = "Add"
            width = {450}
            title = "Add Product"
          >
            <div style = {{ height: 20 }} />
            <Select
              showSearch
              style = {{
                width: '100%'
              }}
              placeholder="Select Product"
              optionFilterProp="children"
              value={productID}
              onChange={(val) => setProductID(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                products.map((v) => <Option value={v["_id"]} key={v["_id"]}>{v["title"]}</Option>)
              }
            </Select>
          </Modal>
        </>
      }
    </>
  );

});