import React, { useContext, useEffect, useState } from 'react';

import { 
  message, 
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Select,
  Input
} from 'antd';

import { 
  Name,
  Other,
  CustomModalStyle,
  ModalTitle,
  ModalTitleDesc,
  Divider,
  MenuButton,
  SelectSubTitle
} from './index.styles';

import { Context as DataContext } from "../../../../../api/dataProvider";

import moment from 'moment';
import { productStatusCodes } from '../../../../../statusCodes';
import DetailsTab from './components/details';
import ProductsTab from './components/products';
import { PaymentDetailsTab } from './components/paymentDetails';

import { CreateOrder } from './components/createOrder';

import { jsPDF } from "jspdf";

import renderHTML from 'react-render-html';

import Iframe from 'react-iframe';

const doc = new jsPDF();
const { Option } = Select;

function ProductsOrder() {

  const {
    getProducts,
    fetchProductOrders,
    createAPackage,
    shipAPackage,
    markProductOrderAsDelivered,
    returnAPackage,
    getInvoice,
    getManifest,
    getProductOrderBatch,
    createProductOrder,
    createProductOrderInvoice,
    createProductOrderPickrr
  } = useContext(DataContext);

  const [createOrderModal, setCreateOrderModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [createModalLoader, setCreateModalLoader] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [orderIndex, setOrderIndex] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [invoice, setInvoiceOpen] = useState(false);
  const [pickrrSlip, setPickrrSlipOpen] = useState(false);
  const [journey, setJourney] = useState(false);
  var [htmlInvoice, setHTMLInvoice] = useState("");
  var [pickrrManifest, setPickrrManifest] = useState("");

  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [modeType, setModeType] = useState();

  const [packModal, setPackModal] = useState(false);
  const [batchDataLoading, setBatchDataLoading] = useState(false);
  const [productBatch, setProductBatch] = useState();
  const [selectedBatch, setSelectedBatch] = useState([]);


  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      const data = await fetchProductOrders();
			const products = await getProducts();
      setOrderList(data);
      setProducts(products);
    } catch (e) {
      console.log(e);
      message.error('Some error occurred');
    }
  }

  const columns = [
    {
        title: 'Order ID',
        dataIndex: 'orderID',
        key: 'orderID',
        render: text => <Name>{text}</Name>,
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'phone',
        render: customer => <Name>{customer.firstName} {customer.lastName}</Name>,
    },
    {
        title: 'Amount',
        dataIndex: 'paymentDetails',
        key: 'amount',
        render: paymentDetails => <Name>{paymentDetails.totalAmount}</Name>,
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      key: 'status',
      render: status => <Name>{productStatusCodes[status[status.length - 1].code]}</Name>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => <Name>{moment(date).format("DD MMM yyyy, hh:mm A")}</Name>,
      width: 250
    },
    {
      title: 'COD',
      dataIndex: 'paymentDetails',
      key: 'createdAt',
      render: paymentDetails => <Other>{paymentDetails["cod"] ? "Yes": "No"}</Other>,
    },
  ];

  const createOrder = async (data) => {
    console.log(data);
		// const hide = message.loading('Creating..', 0);
		setCreateModalLoader(true);
		try {
			await createProductOrder(data);
			setCreateModalLoader(false);
			// hide();
			message.success('Order created successfuly!');
			setCreateOrderModal(false);
			setLoading(true);
			const orders = await fetchProductOrders();
			setOrderList(orders);
			setLoading(false);
		} catch (e) {
			setCreateModalLoader(false);
			// hide();
			console.log(e);
			if (e.data && e.data.message) {
				message.error(e.data.message)
			} else {
				message.error('Some error occurred');
			}
		}
	}

  const handleRowClick = async (data, index) => {
    setOrderData(data);
    setOrderIndex(index);
    setOrderDetailsModal(true);
  }

  const setShipments = async (id, shipments) => {
    const newOrderList = [...orderList];
    const index = newOrderList.findIndex((val) => val["_id"].toString() == id.toString());
    console.log("index: ", index);
    const product = JSON.parse(JSON.stringify(newOrderList[index]));
    product["shipments"] = shipments;
    newOrderList[index] = product
    setOrderList(newOrderList);
  }

  const createPackage = async () => {
    if (length == null || width == null || height == null || weight == null || modeType == null) {
      message.error('Please add all dimensions of the package');
      return;
    }
    if (selectedBatch.length != orderData.products.length) {
      message.error('Please select batches for all products');
      return;
    }
    const hide = message.loading('Creating a package..', 0);
    try {
      const data = {
        "orderID": orderData["orderID"],
        "batches": selectedBatch,
        modeType,
        length,
        width,
        height,
        weight
      };
      const resp = await createAPackage(data);
      orderData["packed"] = resp.data;
      orderData["status"].push({
        "code": 205,
        "timestamp": new Date()
      })
      hide();
      setPackModal(false);
      message.success(resp.message);
      setOrderData(orderData);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const initiatePack = async () => {
    const hide = message.loading('Please wait... Fetching batches...', 0);
    try {
      let data = await getProductOrderBatch({ "orderID": orderData["orderID"] });
      setProductBatch(data);
      setPackModal(true);
      hide()
    } catch (e) {
      hide()
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const shipPackage = async (index) => {
    const hide = message.loading('Shipping this package..', 0);
    try {
      const data = {
        "orderID": orderData["orderID"],
      };
      const resp = await shipAPackage(data);
      orderData["shipped"] = resp.data;
      orderData["status"].push({
        "code": 206,
        "timestamp": new Date()
      })
      hide();
      message.success(resp.message);
      setOrderData(orderData);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const markAsDelivered = async (index) => {
    const hide = message.loading('Marking as delivered...', 0);
    try {
      const data = {
        "orderID": orderData["orderID"],
      };
      const resp = await markProductOrderAsDelivered(data);
      orderData["status"].push({
        "code": 207,
        "timestamp": new Date()
      })
      hide();
      message.success(resp.message);
      setOrderData(orderData);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const downloadInvoice = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getInvoice(orderData["shipmentDetails"]["zohoInvoiceID"]);
      hide();
      setHTMLInvoice(resp);
      setInvoiceOpen(true);
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const downloadShipmentLabel = async () => {
    const hide = message.loading('Fetching..', 0);
    try {
      const resp = await getManifest(orderData["orderID"]);
      hide();
      window. open(resp, '_blank', 'noopener,noreferrer')
    } catch (e) {
      console.log(e.response.data)
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message || response.data.error)
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const createInvoice = async () => {
    const hide = message.loading('Creating Invoice...', 0);
    try {
      const resp = await createProductOrderInvoice(orderData["orderID"]);
      let { invoiceID } = resp
      hide();
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      newOrderData["shipmentDetails"]["zohoInvoiceID"] = resp["invoiceID"];
      setOrderData(newOrderData);
      message.success(resp["message"]);
      getOrders()
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  const createPickrr = async () => {
    const hide = message.loading('Creating Pickrr Order...', 0);
    try {
      const resp = await createProductOrderPickrr(orderData["orderID"]);
      let { data } = resp
      hide();
      let newOrderData = JSON.parse(JSON.stringify(orderData));
      newOrderData["shipmentDetails"]["pickrr"] = data;
      setOrderData(newOrderData);
      message.success(resp["message"]);
      getOrders()
    } catch (e) {
      hide();
      let { response } = e;
      if (response != null) {
        let { code } = response.data;
        message.error(response.data.message);
        console.log(response.data);
      } else {
        console.error(e);
      }
    }
  }

  return (
    <>
      <Button loading = {loading} type='primary' onClick={() => setCreateOrderModal(true)}>Create Order</Button>
      <div style = {{ height: 15 }} />
      <Table
        columns={columns}
        scroll={{ y: '70vh' }}
        onRow={(record, index) => ({
            onClick: () => {handleRowClick(record, index)}
        })}
        dataSource={orderList}
      />
      {
        orderData &&
        <CustomModalStyle
          centered
          visible={orderDetailsModal}
          onOk={() => setOrderDetailsModal(false)}
          onCancel={() => setOrderDetailsModal(false)}
          afterClose = {() => setSelectedTab(0)}
          width = {1300}
        >
          <Row align="middle" justify="space-between">
            <div style = {{ marginLeft: 25 }}>
              <ModalTitle>{orderData.orderID}</ModalTitle>
            </div>
            <Row>
              <Col>
                <ModalTitle>Customer Name</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.firstName} {orderData.customer.lastName}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Phone Number</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{orderData.customer.phone}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
              {
                orderData["shipmentDetails"]["shyplite"]["shipmentID"] != null && orderData["shipmentDetails"]["shyplite"]["shipmentID"] != '' &&
                <Col>
                  <ModalTitle>Shyplite Shipment ID</ModalTitle>
                  <div style = {{ height: 5 }} />
                  <ModalTitleDesc>{orderData.shipmentDetails["shyplite"]["shipmentID"]}</ModalTitleDesc>
                </Col>
              }
              {
                orderData.paymentDetails.buyzoneVoucher &&
                <>
                  <div style = {{ width: 50 }} />
                  <Col>
                    <ModalTitle>Buyzone Voucher Used</ModalTitle>
                    <div style = {{ height: 5 }} />
                    <ModalTitleDesc>{orderData.paymentDetails["buyzoneVoucher"]["voucherNumber"]} (₹{orderData.paymentDetails["buyzoneVoucherDiscount"]})</ModalTitleDesc>
                  </Col>
                </>
              }
              {
                orderData.paymentDetails.voucher &&
                <>
                  <div style = {{ width: 50 }} />
                  <Col>
                    <ModalTitle>Voucher Used</ModalTitle>
                    <div style = {{ height: 5 }} />
                    <ModalTitleDesc>{orderData.paymentDetails["voucher"]["code"]} (₹{orderData.paymentDetails["voucherDiscount"]})</ModalTitleDesc>
                  </Col>
                </>
              }
              <div style = {{ width: 50 }} />
              <Col>
                <ModalTitle>Order Status</ModalTitle>
                <div style = {{ height: 5 }} />
                <ModalTitleDesc>{productStatusCodes[orderData.status[orderData.status.length - 1]["code"]]}</ModalTitleDesc>
              </Col>
              <div style = {{ width: 50 }} />
            </Row>
          </Row>
          <div style = {{ height: 25 }} />
          <Divider />
          <div style = {{ height: 45 }} />
          <Row justify="space-between" style = {{
            minHeight: 400
          }}>
            <Col span = {5} style = {{ borderRight: 'solid 1px #e1e1e1', paddingRight: 30 }}>
              <MenuButton selected={selectedTab === 0} onClick = {() => setSelectedTab(0)}>Products</MenuButton>
              <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 1} onClick = {() => setSelectedTab(1)}>Details</MenuButton>
              {/* <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 2} onClick = {() => setSelectedTab(2)}>Customer Details</MenuButton> */}
              <div style = {{ height: 15 }} />
              <MenuButton selected={selectedTab === 2} onClick = {() => setSelectedTab(2)}>Payment Details</MenuButton>
            </Col>
            <Col span = {18}>
              {
                selectedTab == 0 &&
                <ProductsTab orderID = {orderData["_id"]} setShipments = {setShipments} shipped = {orderData["shipped"]} packed = {orderData["packed"]} shipmentDetails = {orderData["shipmentDetails"]} products = {orderData["products"]} />
              }
              {
                selectedTab == 1 &&
                <DetailsTab details = {orderData} />
              }
              {
                selectedTab == 2 &&
                <PaymentDetailsTab data = {orderData} />
              }
            </Col>
          </Row>
          {
            orderData["status"].find((val) => val["code"] == 208) != null &&
            <>
              <div style = {{ height: 25 }} />
              <Divider />
              <div style = {{ height: 25 }} />
              <Row justify="end">
                <Space>
                  {
                    !orderData["shipmentDetails"]["zohoInvoiceID"] == null || orderData["shipmentDetails"]["zohoInvoiceID"] == '' &&
                    <Button type="secondary" onClick = {() => createInvoice()}>Create Invoice</Button>
                  }
                  {/* {
                    orderData["shipmentDetails"]["zohoInvoiceID"] != null && orderData["shipmentDetails"]["zohoInvoiceID"] != '' && (orderData["shipmentDetails"]["pickrr"]["trackingID"] == null || orderData["shipmentDetails"]["pickrr"]["trackingID"] == '') &&
                    <Button type="primary" onClick = {() => createPickrr()}>Create Pickrr Order</Button>
                  } */}
                  {
                    orderData["shipmentDetails"]["shyplite"]["shipmentID"] != null && !orderData["packed"]["status"] &&
                    <Button type="primary" onClick = {() => initiatePack()}>Pack Order</Button>
                  }
                  {
                    orderData["packed"]["status"] && !orderData["shipped"]["status"] &&
                    <Button type="primary" onClick = {() => shipPackage()}>Ship Order</Button>
                  }
                  {
                    orderData["shipped"]["status"] &&
                    <Button type="primary" onClick = {() => markAsDelivered()}>Mark As Delivered</Button>
                  }
                  {
                    orderData["shipmentDetails"]["zohoInvoiceID"] != null && orderData["shipmentDetails"]["zohoInvoiceID"] != '' &&
                    <Button type="secondary" onClick = {() => downloadInvoice()}>Invoice</Button>
                  }
                  {
                    orderData["shipmentDetails"]["shyplite"]["shipmentID"] != null && orderData["shipmentDetails"]["shyplite"]["shipmentID"] != '' && !orderData["shipped"]["status"] &&
                    <Button type="secondary" onClick = {() => downloadShipmentLabel()}>Shyplite Receipt</Button>
                  }
                </Space>
              </Row>
            </>
          }
          {
            orderData["status"].find((val) => val["code"] == 201) != null &&
            <>
              <div style = {{ height: 25 }} />
              <Divider />
              <div style = {{ height: 25 }} />
              <Row justify="end">
                <Space>
                  {
                    !orderData["packed"]["status"] &&
                    <Button type="primary" onClick = {() => initiatePack()}>Pack Order</Button>
                  }
                  {
                    orderData["packed"]["status"] && !orderData["shipped"]["status"] &&
                    <Button type="primary" onClick = {() => shipPackage()}>Ship Order</Button>
                  }
                  {
                    orderData["shipped"]["status"] &&
                    <Button type="primary" onClick = {() => markAsDelivered()}>Mark As Delivered</Button>
                  }
                  {
                    orderData["shipmentDetails"]["zohoInvoiceID"] != null && orderData["shipmentDetails"]["zohoInvoiceID"] != '' &&
                    <Button type="secondary" onClick = {() => downloadInvoice()}>Invoice</Button>
                  }
                  {
                    orderData["shipmentDetails"]["shyplite"]["shipmentID"] != null && orderData["shipmentDetails"]["shyplite"]["shipmentID"] != '' && !orderData["shipped"]["status"] &&
                    <Button type="secondary" onClick = {() => downloadShipmentLabel()}>Shyplite Receipt</Button>
                  }
                </Space>
              </Row>
            </>
          }
          <CustomModalStyle
            centered
            visible={invoice}
            onOk={() => setInvoiceOpen(false)}
            onCancel={() => setInvoiceOpen(false)}
            width = {650}
          >
            {
              renderHTML(htmlInvoice)
            }
          </CustomModalStyle>
          <CustomModalStyle
            centered
            visible={pickrrSlip}
            onOk={() => setPickrrSlipOpen(false)}
            onCancel={() => setPickrrSlipOpen(false)}
            width = {650}
          >
            <Iframe
              url = {pickrrManifest}
              width = "100%"
              height = "750px"
              id = "myId"
              className = "myClassname"
              display = "initial"
              position = "relative"
              frameBorder = {0}
            />
          </CustomModalStyle>
          <CustomModalStyle
            centered
            visible={journey}
            onOk={() => setJourney(false)}
            onCancel={() => setJourney(false)}
            width = {650}
          >
            
          </CustomModalStyle>
          {
            packModal &&
            <Modal
              centered
              visible={packModal}
              onOk={() => createPackage()}
              onCancel={() => setPackModal(false)}
              afterClose={() => setSelectedBatch()}
              width = {650}
              title = "Pack Order"
              okText="Pack"
            >
            Please add dimensions of package.
            <div style = {{ height: 15 }} />
            <Space>
              <Input 
                placeholder="Length (CM)" 
                value={length} 
                onChange={(e) => setLength(e.target.value)}
              />
              <Input 
                placeholder="Width (CM)" 
                value={width} 
                onChange={(e) => setWidth(e.target.value)}
              />
              <Input 
                placeholder="Height (CM)" 
                value={height} 
                onChange={(e) => setHeight(e.target.value)}
              />
              <Input 
                placeholder="Weight (Kg)" 
                value={weight} 
                onChange={(e) => setWeight(e.target.value)}
              />
            </Space>
            <div style = {{ height: 15 }} />
            <Select
              style = {{ width: 240 }}
              placeholder="Select Mode of Transport"
              optionFilterProp="children"
              onChange={(val) => setModeType(val)}
              value = {modeType}
            >
              <Option value="Air" key="Air">Air</Option>
              <Option value="Surface-10kg" key="Surface-10kg">Surface-10kg</Option>
              <Option value="Surface-5kg" key="Surface-5kg">Surface-5kg</Option>
              <Option value="Surface-30kg" key="Surface-30kg">Surface-30kg</Option>
              <Option value="Lite-2kg" key="Lite-2kg">Lite-2kg</Option>
              <Option value="Lite-1kg" key="Lite-1kg">Lite-1kg</Option>
              <Option value="Lite-0.5kg" key="Lite-0.5kg">Air</Option>
            </Select>
            <div style = {{ height: 15 }} />
            Please select batches of product to pack from.
            <div style = {{ height: 15 }} />
            {
              Object.keys(productBatch).map((val, index) => (
                <Space>
                  <Name>{orderData["products"][index]["details"]["title"]}: </Name>
                  <Select
                    style = {{ width: 240 }}
                    placeholder="Select Batch"
                    optionFilterProp="children"
                    onChange={(val) => {
                      let allArr = [...selectedBatch];
                      allArr[index] = val;
                      setSelectedBatch(allArr);
                    }}
                    value = {selectedBatch[index]}
                  >
                    {
                      productBatch[val].map((v) => 
                        <Option value={v["_id"]} key={v["_id"]} >
                          {v["title"]}
                          <SelectSubTitle>Available Stock: {v["totalItems"] - v["usedItems"] - v["committedItems"]}</SelectSubTitle>
                          <SelectSubTitle>Expiry: {moment(v["expiry"]).format('DD MMMM, YYYY')}</SelectSubTitle>
                        </Option>
                      )
                    }
                  </Select>
                </Space>
              ))
            }
            </Modal>
          }
        </CustomModalStyle>
      }
      <CustomModalStyle
				centered
				visible={createOrderModal}
				onOk={() => setCreateOrderModal(false)}
				onCancel={() => setCreateOrderModal(false)}
				width = {950}
			>
				<CreateOrder productsList = {products} createOrder = {createOrder} loader = {createModalLoader} />
			</CustomModalStyle>
    </>
  )
}

export default ProductsOrder