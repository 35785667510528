export const ColorsListArr = [
  {
    "name": "White",
    "value": "ffffff"
  },
  {
    "name": "Silver",
    "value": "cococo"
  },
  {
    "name": "Gray",
    "value": "808080"
  },
  {
    "name": "Black",
    "value": "000000"
  },
  {
    "name": "Red",
    "value": "FF0000"
  },
  {
    "name": "Maroon",
    "value": "800000"
  },
  {
    "name": "Yellow",
    "value": "FFFF00"
  },
  {
    "name": "Olive",
    "value": "808000"
  },
  {
    "name": "Lime",
    "value": "00FF00"
  },
  {
    "name": "Green",
    "value": "008000"
  },
  {
    "name": "Aqua",
    "value": "00FFFF"
  },
  {
    "name": "Teal",
    "value": "008080"
  },
  {
    "name": "Blue",
    "value": "0000ff"
  },
  {
    "name": "Navy",
    "value": "000080"
  },
  {
    "name": "Fuchsia",
    "value": "ff00ff"
  },
  {
    "name": "Purple",
    "value": "800080"
  }
]

export const STATIC_IMG_URL = "https://buyzone.s3.ap-south-1.amazonaws.com/images/"