import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  ModalTitle
} from './index.styles';
import {
	Input,
	Select,
  Space,
  DatePicker
} from 'antd';
import moment from 'moment';

const { Option } = Select;

export const VoucherDetailsTab = forwardRef(({ data }, ref) => {

  const [code, setCode] = useState();
  const [description, setDescription] = useState();

  const [benefitType, setBenefitType] = useState();
  const [amount, setAmount] = useState();
  const [maxBenefitAmount, setMaxBenefitAmount] = useState();
  const [expiry, setExpiry] = useState();

  useEffect(() => {
    if (data != null) {
      console.log(data);
      setCode(data["code"]);
      setDescription(data["description"]);
      setBenefitType(data["benefits"]["benefitType"]);
      setAmount(data["benefits"]["amount"]);
      setMaxBenefitAmount(data["benefits"]["maxBenefitAmount"]);
      setExpiry(data["expiry"])
    }
  }, [])

  useImperativeHandle(ref, () => ({
		handleVoucherDetails() {
			return new Promise ((resolve, reject) => {
				const finalData = {
					code,
          description,
          benefits: {
            benefitType,
            amount,
            maxBenefitAmount
          },
          expiry
				}
				resolve(finalData)
			})
		}
	}))

  return (
    <>
      <Input placeholder="Voucher Code" value = {code} onChange={(e) => setCode(e.target.value)} maxLength = {50} allowClear />
      <div style = {{ height: 15 }} />
      <Input.TextArea placeholder="Voucher Description" value = {description} onChange={(e) => setDescription(e.target.value)} allowClear style = {{ height: 100 }} />
      <div style = {{ height: 15 }} />
      <ModalTitle>Benefits</ModalTitle>
      <div style = {{ height: 15 }} />
      <Space>
        <Select
          style={{ width: 150 }}
          placeholder="Benefit Type"
          optionFilterProp="children"
          value={benefitType}
          onChange={(e) => setBenefitType(e)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option key={0} value={0}>Percentage</Option>
          <Option key={1} value={1}>Amount</Option>
        </Select>
        {
          benefitType != null &&
          <Input 
            placeholder={benefitType == 0 ? 'Discount Percent' : 'Discount Amount'} 
            value = {amount} 
            onChange={(e) => setAmount(e.target.value)} 
            maxLength = {50} 
            allowClear />
        }
        {
          benefitType == 0 &&
          <Input 
            placeholder="Max Benefit Amount (Optional)" 
            value = {maxBenefitAmount} 
            onChange={(e) => setMaxBenefitAmount(e.target.value)} 
            maxLength = {50} 
            allowClear />
        }
        <DatePicker placeholder="Expiry" format="DD MMM YYYY, hh:mm a" showTime onChange={(val) => {console.log("Date Changed")}} value={expiry == null ? expiry : moment(expiry)} onOk={(val) => setExpiry(val.toDate())} />
      </Space>
    </>
  );
})